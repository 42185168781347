import styled from "styled-components";
import { tabletMediaQuery } from "styles/themes/mediaQueries";

const ServiceSpan = styled.span`
    color: #fff;
    font-weight: 700;

    text-shadow: black 1px 1px;
    margin-bottom: 1em;
`;

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    margin-top: 3em;
`;
export const ServiceContent = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    &:hover {
        &::before {
            content: "";
            position: absolute;
            width: 100%;
            border-radius: 8px;
            height: 100%;
            z-index: 1;
            background: linear-gradient(
                180deg,
                rgba(54, 54, 54, 0) 0%,
                #39505f 100%
            );
        }

        cursor: pointer;
        span {
            visibility: visible;
            opacity: 1;
        }
        strong {
            visibility: visible;
            opacity: 1;
        }
    }
`;
export const ServiceDescriptionOpacity = styled(ServiceSpan)`
    visibility: hidden;
    margin-bottom: 0 !important;
    text-shadow: black 1px 1px;
    font-size: 1rem;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
`;

export const ServiceDescription = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-weight: 700;
    width: 100%;
    padding: 0 2em;
    line-height: 20px;

    strong {
        visibility: hidden;
        font-size: 1rem;
        text-decoration: underline;
        color: #aedfef;
        margin-bottom: 1em;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
    }
`;

export const ServiceTitle = styled(ServiceSpan)`
    padding: 1em 0.5em;
    font-size: 1.5rem;
`;

export const ServiceTitleContent = styled.div`
    position: absolute;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(180deg, rgba(54, 54, 54, 0) 0%, #39505f 100%);

    width: 100%;
    height: 122px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
`;

export const Service = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    width: 100%;
    margin-top: 1em;
    ${tabletMediaQuery} {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
`;

export const ServiceImage = styled.img`
    border-radius: 8px;
    width: 100%;
`;

export default {
    primaryColor: "#6FAAC3",
    primaryColorLight: "#487B9D",
    primaryColorDark: "#2E3B4D",
    secondaryColor: "#026873",
    secondaryColorLight: "#3AA6A6",
    secondaryColorDark: "#054959",
    backgroundLight: "#fff",
    textDark: "#333333",
    textLight: "#ffffff",
    borderGray: "#e0e0e0",
    red: "#ff0000",
    redLight: "#ff0000ba",
    white: "#ffff",
    spanText: "#6f6b6b",
    greenDark: "#054959",
    greenIntermediate: "#026873",
    greenLight: "#3AA6A6",
    grey: "#888888",
    black: "#000000",
    mediumBlue: "#487B9D",
    darkBlue: "#2E3B4D",
    offWhite: "#F3F3F3",
};

export default () => (
    <svg
        width="44"
        height="40"
        viewBox="0 0 44 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M19.25 29.75V8.86562L12.1 15.5281L8.25 11.8125L19.737 1.1087C21.0118 -0.0792064 22.9882 -0.0792097 24.263 1.10869L35.75 11.8125L31.9 15.5281L24.75 8.86562V29.75H19.25ZM5.5 40C3.9875 40 2.69225 39.4977 1.61425 38.4932C0.536254 37.4887 -0.00182866 36.2827 4.66893e-06 34.875V27.1875H5.5V34.875H38.5V27.1875H44V34.875C44 36.2844 43.461 37.4913 42.383 38.4958C41.305 39.5003 40.0107 40.0017 38.5 40H5.5Z"
            fill="#487B9D"
        />
    </svg>
);

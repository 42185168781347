import { FaqType } from "types";

export const FaqItems: Array<FaqType> = [
    {
        id: 1,
        title: "Como posso enviar meus projetos para a render farm?",
        description:
            "Envie seus projetos facilmente através de nossa interface intuitiva, seguindo alguns passos simples. Após criar sua conta, basta fazer o upload do seu projeto e acompanhá-lo na aba de documentos. Cadastra-se no Arktools e iremos te ajudar!",
    },
    {
        id: 2,
        title: "Qual é o tempo de processamento dos projetos de renderização?",
        description:
            "O tempo médio de processamento varia, mas nossa render farm é otimizada para acelerar significativamente a renderização de seus projetos, pois contamos com máquinas altamente qualificadas para otimizar todo o processo.",
    },
    {
        id: 3,
        title: "Como a segurança dos arquivos é garantida durante o processo?",
        description:
            "Garantimos a segurança dos seus arquivos com criptografia avançada durante o upload, download e renderização, além de servidores altamente seguros para proteger a confidencialidade do seu trabalho.",
    },
    {
        id: 4,
        title: "Por que devo usar o Arktools para renderizar meus projetos?",
        description:
            "Oferecemos benefícios significativos para economia do seu tempo! Possuímos hardwares otimizados para gráficos 3D, garantindo resultados de alta qualidade, além de nossa interface amigável, simplificando o processo de envio e acompanhamento dos seus projetos.",
    },
    {
        id: 5,
        title: "Como posso tirar dúvidas mais específicas durante o processo?",
        description:
            "O Arktools oferece um chat para que você consiga conversar diretamente com o administrador, além de contar com um Blog, onde vários arquitetos compartilham ideias e discutem sobre dúvidas, crie sua conta e faça parte do Arktools!",
    },
    {
        id: 6,
        title: "Como enviar/submeter meu arquivo?",
        description:
            "Ao acessar sua conta, vá até o ícone do seu perfil e clique em “Projetos”, você pode arrastar seu arquivo até a interface ou abrir do seu computador.",
    },
    {
        id: 7,
        title: "Posso enviar mais de um projeto por vez?",
        description:
            "Sim, você pode enviar mais de um arquivo e poderá acompanhar em tempo real o andamento do seu projeto, com a data prevista para entrega.",
    },
    {
        id: 8,
        title: "Como posso acompanhar meu projeto durante o processo?",
        description:
            "Você poderá acompanhar em tempo real o andamento do seu projeto, com a data prevista para entrega na aba “Meus projetos”.",
    },
    {
        id: 9,
        title: "Posso compartilhar meu acesso com outros arquitetos?",
        description: "Sim, lorem ipsum.",
    },
];

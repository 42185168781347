import * as S from "./style";
import { Container, Image360 } from "components";
import JsonService from "utils/jsonService.json";
import { useParams } from "react-router-dom";
import { useState } from "react";

export default function ReadMore() {
    const [isLoaded, setIsLoaded] = useState(false);
    const { id } = useParams();
    const filteredData = JsonService.services.filter((item) => item.id == id);
    return (
        <Container>
            <S.Container>
                <S.Content>
                    {filteredData[0]?.image360 ? (
                        <Image360 url={`../${filteredData[0]?.image360}`} />
                    ) : filteredData[0]?.iframe ? (
                        <S.IFrame
                            allow="xr-spatial-tracking"
                            onLoad={() => {
                                setTimeout(() => {
                                    self.scrollTo(0, 0);
                                    setIsLoaded(true);
                                }, 1000);
                            }}
                            style={{
                                display: isLoaded ? "block" : "none",
                            }}
                            src={filteredData[0]?.iframe}
                        />
                    ) : (
                        <S.Image src={`../${filteredData[0].banner}`} />
                    )}

                    <S.Title>{filteredData[0].title}</S.Title>
                    <S.Line />
                    <S.Text>{filteredData[0].description}</S.Text>
                    <S.LineText />
                </S.Content>
            </S.Container>
        </Container>
    );
}

import styled from "styled-components";

export const ContainerSummary = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const WrapperSummary = styled.div`
    width: 302px;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
`;

export const ContentSummary = styled.div`
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    text-align: start;
    padding: 1em;
    gap: 1em;
    display: flex;
    flex-direction: column;
    span {
        font-weight: 500;
        color: #000;
    }
`;

export const SummaryTitle = styled.h4`
    font-size: 1.25rem;
    font-weight: 700;
    color: #000;
    margin: 0;
`;

export const Subtitle = styled.h4`
    font-weight: 500;
    font-size: 1rem;
    margin: 0;
`;

export const SummaryValue = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
export const SummaryButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const ButtonWhite = styled.button`
    height: 39px;
    border-radius: 8px;
    border: solid 1px ${(props) => props.theme.primaryColorLight};
    color: ${(props) => props.theme.primaryColorLight};
    font-weight: 500;
    font-size: 1.1rem;
    background-color: ${(props) => props.theme.white};
    transition: transform ease-in-out 0.3s;
    &:hover {
        cursor: pointer;
        transform: scale(1.1);
    }
`;

export const ButtonBlue = styled.button`
    height: 39px;
    border-radius: 8px;
    border: none;
    color: ${(props) => props.theme.white};
    font-weight: 500;
    font-size: 1.1rem;
    background-color: ${(props) => props.theme.primaryColorLight};
    transition: transform ease-in-out 0.3s;
    &:hover {
        cursor: pointer;
        transform: scale(1.1);
    }
`;

import "react-responsive-carousel/lib/styles/carousel.min.css";
import * as S from "./style";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { ProjectsItems } from "pages/projects/projects";
import { Projects } from "types";
import { useNavigate } from "react-router-dom";
export default function ContainerCarousel() {
    const route = useNavigate();
    return (
        <S.CarouselContent>
            <S.StyledCarousel
                showArrows={true}
                showStatus={false}
                showIndicators={false}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                        <S.CustomPrevArrow
                            onClick={onClickHandler}
                            title={label}
                        >
                            <div>
                                <BsChevronLeft />
                            </div>
                        </S.CustomPrevArrow>
                    )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                        <S.CustomNextArrow
                            onClick={onClickHandler}
                            title={label}
                        >
                            <div>
                                <BsChevronRight />
                            </div>
                        </S.CustomNextArrow>
                    )
                }
            >
                {ProjectsItems.map((CarouselItem: Array<Projects>, index) => (
                    <S.CarouselSlide key={index}>
                        {CarouselItem.map((ImageItem: Projects) => (
                            <S.ContentCarousel key={ImageItem.id}>
                                <S.Image
                                    onClick={() =>
                                        route(
                                            `/detailedProject/${ImageItem.id}`
                                        )
                                    }
                                >
                                    <img
                                        src={ImageItem.url}
                                        alt={ImageItem.title}
                                    />
                                </S.Image>

                                <span
                                    onClick={() =>
                                        route(
                                            `/detailedProject/${ImageItem.id}`
                                        )
                                    }
                                >
                                    {ImageItem.title}
                                </span>
                            </S.ContentCarousel>
                        ))}
                    </S.CarouselSlide>
                ))}
            </S.StyledCarousel>
        </S.CarouselContent>
    );
}

import styled, { css } from "styled-components";

export const MenuItem = styled.li`
    color: #fff;
    font-size: 1rem;
    &:hover {
        cursor: pointer;
    }
`;
export const Span = styled.span<{ active?: boolean }>`
    font-size: 1.1rem;
    color: ${(props) => props.theme.grey};
    font-weight: 100;
    transition: transform 0.3s ease-in-out;
    &:hover {
        color: ${(props) => props.theme.black};
    }
    ${({ active }) =>
        active &&
        css`
            color: ${(props) => props.theme.black};
        `}

    @media (max-width: 450px) {
        font-size: 1rem;
    }
`;

import IconFast3dLab from "assets/icons/LogoFast3dLab.svg";
import IconFastProBr from "assets/icons/LogoFastProBr.svg";
import IconPonto44 from "assets/icons/LogoPonto44.svg";
import { BannerShipType } from "types";

export const BannerShip: Array<BannerShipType> = [
    {
        id: 1,
        link: "https://www.fast3dlab.com/",
        icon: IconFast3dLab,
    },
    { id: 2, link: "https://www.fastprobr.com/", icon: IconFastProBr },
    {
        id: 3,
        link: "https://www.ponto44.com.br/",
        icon: IconPonto44,
    },
];

import styled from "styled-components";
import Accordion from "react-bootstrap/Accordion";
import { tabletMediaQuery } from "styles/themes/mediaQueries";

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

export const ContainerTitle = styled.div`
    margin-top: 8em;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const AccordionContainer = styled(Accordion)<{ isOpen: any }>`
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin: 4em 0;
    width: 768px;
    .card {
        border: 1px solid ${(props) => props.theme.primaryColorLight};
        background-color: ${(props) =>
            props.isOpen ? props.theme.primaryColorDark : "transparent"};
        border-radius: 10px;
    }
    .card-header {
        align-items: center;
        background-color: transparent;
        font-size: 1.2rem;
        font-weight: 700;
        display: flex;
        justify-content: space-between;
        gap: 0.8em;
        border: none;
        padding: 12px 30px;
        border-radius: 10px;
    }
    .accordion-collapse {
        border-top: 1px solid ${(props) => props.theme.primaryColorLight};
    }
    .card-body {
        padding: 15px 30px;

        font-size: 1rem;
    }
`;

export const ButtonTottle = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.primaryColorLight};
    border-radius: 50%;
    border: none;
    width: 42px;
    height: 42px;
    ${tabletMediaQuery} {
        width: 25px;
        height: 25px;
    }
    svg {
        color: ${(props) => props.theme.white};
        ${tabletMediaQuery} {
            width: 15px;
            height: 15px;
        }
    }
`;

import { z } from "zod";

export const RegisterSchema = z.object({
    name: z.string().min(1, { message: "Nome é obrigatório!" }),
    email: z
        .string()
        .email("E-mail inválido!")
        .min(1, { message: "Email é obrigatório!" }),
    password: z.string().min(1, { message: "Senha é obrigatório!" }),
    document: z.string().min(1, { message: "CPF é obrigatório!" }),
    phone: z.string().min(1, { message: "Telefone é obrigatório!" }),
    politica: z
        .boolean()
        .refine((value) => value === true, "Política é obrigatório!"),
});

export const EstilizacaoData = [
    {
        title: "Estilo 1 - Colagem",
        description: "Colagem digital com fotos e elementos visuais.",
        option: [
            "Incluir animação ambiente aberto",
            "Incluir animação - ambiente com chuva",
        ],
        url: "https://arktools.s3.sa-east-1.amazonaws.com/Service/detailPage/Colagembanner.png",
        valueNoSignature: 600,
        valueSignature: 540,
    },
    {
        title: "Estilo 1 - Isométrica",
        description: "Imagem com foco em volumetria, acabamento mais clean​.",
        option: [
            "Incluir animação ambiente aberto",
            "Incluir animação - ambiente com chuva",
        ],
        url: "https://arktools.s3.sa-east-1.amazonaws.com/Service/detailPage/Isometricabanner.png",
        valueNoSignature: 600,
        valueSignature: 540,
    },
    {
        title: "Estilo 1 - Ilustração",
        description: "Ilustração digital de cores chapadas e mais elaboradas.",
        option: [
            "Incluir animação ambiente aberto",
            "Incluir animação - ambiente com chuva",
        ],
        url: "https://arktools.s3.sa-east-1.amazonaws.com/Service/detailPage/croquibanner.png",
        valueNoSignature: 600,
        valueSignature: 540,
    },
    {
        title: "Estilo 1 - Croqui ",
        description: "Ilustração digital no estilo de croquis arquitetônicos​.",
        option: [
            "Incluir animação ambiente aberto",
            "Incluir animação - ambiente com chuva",
        ],
        url: "https://arktools.s3.sa-east-1.amazonaws.com/Service/detailPage/croquibanner.png",
        valueNoSignature: 600,
        valueSignature: 540,
    },
];

export default () => (
    <svg
        width="25"
        height="19"
        viewBox="0 0 25 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0.6875 15.7393C0.6875 16.5225 0.998632 17.2736 1.55245 17.8274C2.10627 18.3813 2.85741 18.6924 3.64062 18.6924H21.3594C22.1426 18.6924 22.8937 18.3813 23.4475 17.8274C24.0014 17.2736 24.3125 16.5225 24.3125 15.7393V7.61816H0.6875V15.7393ZM4.16797 11.7314C4.16797 11.3119 4.33465 10.9095 4.63134 10.6128C4.92802 10.3161 5.33042 10.1494 5.75 10.1494H8.28125C8.70083 10.1494 9.10323 10.3161 9.39992 10.6128C9.6966 10.9095 9.86328 11.3119 9.86328 11.7314V12.7861C9.86328 13.2057 9.6966 13.6081 9.39992 13.9048C9.10323 14.2015 8.70083 14.3682 8.28125 14.3682H5.75C5.33042 14.3682 4.92802 14.2015 4.63134 13.9048C4.33465 13.6081 4.16797 13.2057 4.16797 12.7861V11.7314ZM21.3594 0.129883H3.64062C2.85741 0.129883 2.10627 0.441015 1.55245 0.994833C0.998632 1.54865 0.6875 2.29979 0.6875 3.08301V4.4541H24.3125V3.08301C24.3125 2.29979 24.0014 1.54865 23.4475 0.994833C22.8937 0.441015 22.1426 0.129883 21.3594 0.129883Z"
            fill="black"
        />
    </svg>
);

import * as S from "./style";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Title from "components/Title";
import SubTitle from "components/SubTitle";
import { ProjectsItems } from "pages/projects/projects";
import { Projects } from "types";
import { useNavigate } from "react-router-dom";
export default function LatestProjects() {
    const route = useNavigate();
    return (
        <>
            <S.ContainerTitles>
                <Title title="Projetos recentes" />
                <SubTitle subtitle="Clique para saber mais" />
            </S.ContainerTitles>
            <S.StyledCarousel
                infiniteLoop={true}
                autoPlay={true}
                showArrows={false}
                transitionTime={500}
                interval={3000}
                showStatus={false}
                showIndicators={false}
                showThumbs={false}
                centerMode
                centerSlidePercentage={33.33}
            >
                {ProjectsItems.map((item: any) =>
                    item?.map((itemData: Projects) => (
                        <S.CarouselSlide
                            key={itemData.id}
                            onClick={() =>
                                route(`/detailedProject/${itemData.id}`)
                            }
                        >
                            <S.Image
                                src={itemData.thumbnail}
                                alt={itemData.title}
                            />
                        </S.CarouselSlide>
                    ))
                )}
            </S.StyledCarousel>
        </>
    );
}

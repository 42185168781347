import styled from "styled-components";

type typeButton = {
    hover: boolean;
};
export const WrapperAfterBefore = styled.div`
    padding: 1em;
`;

export const ContainerAfterBefore = styled.div`
    height: 657px;
    position: relative;
    @media (max-width: 600px) {
        height: 200px;
    }
    @media (min-width: 600px) and(max-width: 1000px) {
        height: 300px;
    }
    @media (min-width: 1000px) and(max-width: 1300px) {
        height: 450px;
    }
`;

export const ImgAfter = styled.div<typeButton>`
    position: absolute;
    top: 0;
    left: 0;
    width: 1062px;
    height: 630px;
    max-width: 1062px;
    @media (max-width: 600px) {
        width: 250px;
        height: 150px;
    }
    @media (min-width: 600px) and (max-width: 1000px) {
        width: 400px;
        height: 200px;
    }

    @media (min-width: 1000px) and (max-width: 1300px) {
        width: 500px;
        height: 300px;
    }
    img {
        border-bottom-left-radius: 8px;
        @media (max-width: 600px) {
            width: 250px;
            height: 150px;
        }
        @media (min-width: 600px) and (max-width: 1000px) {
            width: 400px;
            height: 200px;
        }
        @media (min-width: 1000px) and (max-width: 1300px) {
            width: 550px;
            height: 300px;
        }
    }
    button {
        position: absolute;
        z-index: 2;
        width: 30px;
        height: 630px;
        top: 0;
        right: 0;
        border: none;
        background-color: transparent;
        animation-fill-mode: forwards;
        animation-name: ${(props) =>
            props.hover ? "example" : "exampleClose"};
        @media (max-width: 600px) {
            height: 150px;
            svg {
                height: 150px;
            }
        }
        @media (min-width: 600px) and (max-width: 1000px) {
            height: 200px;
            svg {
                height: 200px;
            }
        }
        @media (min-width: 1000px) and (max-width: 1300px) {
            height: 300px;
            svg {
                height: 300px;
            }
        }
        @keyframes example {
            from {
                right: 0;
            }
            to {
                right: 100%;
            }
        }
        @keyframes exampleClose {
            from {
                right: 100%;
            }
            to {
                right: 0;
            }
        }
    }
`;
export const Button = styled.button``;

export const ButtonBefore = styled.button``;
export const ImgBefore = styled.div<typeButton>`
    position: absolute;
    width: 100%;
    top: 0;
    z-index: ${(props) => (props.hover ? 1 : -1)};
    right: 0;

    img {
        width: ${(props) => (props.hover ? "100%" : "1000px")};
        opacity: ${(props) => (props.hover ? 1 : 0)};
        height: 630px;
        border-bottom-right-radius: 8px;
        @media (max-width: 600px) {
            width: 100%;
            height: 150px;
        }
        @media (min-width: 600px) and (max-width: 1000px) {
            width: 100%;
            height: 200px;
        }
        @media (min-width: 1000px) and (max-width: 1300px) {
            width: 100%;
        }
    }
`;
export const ImgBeforeZIndex = styled.div<typeButton>`
    position: absolute;
    top: 0;
    z-index: -1;
    right: 0;

    img {
        border-bottom-right-radius: 8px;
        @media (max-width: 600px) {
            width: 250px;
            height: 150px;
        }

        @media (min-width: 1300px) and (max-width: 1440px) {
            width: 1000px;
            height: 630px;
        }

        @media (min-width: 600px) and (max-width: 1000px) {
            width: 400px;
            height: 200px;
        }
        @media (min-width: 1000px) and (max-width: 1300px) {
            width: 550px;
            height: 300px;
        }
    }
`;

export const Instruction = styled.div`
    background-color: #e7eff5;
    padding: 1em;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    span {
        font-size: 1.25rem;
    }
`;

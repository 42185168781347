import { InputType } from "types";
import * as S from "./style";
import React from "react";
import { Button, HelperText } from "components";
import { Upload } from "assets/icons";
const InputFileContrat = React.forwardRef<HTMLInputElement, InputType>(
    (
        { type = "file", helperText = "", watch = "", align = "", ...props },
        ref
    ) => {
        const hasError = helperText.length > 0;

        return (
            <>
                <S.StyledFileInputContainer align={align}>
                    <S.StyledFileInputContrat
                        ref={ref}
                        type={type}
                        id="file"
                        {...props}
                    />
                    <S.StyledButton>
                        {watch.length > 0 ? (
                            watch[0]?.name
                        ) : (
                            <S.Upload>
                                <Upload /> <S.UploadTitle>Upload</S.UploadTitle>
                                <S.UploadSubTitle>
                                    Arraste seus arquivos nesta área
                                </S.UploadSubTitle>
                                <S.UploadType>
                                    .skp, .png, .jpeg, .mp4
                                </S.UploadType>
                            </S.Upload>
                        )}
                    </S.StyledButton>
                    <S.UploadOpenContrat>
                        <Button.Small type="submit">
                            Escolher arquivo
                        </Button.Small>
                    </S.UploadOpenContrat>
                </S.StyledFileInputContainer>
                {hasError && <HelperText helperText={helperText} />}
            </>
        );
    }
);

export default InputFileContrat;

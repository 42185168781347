import { ReactElement } from "react";
import SquareBlue1 from "assets/images/SquareUp1.png";
import SquareBlue2 from "assets/images/SquareUp2.png";
import * as S from "./style";
export default function Left({
    SquareUpTop1 = "1em",
    SquareUpTop2 = "12em",
}: any): ReactElement {
    return (
        <S.LeftColumn>
            <S.ContainerSquareUp1 SquareUpTop1={SquareUpTop1}>
                <S.SquareBlue src={SquareBlue1} />
            </S.ContainerSquareUp1>
            <S.ContainerSquareUp2 SquareUpTop2={SquareUpTop2}>
                <S.SquareBlue src={SquareBlue2} />
            </S.ContainerSquareUp2>
        </S.LeftColumn>
    );
}

import * as S from "./style";
import { Button, Container, Input, Loading } from "components";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { DocumentsSchema } from "schemas";
import { DataProps } from "types";
import { useFile } from "hooks";

export default function ThreeDIserter() {
    const { isloading, postFile } = useFile();
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        reset,
    } = useForm<DataProps<typeof DocumentsSchema>>({
        resolver: zodResolver(DocumentsSchema),
    });

    const watchFile = watch("file");

    const submitForm = async (FileList: any) => {
        const form = new FormData();
        form.append("file", FileList.file[0]);

        const response = await postFile(form);
        if (response?.code) return;
        reset();
    };

    return (
        <Container>
            <S.Wrapper>
                <S.Title>Envie aqui seu projeto</S.Title>

                <S.Form onSubmit={handleSubmit(submitForm)}>
                    <Input.File
                        {...register("file")}
                        name="file"
                        helperText={errors?.file?.message?.toString()}
                        watch={watchFile}
                    />
                    <S.ContentButton>
                        <Button.Small type="submit">
                            {isloading ? <Loading size={8} /> : "Enviar"}
                        </Button.Small>
                    </S.ContentButton>
                </S.Form>
            </S.Wrapper>
        </Container>
    );
}

import { useEffect, useState } from "react";
import { useConfirmEmail } from "hooks";
import { useParams } from "react-router-dom";
import Logo from "assets/icons/LogoArktoolsBlue.svg";
import * as S from "./style";

export default function ConfirmMail() {
    const { token } = useParams<string>();
    const { postToken, resp } = useConfirmEmail();
    const [fetching, setFetching] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            if (fetching) await postToken(token!);
            setFetching(true);
        })();
    }, [fetching]);

    return (
        <S.Wrapper>
            <S.Container>
                <S.Logo>
                    <img src={Logo} alt={"Logo arktools"} />
                </S.Logo>
                <S.ContentTitle>
                    <S.Title> {resp} </S.Title>

                    {/* <S.Span>
                        Por favor, <strong>clique aqui</strong> para gerar novo
                        e-mail de validação.
                    </S.Span> */}
                </S.ContentTitle>
            </S.Container>
        </S.Wrapper>
    );
}

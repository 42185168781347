import { zodResolver } from "@hookform/resolvers/zod";
import Image360v2 from "assets/images/360Sala.png";
import {
    Container,
    HelperText,
    Image360,
    Input,
    Loading,
    Title,
} from "components";
import { DatePickerStyle } from "components/Input/style";
import { getServiceID, useCarrinho, useFile, useGetCarrinho } from "hooks";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { ScanningHiringSchema } from "schemas";
import { DataProps, TypeImagensRealistas } from "types";
import { GetService } from "utils/typeService";
import { EstilizacaoData } from "./estilizacao";
import * as S from "./style";

export default function ScanningHiring() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { refetchListCarrinho } = useGetCarrinho();
    const router = useNavigate();
    const { postFile } = useFile();
    const { postCarrinho } = useCarrinho();
    const { id } = useParams();
    const [startDate, setStartDate] = useState(new Date());
    const [getService, setGetService] = useState<any>("");
    const [selectedCheckboxes, setSelectedCheckboxes] = useState<{
        [key: string]: boolean;
    }>({});
    const [totalEstilizacao, setTotalEstilizacao] = useState<number>(0);
    const valueNoSignature = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
    }).format(getService?.valueNoSignature ? getService?.valueNoSignature : 0);
    const valueSignature = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
    }).format(getService?.valueSignature ? getService?.valueSignature : 0);
    const amount = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
    }).format(
        getService?.valueNoSignature
            ? getService?.valueNoSignature + totalEstilizacao
            : 0 + totalEstilizacao
    );
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<DataProps<typeof ScanningHiringSchema>>({
        mode: "onBlur",
        resolver: zodResolver(ScanningHiringSchema),
    });

    const submitForm = async (data: any) => {
        const selectedValues = Object.keys(selectedCheckboxes).filter(
            (key) => selectedCheckboxes[key]
        );
        setIsLoading(true);
        const form = new FormData();
        form.append("file", data.file[0]);
        form.append("comentario", data.comentario);
        form.append("nameProject", data.nameProject);
        form.append("idService", getService?.id);
        form.append("authorization", data.authorization);
        form.append("validFile", data.validFile);
        form.append("extraStyles", selectedValues.join(","));

        const response = await postFile(form);

        const dataCarrinho = {
            upload: { id: response.respUpload.id },
            service: { id: response.respUpload.serviceId[0], valor: amount },
            user: { id: response.respUpload.userID[0] },
            authorization: data?.authorization,
        };

        const responseCarrinho = await postCarrinho(dataCarrinho);

        if (responseCarrinho) {
            refetchListCarrinho();
            router("/confirmOrder");
        }
    };

    const watchFile = watch("file");
    useEffect(() => {
        async function response() {
            const resp = await getServiceID(id!);
            setGetService(resp[0]);
        }
        response();
    }, []);

    function handleCheckboxChange(
        event: React.ChangeEvent<HTMLInputElement>,
        value: number,
        id: string
    ) {
        if (event.target.checked) {
            setTotalEstilizacao(totalEstilizacao + value);
        } else {
            setTotalEstilizacao(
                totalEstilizacao > 0 ? totalEstilizacao - value : 0
            );
        }
        setSelectedCheckboxes({
            ...selectedCheckboxes,
            [id]: event.target.checked,
        });
    }

    return (
        <Container>
            <S.Wrapper>
                <Title title="Contratação de serviço" />
                <S.Subtitle>
                    Você está contratando o serviço: {getService?.title}
                </S.Subtitle>
                <S.Banner>
                    {GetService()["imagem360"] == id ? (
                        <Image360 url={Image360v2} />
                    ) : getService?.id == GetService()["video2D"] ? (
                        <S.WrapperAnimation>
                            <S.ContentVideo>
                                <S.Video controls>
                                    <source
                                        src={getService?.detailPage?.banner}
                                        type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                </S.Video>
                            </S.ContentVideo>
                        </S.WrapperAnimation>
                    ) : (
                        <S.BannerImg
                            src={
                                Array.isArray(getService?.detailPage?.banner)
                                    ? getService?.detailPage?.banner[0]
                                    : getService?.detailPage?.banner
                            }
                            alt={getService?.title}
                        />
                    )}
                </S.Banner>
                <S.Hiring>
                    <S.HiringTitle>
                        Contratação de serviço: {getService?.title}
                    </S.HiringTitle>
                    <S.HiringTitle>
                        ID: <strong>0001</strong>
                    </S.HiringTitle>
                    <S.HiringTitle>
                        Descrição:{" "}
                        <strong>{getService?.detailPage?.description}</strong>
                    </S.HiringTitle>
                    <S.HiringTitle>
                        Prazo:{" "}
                        <strong>
                            pode variar após uma primeira validação do arquiteto
                            responsável
                        </strong>
                    </S.HiringTitle>
                    <S.Requirements>
                        <S.HiringTitle>Requerimentos:</S.HiringTitle>
                        <S.RequirementsSubTitle>
                            1. Projeto
                        </S.RequirementsSubTitle>
                        <S.Hr />
                        <form onSubmit={handleSubmit(submitForm)}>
                            <S.WrapperForm>
                                <S.ContentInfo>
                                    <S.FormTitle>
                                        Informe o nome do projeto:
                                    </S.FormTitle>
                                    <Input.Text
                                        {...register("nameProject")}
                                        type="text"
                                        name="nameProject"
                                        helperText={
                                            errors?.nameProject?.message
                                        }
                                    />
                                </S.ContentInfo>
                                <S.ContentFile>
                                    <Input.FileContrat
                                        {...register("file")}
                                        name="file"
                                        align="start"
                                        helperText={errors?.file?.message?.toString()}
                                        watch={watchFile}
                                    />
                                </S.ContentFile>
                                <S.ConfirmFile>
                                    <Input.Checkbox
                                        label="Confirmo que o arquivo enviado está
                                        correto"
                                        {...register("validFile")}
                                    />
                                </S.ConfirmFile>

                                {errors?.validFile?.message?.toString() && (
                                    <HelperText
                                        helperText={errors?.validFile?.message?.toString()}
                                    />
                                )}
                                <S.ConfirmFile>
                                    <Input.Checkbox
                                        label="Autorizo a divulgação de imagens e vídeos do projeto enviado"
                                        {...register("authorization")}
                                    />
                                </S.ConfirmFile>
                                <S.Date>
                                    <span>Data de solicitação: </span>
                                    <DatePickerStyle
                                        selected={startDate}
                                        showTimeSelect
                                        onChange={(date: any) =>
                                            setStartDate(date)
                                        }
                                        disabled={true}
                                        timeFormat="HH:mm"
                                        dateFormat="dd/MM/yyyy HH:mm"
                                        timeIntervals={60}
                                    />
                                </S.Date>

                                {id ==
                                    GetService()["representacaoEstilizada"] && (
                                    <>
                                        <S.RequirementsSubTitle>
                                            2. Escolha um ou mais estilos a
                                            serem gerados
                                        </S.RequirementsSubTitle>
                                        <S.Hr />
                                        <S.WrapperEstilizacao>
                                            {EstilizacaoData.map(
                                                (item: any, index: number) => (
                                                    <S.ContentEstiliza
                                                        key={index}
                                                    >
                                                        <S.InputEstiliza>
                                                            <Input.Checkbox
                                                                disabled={false}
                                                                label={
                                                                    item.title
                                                                }
                                                                onChange={(e) =>
                                                                    handleCheckboxChange(
                                                                        e,
                                                                        item.valueNoSignature,
                                                                        item.title
                                                                    )
                                                                }
                                                            />
                                                        </S.InputEstiliza>

                                                        <img
                                                            src={item.url}
                                                            alt={item.title}
                                                        />
                                                        <S.InputEstiliza>
                                                            <Input.Checkbox
                                                                disabled={false}
                                                                label={
                                                                    item
                                                                        .option[0]
                                                                }
                                                            />
                                                        </S.InputEstiliza>
                                                        <S.InputEstiliza>
                                                            <Input.Checkbox
                                                                disabled={false}
                                                                label={
                                                                    item
                                                                        .option[1]
                                                                }
                                                            />
                                                        </S.InputEstiliza>
                                                    </S.ContentEstiliza>
                                                )
                                            )}
                                        </S.WrapperEstilizacao>
                                    </>
                                )}
                                {id == GetService()["imagensRealista"] && (
                                    <>
                                        <S.RequirementsSubTitle>
                                            2. Escolha um ou mais estilos a
                                            serem gerados
                                        </S.RequirementsSubTitle>
                                        <S.Hr />
                                        <S.WrapperEstilizacao
                                            justifyContent={"flex-start"}
                                        >
                                            {getService?.extraStyles?.map(
                                                (
                                                    item: TypeImagensRealistas,
                                                    index: number
                                                ) => (
                                                    <S.ContentEstiliza
                                                        key={index}
                                                    >
                                                        <S.InputEstiliza>
                                                            <Input.Checkbox
                                                                disabled={false}
                                                                label={
                                                                    item.title
                                                                }
                                                                onChange={(e) =>
                                                                    handleCheckboxChange(
                                                                        e,
                                                                        item.valueNoSignature,
                                                                        item.id
                                                                    )
                                                                }
                                                            />
                                                        </S.InputEstiliza>
                                                    </S.ContentEstiliza>
                                                )
                                            )}
                                        </S.WrapperEstilizacao>
                                    </>
                                )}
                                {id !==
                                    GetService()["representacaoEstilizada"] &&
                                    id !== GetService()["imagensRealista"] && (
                                        <>
                                            <S.RequirementsSubTitle>
                                                2. Informação sobre o serviço a
                                                ser contratado:
                                            </S.RequirementsSubTitle>
                                            <S.Hr />
                                        </>
                                    )}

                                <S.ContentInfoArea>
                                    <S.FormTitle>
                                        Comentários, dicas e solicitações:
                                    </S.FormTitle>
                                    <Input.Textarea
                                        {...register("comentario")}
                                        type="text"
                                        name="comentario"
                                        helperText={errors?.comentario?.message}
                                    />
                                </S.ContentInfoArea>

                                {id !=
                                    GetService()["representacaoEstilizada"] && (
                                    <>
                                        <S.RequirementsSubTitle>
                                            3. Preços:
                                        </S.RequirementsSubTitle>
                                        <S.Hr />
                                        <S.ConfirmFile>
                                            <Input.Checkbox
                                                checked={true}
                                                label={`Custo para não assinante: ${valueNoSignature} por renderização`}
                                            />
                                        </S.ConfirmFile>
                                        <S.ConfirmFile>
                                            <Input.Checkbox
                                                color="#B0B0B0"
                                                disabled={true}
                                                label={`Custo para assinante: ${valueSignature} por renderização`}
                                            />
                                        </S.ConfirmFile>
                                    </>
                                )}
                                <S.Amount>
                                    <span>Valor total: {amount}</span>
                                    <S.ContentButton>
                                        <S.ButtonCancelar>
                                            Cancelar
                                        </S.ButtonCancelar>
                                        <S.ButtonConfirmar type="submit">
                                            {isLoading ? (
                                                <Loading size={8} />
                                            ) : (
                                                "Confirmar"
                                            )}
                                        </S.ButtonConfirmar>
                                    </S.ContentButton>
                                </S.Amount>
                            </S.WrapperForm>
                        </form>
                    </S.Requirements>
                </S.Hiring>
            </S.Wrapper>
        </Container>
    );
}

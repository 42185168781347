import AfterBefore from "components/Home/AfterBefore";

import {
    Container,
    Service,
    OurProcess,
    ChooseUs,
    LatestProjects,
    // OurBlog,
    Testimonials,
    Banner,
    BannerPartnership,
    WhatsappButton,
} from "components";

export default function Home() {
    return (
        <>
            <Container>
                <Banner />
                <Service />
                <OurProcess />
                <ChooseUs />
                <LatestProjects />
                <AfterBefore />
                {/* <OurBlog /> */}
                <Testimonials />
            </Container>
            <BannerPartnership />
            <WhatsappButton />
        </>
    );
}

import styled from "styled-components";
import { mobileMediaQuery } from "styles/themes/mediaQueries";

export const Button = styled.a`
    svg {
        width: 109px;
        height: 109px;

        ${mobileMediaQuery} {
            width: 90px;
            height: 90px;
        }
    }
`;

export const ContainerWppButton = styled.div`
    position: fixed;
    bottom: 80px;
    right: 10px;
    z-index: 2;

    ${mobileMediaQuery} {
        right: -10px;
    }
`;

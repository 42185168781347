type TypeDropdownData = {
    title: string;
    url: string;
};

export const DataDropdown: Array<TypeDropdownData> = [
    {
        title: "Upload de projeto",
        url: "/3d-inserter",
    },
    {
        title: "Contratar serviço",
        url: "/service-contracting",
    },
    {
        title: "Meus projetos",
        url: "/orders",
    },
    {
        title: "", // configurações, incluir quando existir
        url: "",
    },
    {
        title: "",  // suporte, incluir quando existir
        url: "",
    },
];

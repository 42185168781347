import React, { InputHTMLAttributes, useId } from "react";
import * as S from "./style";
import { HelperText } from "components";

type TypeTextArea = InputHTMLAttributes<HTMLTextAreaElement> & {
    label?: string;
    helperText?: string;
    rows?: number;
    cols?: number;
};

const InputTextarea = React.forwardRef<HTMLTextAreaElement, TypeTextArea>(
    ({ name = "", label = "", helperText = "", rows, cols, ...props }, ref) => {
        const inputId = useId();
        const hasError = helperText.length > 0;
        return (
            <>
                <S.LabelTextarea htmlFor={inputId}>{label}</S.LabelTextarea>
                <S.InputTextarea
                    ref={ref}
                    id={inputId}
                    name={name}
                    hasError={hasError}
                    rows={rows}
                    cols={cols}
                    {...props}
                />

                {hasError && <HelperText helperText={helperText} />}
            </>
        );
    }
);

export default InputTextarea;

import styled from "styled-components";

export const ContentWrapper = styled.div``;
export const ThTitle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    svg {
        &:hover {
            cursor: pointer;
        }
    }
`;
export const WrapperTable = styled.div`
    display: block;
    overflow: auto;
    max-height: 400px;
    margin: 25px 0;
`;

export const Table = styled.table`
    border-collapse: separate;
    box-shadow: 0px 2px 20px 0px #0000001a;
    border-spacing: 0px;
    border-radius: 15px;
    width: 100%;
    position: relative;

    thead {
        position: sticky;
        top: 0;
        color: #fff;
        z-index: 1;
        background-color: ${(props) => props.theme.primaryColorLight};
        th {
            font-size: 1rem;
            padding: 0.5em 1em;
            font-weight: 700;
            color: #fff;
        }
        th:last-child {
            border-top-right-radius: 8px;
        }
        th:first-child {
            border-top-left-radius: 8px;
        }
    }
    tbody {
        position: relative;
        tr {
            background: #f2f4f5;
            font-weight: 700;
            font-size: 0.8rem;
            td:first-child {
                border-left: 2px solid #e0e9f1;
            }
            td:last-child {
                border-right: 2px solid #e0e9f1;
            }
        }
        tr:last-child {
            td {
                border-bottom: 2px solid #e0e9f1;
            }
            td:last-child {
                border-bottom-right-radius: 8px;
            }
            td:first-child {
                border-bottom-left-radius: 8px;
                border: 2px solid #e0e9f1;
                border-top: none;
                border-right: none;
            }
        }
        td {
            padding: 10px;
        }
    }
    tr {
        text-align: center;
    }
    tfoot {
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        tr {
            display: flex;
            place-content: center;
        }
    }
`;

export const ContentButton = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    align-items: center;
    svg {
        transition: transform 0.3s ease-in-out;
        &:hover {
            transform: scale(1.1);
            cursor: pointer;
        }
    }
    a {
        padding: 4px 8px;
        border-radius: 8px;

        color: #fff;
        text-decoration: none;
        &:hover {
            cursor: pointer;
        }
    }
`;

import styled from "styled-components";
import { tabletMediaQuery } from "styles/themes/mediaQueries";

export const Padding = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
`;
export const Button = styled.div`
    display: flex;
    justify-content: center;
    button {
        width: 86px;
        height: 39px;
        font-size: 1rem;
        font-weight: 500;
        text-align: center;
        padding: 0;
    }
`;
export const Register = styled.div`
    display: flex;
    grid-gap: 5px;
    align-items: center;
`;
export const Form = styled.form`
    width: 80%;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    ${tabletMediaQuery} {
        width: 70%;
    }
`;
export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
`;

export const ContentTitle = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const Title = styled.h3`
    font-size: 1.2rem;
    margin: 0 0 0.5em 0;
    font-weight: 700;
`;

export const Container = styled.div`
    margin: 10em 0 8em 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 380px;
    height: 332px;
    grid-gap: 10px;
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.primaryColor};
    box-shadow: 2.680000066757202px 2.680000066757202px 6.700000286102295px 0px
        #00000040;
`;

export const Logo = styled.div``;

export const ForgotPassowrd = styled.a`
    text-decoration: none;
    color: ${(props) => props.theme.mediumBlue};
    cursor: pointer;
`;

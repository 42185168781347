import { api } from "api/api";
import { useState } from "react";
import { toast } from "react-toastify";
import { ContactData } from "types/ContactData.type";

export function useContactEmail() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const postContactEmail = async (ContactData: ContactData) => {
        try {
            setIsLoading(true);
            const response = await api("contact", {
                method: "POST",
                data: JSON.stringify({
                    name: ContactData.name,
                    email: ContactData.email,
                    message: ContactData.message,
                }),
            });

            toast.success("Mensagem enviada com sucesso!");
            return response;
        } catch (error) {
            toast.error("Erro na tentativa de enviar a mensagem.");
            return error;
        } finally {
            setIsLoading(false);
        }
    };

    return { postContactEmail, isLoading };
}

import styled from "styled-components";

export const Close = styled.div`
    transition: transform 0.3s ease-in-out;
    svg {
        color: ${(props) => props.theme.primaryColorLight};
    }
    :hover {
        cursor: pointer;
        transform: scale(1.1);
    }
`;

export const RemoveBtn = styled.button`
width: 60px;
border: none;
font-size: 0.7rem;
border-radius: 8px;
color:  ${(props) => props.theme.white};
background-color: ${(props) => props.theme.red};
`;

export const Minus = styled.div`
    cursor: pointer;
`;
export const ContainerModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
`;

export const ExternalModal = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 99.4%;
    height: 101%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    @media (max-width: 700px) {
        justify-content: center;
    }
`;

export const Modal = styled.div`
    background: #fff;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 70vh;
    width: 550px;
    margin-top: 4.9em;
    box-shadow: 0px 10px 10px -3px rgba(0, 0, 0, 0.1),
        0px 4px 5px -2px rgba(0, 0, 0, 0.04),
        0px 4px 4px -2px rgba(0, 0, 0, 0.02);
    border: solid 1px ${(props) => props.theme.primaryColorLight};
    position: relative;
    @media (max-width: 700px) {
        width: 320px;
    }
`;
export const Header = styled.header`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1em;
    align-items: center;
    width: 100%;
    h3 {
        font-size: 1.25rem;
        font-weight: 700;
        color: #4d4d4d;
        margin: 0;
    }
    box-shadow: 0px 1px 1px 0px #00000026;
`;
export const Sacola = styled.button`
    position: relative;
    border: none;
    background-color: transparent;
    svg {
        width: 30px;
        height: 30px;
    }
`;
export const CountItens = styled.span`
    position: absolute;
    top: 0;
    right: 0.3em;
    border-radius: 50%;
    background-color: #ff6969;
    color: #fff;
    width: 15px;
    height: 15px;
    font-size: 0.6rem;
    font-weight: 700;
`;

export const Footer = styled.footer`
    border-top: 1px solid #d9d9d9;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 100%;
    padding: 1em 3em;
`;
export const Total = styled.span`
    font-size: 1.25rem;
    font-weight: 700;
`;
export const Value = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
export const ItemSacola = styled.div`
    display: flex;
    flex-direction: row;
    gap: 2em;
    padding: 1em;
    @media (max-width: 700px) {
        align-items: center;
    }
    img {
        width: 205px;
        height: 162px;
        @media (max-width: 700px) {
            width: 105px;
            height: auto;
            object-fit: cover;
        }
    }
    box-shadow: 0px 1px 1px 0px #00000026;
`;
export const DescriptionSacola = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1em;
    h4 {
        font-size: 1.1rem;
        font-weight: 700;
        margin: 0;
    }
    span {
        font-size: 0.8rem;
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
`;

export const ProductQuantity = styled.div`
    border-radius: 4px;
    border: 0.5px solid #4d4d4d;
    padding: 0em 0.4em;
    font-weight: 600;
`;
export const ContentSacola = styled.div`
    height: 500px;
    overflow: auto;
`;
export const ContentProduct = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1em;
`;

export const ContentQuantity = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
    svg {
        width: 20px;
        height: 20px;
    }
`;
export const ValueProduct = styled.div`
    font-size: 0.9rem;
`;
export const ButtonConfirmar = styled.button`
    border-radius: 8px;
    font-weight: 500;
    color: ${(props) => props.theme.white};
    font-size: 1.1rem;
    padding: 0.3em 1em;
    border: none;
    background-color: ${(props) => props.theme.primaryColorLight};
`;

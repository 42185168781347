export default () => (
    <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M20 11L2 11"
            stroke="white"
            strokeWidth="4"
            stroke-linecap="round"
        />
        <path
            d="M11 20L11 2"
            stroke="white"
            strokeWidth="4"
            stroke-linecap="round"
        />
    </svg>
);

import Title from "components/Title";
import * as S from "./style";
import { Loading, ServicesItem, WhatsappButton } from "components";
import { getDisplayService } from "hooks";
import { useEffect } from "react";

export function ServicesPage(): JSX.Element {
    const {
        isLoadingServiceDisplay,
        listServiceDisplay,
        refetchServiceDisplay,
    } = getDisplayService();

    useEffect(() => {
        async function response() {
            await refetchServiceDisplay();
        }
        response();
    }, []);

    return (
        <>
            <S.ContainerTitle>
                <Title title="Serviços que oferecemos" />
            </S.ContainerTitle>
            <S.Container>
                {isLoadingServiceDisplay ? (
                    <Loading size={20} color="#487B9D" />
                ) : (
                    listServiceDisplay?.map((item: any, index: number) => {
                        if (item.id != "66212653531a71dceb04ca73") {
                            return (
                                <ServicesItem
                                    key={item?.id}
                                    imageSrc={item?.imageDisplay}
                                    subtitle={item?.title}
                                    text={item?.description}
                                    reversed={index % 2 !== 0}
                                    id={item?.id}
                                />
                            );
                        }
                    })
                )}
            </S.Container>
            <WhatsappButton />
        </>
    );
}

import Title from "components/Title";
import * as S from "./style";

import ImgCarousel1 from "assets/images/carlos.jpg";
import ImgCarousel3 from "assets/images/giba.png";
import ImgCarousel2 from "assets/images/rafael.jpg";

interface TestimonialsData {
    id: number;
    imageSrc: string;
    subtitle: string;
    text: string;
    especialidade: string;
}

const Testimonials: React.FC = () => {
    const testimonialsData: TestimonialsData[] = [
        {
            id: 1,
            imageSrc: ImgCarousel1,
            subtitle: "Carlos Marsi",
            especialidade: "Arquiteto",
            text: "A plataforma nos auxilia não só no cotidiano, mas em altas demandas também, algo corriqueiro nos escritórios de arquitetura, reduz o tempo na preparação das imagens, deixando a equipe livre para se dedicar a criação de novos projetos.",
        },
        {
            id: 2,
            imageSrc: ImgCarousel2,
            subtitle: "Rafael Derderian",
            especialidade: "Arquiteto",
            text: "O Arktools revoluciona nosso escritório ao otimizar o tempo necessário para renderizar imagens, trazendo nossa criatividade para mais perto da realidade. Além disso, a plataforma oferece opções exclusivas de imagens artísticas, ideais para a finalização de nossos projetos. Essas imagens garantem uma identidade personalizada e distinta para cada trabalho que desenvolvemos.",
        },
        {
            id: 3,
            imageSrc: ImgCarousel3,
            subtitle: "Gilberto Dias",
            especialidade: "3D Designer",
            text: "O Arktools oferece aos arquitetos e designers uma maneira poderosa de visualizar, comunicar e melhorar seus projetos. Essas ferramentas agilizam o processo e ajudam a produzir um produto final que atende melhor às expectativas dos clientes, gostei muito das possibilidades disponíveis na Plataforma da Arktools. Recomendo!",
        },
    ];

    return (
        <>
            <S.ContainerTitleSubtitle>
                <Title title="Conheça nossos parceiros" />
            </S.ContainerTitleSubtitle>
            <S.StyledCarousel
                showArrows={false}
                showStatus={false}
                showIndicators={false}
                showThumbs={false}
                emulateTouch={true}
                transitionTime={1200}
            >
                {testimonialsData.map((item) => (
                    <S.CarouselSlide key={item.id}>
                        <S.ContainerFlex>
                            <S.Image src={item.imageSrc} />
                            <S.ContainerText>
                                <S.Subtitle>{item.subtitle}</S.Subtitle>
                                <S.Especialidade>
                                    {item.especialidade}
                                </S.Especialidade>
                                <S.Text>
                                    <S.Span>“ </S.Span>
                                    {item.text}
                                </S.Text>
                            </S.ContainerText>
                        </S.ContainerFlex>
                    </S.CarouselSlide>
                ))}
            </S.StyledCarousel>
        </>
    );
};

export default Testimonials;

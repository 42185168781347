import styled from "styled-components";
import { mobileMediaQuery } from "styles/themes/mediaQueries";

// Definição dos outros componentes...

export const Form = styled.form`
    margin-top: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;

    @media (max-width: 1024px) {
        width: 80%;
        margin: auto;
    }
`;

export const ContainerTextarea = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1.5%;
    gap: -10px;
    height: 30%;
`;

export const ContainerCheckbox = styled.div`
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
`;

export const ContainerButton = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 3em;

    @media (max-width: 1024px) {
        margin-top: 4em;
    }

    ${mobileMediaQuery} {
        margin-top: 4em;
    }
`;

export const LabelCheckbox = styled.label`
    font-size: 0.7rem;
    font-weight: 700;
`;

export const Checkbox = styled.input`
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 0;
`;

export const Terms = styled.span`
    cursor: pointer;
    color: ${(props) => props.theme.mediumBlue};
`;

export const ContainerInputs = styled.div`
    display: flex;
    gap: 20px;
    font-weight: 500;
    margin-bottom: 1em;

    @media (max-width: 1024px) {
        flex-direction: column;
    }
`;

export const Image = styled.img`
    margin-top: 20px; // Estilização opcional
    width: 100%;
    max-width: 400px; // Limita a largura da imagem
`;

import * as S from "./style";
import { Button, Input, HelperText, Loading } from "components";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useContactEmail } from "hooks";
import { useNavigate } from "react-router-dom";

export default function FormContact() {
    const { postContactEmail, isLoading } = useContactEmail();

    const formSchema = z.object({
        name: z.string().min(3, "Nome é obrigatório"),
        email: z.string().email("Email inválido"),
        message: z.string().min(10, "Mensagem é obrigatória"),
        terms: z
            .boolean()
            .refine(
                (value) => value === true,
                "Aceitar os termos é obrigatório!"
            ),
    });
    const router = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: zodResolver(formSchema),
        mode: "onBlur",
    });

    const onSubmit = async (data: any) => {
        await postContactEmail(data);
    };

    return (
        <S.Form onSubmit={handleSubmit(onSubmit)}>
            <S.ContainerInputs>
                <Input.Text
                    {...register("name")}
                    type="text"
                    placeholder="Insira seu nome"
                    label="Seu nome"
                    autoComplete="name"
                />
                {errors?.name?.message &&
                    typeof errors.name.message === "string" && (
                        <HelperText helperText={errors.name.message} />
                    )}
                <Input.Text
                    {...register("email")}
                    type="email"
                    placeholder="Insira seu email"
                    label="E-mail"
                    autoComplete="email"
                />
                {errors?.email?.message &&
                    typeof errors.email.message === "string" && (
                        <HelperText helperText={errors.email.message} />
                    )}
            </S.ContainerInputs>
            <S.ContainerTextarea>
                <Input.Textarea
                    {...register("message")}
                    label="Sua mensagem"
                    placeholder="Escreva sua mensagem..."
                    rows={10}
                    cols={50}
                />
                {errors?.message?.message &&
                    typeof errors.message.message === "string" && (
                        <HelperText helperText={errors.message.message} />
                    )}
            </S.ContainerTextarea>
            <S.ContainerCheckbox>
                <S.Checkbox type="checkbox" id="terms" {...register("terms")} />
                {errors?.terms?.message &&
                    typeof errors.terms.message === "string" && (
                        <HelperText helperText={errors.terms.message} />
                    )}
                <S.LabelCheckbox htmlFor="terms">
                    Eu li e aceito os{" "}
                    <S.Terms onClick={() => router("/politica")}>
                        Termos e condições
                    </S.Terms>{" "}
                    e a{" "}
                    <S.Terms onClick={() => router("/politica")}>
                        Política de privacidade
                    </S.Terms>
                    .
                </S.LabelCheckbox>
            </S.ContainerCheckbox>
            <S.ContainerButton>
                <Button.Small type="submit">
                    {isLoading ? <Loading size={8} /> : "Enviar mensagem"}
                </Button.Small>
            </S.ContainerButton>
        </S.Form>
    );
}

import Accordion from "react-bootstrap/Accordion";
import "bootstrap/dist/css/bootstrap.min.css";
import * as S from "./style";
import { Container, Title } from "components";
import Card from "react-bootstrap/Card";
import CustomToggle from "./CustomToggle";
import { useState } from "react";
import { FaqType } from "types";
import { FaqItems } from "./faq";

export default function Faq() {
    const [openKey, setOpenKey] = useState(null);

    const handleToggle = (key: any) => {
        setOpenKey(openKey === key ? null : key);
    };

    return (
        <Container>
            <S.ContainerTitle>
                <Title title="Perguntas frequentes" />
            </S.ContainerTitle>
            <S.Wrapper>
                <S.AccordionContainer>
                    {FaqItems.map((item: FaqType, index: any) => (
                        <Card key={index}>
                            <Card.Header
                                style={{
                                    backgroundColor:
                                        openKey === index
                                            ? "#D2E7F4"
                                            : "transparent",
                                }}
                            >
                                {item.title}
                                <CustomToggle
                                    eventKey={index}
                                    isOpen={openKey === index}
                                    onToggle={handleToggle}
                                />
                            </Card.Header>
                            <Accordion.Collapse eventKey={index}>
                                <Card.Body>{item.description}</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ))}
                </S.AccordionContainer>
            </S.Wrapper>
        </Container>
    );
}

import * as S from "./style";
import { ChatContent } from "contexts/Chat.context";
import { Container, Message, SidebarChat } from "components";
import { useContext, useEffect } from "react";
import { usePostMessage } from "hooks";
export default function Chat() {
    const { recipient, setMessage } = useContext(ChatContent);

    const { postMessage } = usePostMessage();
    useEffect(() => {
        async function getMessage() {
            const response = await postMessage(recipient);
            setMessage(response);
        }
        recipient && getMessage();
    }, [recipient]);
    return (
        <Container>
            <S.Content>
                <h1>Chat</h1>
                <S.Wrapper>
                    <SidebarChat />
                    {recipient && <Message recipient={recipient} />}
                </S.Wrapper>
            </S.Content>
        </Container>
    );
}

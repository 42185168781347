import { InputType } from "types";
import * as S from "./style";
import { BsSearch } from "react-icons/bs";

export default function InputSearch({ type = "text", ...props }: InputType) {
    return (
        <S.ContainerSearch>
            <S.Svg>
                <BsSearch />
            </S.Svg>
            <S.Input type={type} {...props} />
        </S.ContainerSearch>
    );
}

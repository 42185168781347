import styled, { css } from "styled-components";
import { mobileMediaQuery } from "styles/themes/mediaQueries";
import DatePicker from "react-datepicker";

type InputFileType = {
    align: string;
};
type InputCheckbox = {
    color: string;
};

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-gap: 5px;
`;

export const HelperText = styled.div`
    color: ${(props) => props.theme.red};
    font-size: 0.8rem;
    display: flex;
    align-items: center;
`;

export const Label = styled.label`
    color: ${(props) => props.theme.textDark};
    font-size: 1rem;
`;

export const Input = styled.input<{ hasError?: boolean }>`
    border: 1px solid ${(props) => props.theme.primaryColorDark};
    border-radius: 5.36px;
    outline: none;
    height: 31px;
    padding: 0 14px;
    font-size: 0.8rem;
    font-family: "Quicksand Variable", sans-serif;
    &::placeholder {
        color: #c0c0c0;
    }
    &:focus {
        outline: 1px solid ${(props) => props.theme.primaryColorDark};
        border: 1px solid ${(props) => props.theme.primaryColor};
    }
    ${({ hasError }) =>
        hasError &&
        css`
            border-color: ${(props) => props.theme.red};
        `}
`;

export const DatePickerStyle = styled(DatePicker)<{ hasError?: boolean }>`
    border: 1px solid ${(props) => props.theme.primaryColorDark};
    border-radius: 5.36px;
    outline: none;
    height: 31px;
    padding: 0 14px;
    font-size: 0.8rem;
    width: 300px;
    font-family: "Quicksand Variable", sans-serif;
    &::placeholder {
        color: #c0c0c0;
    }
    &:focus {
        outline: 1px solid ${(props) => props.theme.primaryColorDark};
        border: 1px solid ${(props) => props.theme.primaryColor};
    }
    ${({ hasError }) =>
        hasError &&
        css`
            border-color: ${(props) => props.theme.red};
        `}
`;

export const Select = styled.select<{ hasError?: boolean }>`
    border: 1px solid ${(props) => props.theme.primaryColorLight};
    border-radius: 4px;
    outline: none;
    padding: 12px 8px;
    font-size: 14px;
    &:focus {
        outline: 1px solid ${(props) => props.theme.primaryColorDark};
        border: 1px solid ${(props) => props.theme.primaryColor};
    }
    ${({ hasError }) =>
        hasError &&
        css`
            border-color: ${(props) => props.theme.red};
        `}
`;

export const InputFile = styled.input`
    width: 50em;
    border: dashed 1px blue;
    padding: 20px;
    background: gray;
`;

export const ContainerSearch = styled(Container)`
    flex-direction: row;
    justify-content: center;
    gap: 0;
`;

export const Svg = styled.div`
    background-color: ${(props) => props.theme.white};
`;

export const StyledFileInputContainer = styled.div<InputFileType>`
    display: flex;
    position: relative;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    align-items: ${(props) => (props.align ? props.align : "center")};
`;

export const StyledFileInput = styled.input`
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    height: 10em;
    width: 100%;
    height: 265px;
    cursor: pointer;
`;

export const StyledButton = styled.button`
    border: dashed 3px #c0c0c0;
    border-radius: 50px;
    color: black;
    cursor: pointer;
    width: 300px;
    height: 265px;
    background-color: rgba(210, 231, 244, 0.1);
    ${mobileMediaQuery} {
        width: 226px;
        height: 189px;
    }
`;

export const UploadTitle = styled.span`
    font-size: 1.2rem;
    font-weight: 700;
    ${mobileMediaQuery} {
        font-size: 1rem;
    }
`;

export const UploadSubTitle = styled.span`
    font-size: 1rem;
    font-weight: 700;
    margin-top: 0.5em;
    ${mobileMediaQuery} {
        font-size: 0.75rem;
    }
`;

export const UploadType = styled.span`
    font-size: 0.9rem;

    font-weight: 500;
    ${mobileMediaQuery} {
        font-size: 0.625rem;
    }
`;

export const Upload = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;

    svg {
        width: 44px;
        height: 41px;
        color: ${(props) => props.theme.primaryColorLight};
        ${mobileMediaQuery} {
            width: 31px;
            height: 29px;
        }
    }
`;

export const UploadOpen = styled.div`
    margin-bottom: 2.5em;
    button {
        height: 30px;
        padding: 10px 15px;
    }
    ${mobileMediaQuery} {
        margin-bottom: 1em;
    }
`;

export const InputTextarea = styled.textarea<{ hasError?: boolean }>`
    resize: none;
    font-family: "Quicksand Variable", sans-serif;
    padding: 10px;
    border-radius: 8px;
    ${({ hasError }) =>
        hasError &&
        css`
            border-color: ${(props) => props.theme.red};
        `}
`;

export const LabelTextarea = styled.label`
    font-weight: 500;
    font-size: 1rem;
`;

export const Checkbox = styled.input`
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 0;
`;

export const LabelCheckbox = styled.label<InputCheckbox>`
    font-size: 0.7rem;
    font-weight: 700;
    color: ${(props) => (props.color ? "#B0B0B0" : "#4D4D4D")};
`;

export const UploadOpenContrat = styled(UploadOpen)`
    margin-top: 1.5em;
    margin-left: 5em;
`;

export const StyledFileInputContrat = styled(StyledFileInput)`
    height: 320px;
    @media (max-width: 700px) {
        height: 290px;
    }
`;

import { useState } from "react";
import * as S from "./style";

import { About, Export } from "assets/icons";
import { Loading } from "components";
import moment from "moment";
import { BiChevronDownSquare, BiEditAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Tooltip from "../Tooltip";
type typeTable = {
    data: any;
    loading: boolean;
};
export default function Table({ data, loading }: typeTable) {
    const [showTooltip, setShowTooltip] = useState(false);
    const roleNumber: any = localStorage.getItem("user");
    const router = useNavigate();
    const size = 20;

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    return (
        <S.WrapperTable>
            <S.Table>
                <thead>
                    <tr>
                        <th>
                            <S.ThTitle>
                                Projeto <BiChevronDownSquare size={size} />
                            </S.ThTitle>
                        </th>
                        <th>
                            <S.ThTitle>
                                Responsável <BiChevronDownSquare size={size} />
                            </S.ThTitle>
                        </th>
                        <th>
                            <S.ThTitle>
                                Serviço <BiChevronDownSquare size={size} />
                            </S.ThTitle>
                        </th>
                        <th>
                            <S.ThTitle>
                                Quantidade <BiChevronDownSquare size={size} />
                            </S.ThTitle>
                        </th>
                        <th>
                            <S.ThTitle>
                                Material <BiChevronDownSquare size={size} />
                            </S.ThTitle>
                        </th>
                        <th>
                            <S.ThTitle>
                                Contratação <BiChevronDownSquare size={size} />{" "}
                            </S.ThTitle>
                        </th>
                        <th>
                            <S.ThTitle>
                                Finalização
                                <BiChevronDownSquare size={size} />
                            </S.ThTitle>
                        </th>
                        <th>
                            <S.ThTitle>
                                Status <BiChevronDownSquare size={size} />
                            </S.ThTitle>
                        </th>
                        <th>
                            <S.ContentWrapper
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                <About />
                            </S.ContentWrapper>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {showTooltip && <Tooltip />}

                    {!loading &&
                        data?.upload?.length > 0 &&
                        data?.upload?.map((item: any) => (
                            <tr key={item?.id}>
                                <td>
                                    {item?.nameProject
                                        ? item?.nameProject
                                        : item?.originalname}
                                </td>

                                <td>{item?.user[0]?.name}</td>

                                <td>{item?.service[0]?.title}</td>
                                <td>{item?.quantidade}</td>
                                <td>{item?.material}</td>
                                <td>
                                    {moment(item?.date).format(
                                        "HH:mm A DD/MM/YYYY"
                                    )}
                                </td>
                                <td>
                                    {item?.finalDate ==
                                    "1900-01-01T00:00:00.000Z"
                                        ? ""
                                        : moment(item?.finalDate).format(
                                              "HH:mm A DD/MM/YYYY"
                                          )}
                                </td>
                                <td>{item?.status}</td>
                                <td>
                                    <S.ContentButton>
                                        {roleNumber == 1 && (
                                            <BiEditAlt
                                                size={24}
                                                color="#487B9D"
                                                onClick={() =>
                                                    router(
                                                        `/edit-document/${item?.id}`
                                                    )
                                                }
                                            />
                                        )}
                                        {item?.status == "complete" && (
                                            <a href={item?.linkdownload}>
                                                <Export />
                                            </a>
                                        )}
                                    </S.ContentButton>
                                </td>
                            </tr>
                        ))}
                </tbody>
                {loading && (
                    <tfoot>
                        <tr>
                            <td>
                                <Loading size={10} color="#141d33" />
                            </td>
                        </tr>
                    </tfoot>
                )}

                {!loading && data?.upload?.length == 0 && (
                    <tfoot>
                        <tr>
                            <td>
                                <span>Nenhum arquivo cadastrado!</span>
                            </td>
                        </tr>
                    </tfoot>
                )}
            </S.Table>
        </S.WrapperTable>
    );
}

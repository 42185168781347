import styled from "styled-components";

export const Wrapper = styled.div`
    margin-top: center;
    text-align: center;
`;
export const ButtonBlue = styled.div`
    align-items: end;
    max-width: 680px;
    display: flex;

    button {
        margin-top: 1em;
        background-color: ${(props) => props.theme.primaryColorLight};
        border-radius: 12px;
        color: ${(props) => props.theme.white};
        font-size: 1rem;
        text-align: center;
        border: none;
        font-weight: 500;
        padding: 0.5em;
        max-width: 228px;
    }
`;
export const h3 = styled.h3`
    font-size: 1.2rem;
    font-weight: 700;
    color: #4d4d4d;
`;

export const ContentNewService = styled.div`
    border: 0.1px solid #e8e8e8;
    border-radius: 8px;
    box-shadow: 0px 3px 8px 0px #0000001a;
    max-width: 680px;
    padding: 1em;
    span {
        font-weight: 600;
        text-align: justify;
        color: #4d4d4d;
    }
`;

export const NewService = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

import { ContainerCarousel, Title } from "components";
import * as S from "./style";

export default function Projects() {
    return (
        <S.ContainerProjects>
            <Title title="Projetos" />
            <ContainerCarousel />
        </S.ContainerProjects>
    );
}

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "pages/login";
import Layout from "components/layout";
import PrivateRoute from "./PrivateRoute";
import ThreeDIserter from "pages/3d-inserter";
import Register from "pages/register";
import Orders from "pages/orders";
import ConfirmMail from "pages/confirmemail";
import NotFound from "pages/notfound";
import EditDocument from "pages/edit-document";
import Chat from "pages/chat";
import Home from "pages/home";
import ReadMore from "pages/readmore";
import { ServicesPage } from "pages/servicesPage";
import Projects from "pages/projects";
import DetailedProject from "pages/detailedProject";
import Contact from "pages/contact";
import Faq from "pages/faq";
import ScanningHiring from "pages/scanning-Hiring";
import ServiceContracting from "pages/service-contracting";
import DetailService from "pages/detailService";
import Payment from "pages/payments";
import ConfirmOrder from "pages/confirmOrder";
import ValidateAccount from "pages/validateAccount";
import Politica from "pages/politica";

export default function MainRoutes() {
    return (
        <Router>
            <Routes>
                <Route element={<Layout />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/readmore/:id" element={<ReadMore />} />
                    <Route path="/servicesPage" element={<ServicesPage />} />
                    <Route path="/projects" element={<Projects />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/faq" element={<Faq />} />

                    <Route
                        path="/scanninghiring/:id"
                        element={
                            <PrivateRoute>
                                <ScanningHiring />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/detailedProject/:id"
                        element={<DetailedProject />}
                    />

                    <Route
                        path="/confirm-email/:token"
                        element={<ConfirmMail />}
                    />
                    <Route
                        path="/validateAccount"
                        element={<ValidateAccount />}
                    />

                    <Route path="/politica" element={<Politica />} />
                    <Route
                        path="/detailService/:id"
                        element={<DetailService />}
                    />
                    <Route
                        path="/confirmOrder"
                        element={
                            <PrivateRoute>
                                <ConfirmOrder />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/service-contracting"
                        element={
                            <PrivateRoute>
                                <ServiceContracting />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/payment"
                        element={
                            <PrivateRoute>
                                <Payment />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/chat"
                        element={
                            <PrivateRoute>
                                <Chat />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/edit-document/:id"
                        element={
                            <PrivateRoute>
                                <EditDocument />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/3d-inserter"
                        element={
                            <PrivateRoute>
                                <ThreeDIserter />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/orders"
                        element={
                            <PrivateRoute>
                                <Orders />
                            </PrivateRoute>
                        }
                    />
                </Route>
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Router>
    );
}

import styled from "styled-components";
import BannerImg from "assets/images/BannerParcerias.png";

export const ImageBanner = styled.img``;

export const Icons = styled.img`
    @media (max-width: 999px) {
        width: 120px;
    }

    @media (max-width: 450px) {
        width: 80px;
    }
`;

export const BackgroundImage = styled.div`
    background-image: url(${BannerImg});
    width: 100%;
    background-size: cover;
    padding: 4em 0;

    @media (max-width: 450px) {
        padding: 2em 0;
    }
`;

export const ContainerIcons = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media (max-width: 450px) {
        padding: 0 1em;
    }
`;

export const Ancor = styled.a`
    cursor: pointer;
`;

import { api } from "api/api";
import { useState } from "react";
import { toast } from "react-toastify";
import { RegisterType } from "types";

export function useUsers() {
    const [isloading, setIsloading] = useState<boolean>(false);
    const postUser = async (userData: RegisterType) => {
        try {
            setIsloading(true);
            const response = await api("user/", {
                method: "POST",
                data: JSON.stringify({
                    name: userData.name,
                    email: userData.email,
                    phone: userData.phone.replace(/[.\-/]/g, ""),
                    document: userData.document.replace(/[./-]/g, ""),
                    password: userData.password,
                }),
            });
            toast.success(response?.message);
            return response;
        } catch (error) {
            toast.error("Error when trying to register a user!");
            return error;
        } finally {
            setIsloading(false);
        }
    };
    return { postUser, isloading };
}

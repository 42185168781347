import styled from "styled-components";

export const ContentImg = styled.div`
    svg {
        width: 30px;
        height: 30px;
        border-radius: 50px;
        box-shadow: 0px 0px 1px 1px #c7c7c7;
        object-fit: cover;
        padding: 10px;
    }
`;

export const Aside = styled.aside`
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    height: 450px;
    max-height: 550px;
    overflow-y: auto;
    background-color: ${(props) => props.theme.white};
    box-shadow: 0 0 1px 1px #dfdfdf;
    border-radius: 8px;
`;

export const Wrapper = styled.div`
    display: flex;
    grid-gap: 10px;
    padding: 5px 10px;
    transition: 0.1s ease-in-out;
    &:hover {
        background-color: ${(props) => props.theme.secondaryColor};
        cursor: pointer;
        border-radius: 8px;
        color: ${(props) => props.theme.white};
    }
`;

export const ContentSpan = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
`;

export const NewChat = styled.div`
    display: flex;
    align-items: center;
    justify-content: right;
    margin-top: 0.5em;
    margin-right: 0.5em;
    button {
        display: flex;
        align-items: center;
        gap: 5px;
    }
`;

import { Container, Loading } from "components";
import { useGetCarrinho } from "hooks";
import { BsChevronLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { CalculoValorTotal } from "utils/calculoValorTotal";
import * as S from "./style";
export default function ConfirmOrder() {
    const router = useNavigate();
    const { listCarrinho, isLoadingListCarrinho } = useGetCarrinho();
    const formatValue = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
    });
    return (
        <Container>
            <S.ContainerConfirmOrder>
                <S.Content>
                    <h2>Contratação de serviço</h2>
                    <S.Header>
                        <strong>Serviço a contratar </strong>
                        <strong>Preço</strong>
                    </S.Header>
                    {isLoadingListCarrinho ? (
                        <Loading size={20} color="#487B9D" />
                    ) : (
                        listCarrinho?.map((item: any, index: number) => {
                            const valorTotal = CalculoValorTotal({
                                listExtraStyles: item?.upload[0]?.extraStyles,
                                listService: item.service[0],
                            });
                            return (
                                <S.ContentData key={index}>
                                    <span>{item?.service[0]?.title}</span>
                                    <span>
                                        {formatValue.format(valorTotal)}
                                    </span>
                                </S.ContentData>
                            );
                        })
                    )}
                </S.Content>
                <S.ContentButton>
                    <S.AddService
                        type="button"
                        onClick={() => router("/service-contracting")}
                    >
                        <BsChevronLeft size={15} />
                        Adicionar mais serviços
                    </S.AddService>
                    <S.ButtonConfirmar onClick={() => router("/payment")}>
                        Fechar pedido
                    </S.ButtonConfirmar>
                </S.ContentButton>
            </S.ContainerConfirmOrder>
        </Container>
    );
}

import styled from "styled-components";

export const ButtonUser = styled.button`
    border: none;
    background-color: ${(props) => props.theme.primaryColorLight};
    border-radius: 50%;
    /* padding: 5px; */
    width: 34px;
    height: 34px;
`;

export const WrapperDropdown = styled.div`
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    background-color: ${(props) => props.theme.white};
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    position: absolute;
    top: 4em;
    right: -9em;
    /* width: 273px; */
    padding: 1em;
    border-radius: 8px;
    width: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    @media (max-width: 1000px) {
        right: -18em;
    }
`;
export const HeaderDropdown = styled.header`
    display: flex;
    flex-direction: row;
    gap: 1em;

    h4 {
        font-size: 1rem;
        font-weight: 600;
        margin: 0;
    }
    span {
        font-size: 0.75rem;
    }
    div {
        width: auto !important;
        height: auto !important;
    }
`;
export const ListDropdown = styled.div`
    display: flex;
    justify-content: center;
    ul {
        list-style: none;
        font-size: 1rem;
        padding: 0;
    }
    li {
        margin-top: 0.5em;
        &:hover {
            cursor: pointer;
            color: ${(props) => props.theme.primaryColorLight};
        }
    }
`;

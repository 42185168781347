import { api } from "api/api";
import { useQuery } from "react-query";

export function getServiceAuthenticate() {
    const {
        data: listServiceAuthenticate,
        refetch: refetchServiceAuthenticate,
        isFetching: isLoadingServiceAuthenticate,
    } = useQuery(["listServiceAuthenticate"], () => api("services"));

    return {
        listServiceAuthenticate,
        refetchServiceAuthenticate,
        isLoadingServiceAuthenticate,
    };
}

export function getDisplayService() {
    const {
        data: listServiceDisplay,
        refetch: refetchServiceDisplay,
        isFetching: isLoadingServiceDisplay,
    } = useQuery(["listServiceDisplay"], () => api("services/display"));

    return {
        listServiceDisplay,
        refetchServiceDisplay,
        isLoadingServiceDisplay,
    };
}

export async function getServiceID(id: string) {
    try {
        const response = await api(`service/${id}`, { method: "get" });

        return response;
    } catch (error) {
        return error;
    }
}

export default () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12 13.25V2M5.75 14.5H3.25C2.91848 14.5 2.60054 14.6317 2.36612 14.8661C2.1317 15.1005 2 15.4185 2 15.75V20.75C2 21.5 2.5 22 3.25 22H20.75C21.5 22 22 21.5 22 20.75V15.75C22 15 21.5 14.5 20.75 14.5H18.25M17 8.25L12 14.5L7 8.25"
            stroke="#487B9D"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

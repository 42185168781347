import { z } from "zod";

export const ScanningHiringSchema = z.object({
    nameProject: z.string().min(1, { message: "Nome do projeto obrigatório!" }),
    file: z.any().refine((value) => value.length > 0, {
        message: "O documento é obrigatório!",
    }),
    comentario: z
        .string()
        .min(1, { message: "Comentário do projeto obrigatório!" }),
    validFile: z.boolean().refine((value) => value === true, {
        message: "O usuário deve marcar a caixa de seleção!",
    }),
    authorization: z.boolean(),
    
});

import styled from "styled-components";

export const WrapperTooltip = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1em;
    position: absolute;
    color: black;
    background-color: white;
    top: 0;
    right: 2em;
    z-index: 1;
    font-size: 0.8rem;
    max-width: 350px;
    padding: 2em;
    border-radius: 5px;
    box-shadow: 4px 4px 20px 0px #0000001a;
    text-align: start;
    font-weight: 500;
    strong {
        color: ${(props) => props.theme.primaryColorLight};
    }
`;

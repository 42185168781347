import * as S from "./style";
import { BannerShip } from "./banner";
import { BannerShipType } from "types";

export default function BannerPartnership() {
    return (
        <S.BackgroundImage>
            <S.ContainerIcons>
                {BannerShip.map((item: BannerShipType) => (
                    <S.Ancor
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={item.id}
                    >
                        <S.Icons src={item.icon} alt="" />
                    </S.Ancor>
                ))}
            </S.ContainerIcons>
        </S.BackgroundImage>
    );
}

import { Container, Loading, Title } from "components";
import { getServiceAuthenticate } from "hooks";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GetService } from "utils/typeService";
import * as S from "./style";

export default function ServiceContracting() {
    const router = useNavigate();
    const {
        isLoadingServiceAuthenticate,
        listServiceAuthenticate,
        refetchServiceAuthenticate,
    } = getServiceAuthenticate();

    useEffect(() => {
        refetchServiceAuthenticate();
    }, []);
    return (
        <>
            <Container>
                <S.Wrapper>
                    <Title title="Contratação de serviço" />
                    <S.Subtitle>
                        Selecione abaixo o serviço que deseja contratar
                    </S.Subtitle>
                </S.Wrapper>
            </Container>
            <S.Background>
                <Container>
                    {" "}
                    {isLoadingServiceAuthenticate ? (
                        <Loading size={20} color="#487B9D" />
                    ) : (
                        <S.ContainerService>
                            {listServiceAuthenticate?.map((item: any) => {
                                if (item.id != "66212653531a71dceb04ca73") {
                                    return (
                                        <S.WrapperService key={item?.id}>
                                            <S.ContentService>
                                                <S.ServiceImage>
                                                    <img
                                                        src={item?.imageURL}
                                                        alt={item.title}
                                                    />
                                                </S.ServiceImage>
                                                <S.PaddingService>
                                                    <S.ServiceInfo>
                                                        <h3>{item.title}</h3>
                                                        <span>
                                                            {item.description}
                                                        </span>
                                                    </S.ServiceInfo>
                                                    <S.ContentButton>
                                                        <S.ButtonBlue
                                                            disabled={
                                                                item.id !==
                                                                GetService()[
                                                                    "imagensRealista"
                                                                ]
                                                            }
                                                            onClick={() =>
                                                                router(
                                                                    `/scanninghiring/${item.id}`
                                                                )
                                                            }
                                                        >
                                                            Contratar
                                                        </S.ButtonBlue>
                                                        <S.ButtonMore
                                                            onClick={() =>
                                                                router(
                                                                    `/detailService/${item.id}`
                                                                )
                                                            }
                                                        >
                                                            Saiba mais
                                                        </S.ButtonMore>
                                                    </S.ContentButton>
                                                </S.PaddingService>
                                            </S.ContentService>
                                        </S.WrapperService>
                                    );
                                }
                            })}
                        </S.ContainerService>
                    )}
                </Container>
            </S.Background>
        </>
    );
}

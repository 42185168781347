export default () => (
    <svg
        width="120"
        height="120"
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="0.200195"
            y="0.5"
            width="119"
            height="119"
            rx="8"
            fill="url(#paint0_linear_1152_1704)"
        />
        <path
            d="M70.5421 73.4163H79.1671M79.1671 73.4163L74.3754 68.6247M79.1671 73.4163L74.3754 78.208M60.0004 40.833L55.2087 45.6247L60.0004 50.4163"
            stroke="white"
            strokeWidth="2.875"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M57.1254 45.625C60.9379 45.625 64.5942 47.1395 67.2901 49.8353C69.9859 52.5312 71.5004 56.1875 71.5004 60C71.5004 63.8125 69.9859 67.4688 67.2901 70.1647C64.5942 72.8605 60.9379 74.375 57.1254 74.375H40.8337"
            stroke="white"
            strokeWidth="2.875"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M49.9495 47.541C47.7603 48.8016 45.9422 50.617 44.6783 52.8042C43.4144 54.9914 42.7495 57.4732 42.7505 59.9993C42.7505 63.2347 43.82 66.2208 45.6255 68.6243"
            stroke="white"
            strokeWidth="2.875"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <defs>
            <linearGradient
                id="paint0_linear_1152_1704"
                x1="60.527"
                y1="2.61422"
                x2="58.854"
                y2="119.488"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#487B9D" />
                <stop offset="0.447917" stop-color="#79AAC1" />
                <stop offset="1" stop-color="#A3D1E0" />
            </linearGradient>
        </defs>
    </svg>
);

import styled, { css } from "styled-components";
import { mobileMediaQuery } from "styles/themes/mediaQueries";

interface ContainerFlexProps {
    flexReversed?: boolean;
}

export const ContainerFlex = styled.div<ContainerFlexProps>`
    display: flex;
    gap: 30px;
    flex-direction: row;
    margin-bottom: 4em;
    align-items: center;

    box-shadow: 1px 2px 6px 0px #487b9d26, -2px -2px 6px 0px #487b9d26;
    background-color: #fcfeff;
    border-radius: 8px;
    ${(props) =>
        props.flexReversed &&
        css`
            flex-direction: row-reverse;
            /* padding-left: 4em; */
        `}

    ${mobileMediaQuery} {
        text-align: start;
        padding: 0 2em;
    }

    @media (max-width: 300px) {
        padding: 0;
    }

    @media (max-width: 1024px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: start;

        & > div {
            order: 2;
        }

        & > div:first-child {
            order: 1;
        }
    }
`;

export const ContainerImage = styled.div`
    order: 1;
    transition: transform ease-in-out 0.3s;
    &:hover {
        transform: scale(1.1);
        cursor: pointer;
    }
`;

export const Image = styled.img`
    width: 425px;
    height: 320px;
    border-radius: 8px;
    @media (max-width: 1024px) {
        width: 450px;
    }

    @media (max-width: 768px) {
        width: 300px;
    }
`;

export const ContainerText = styled.div`
    order: 2;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 1em 3em;
`;

export const Subtitle = styled.h2`
    font-size: 1.5rem;
    margin: 0;

    ${mobileMediaQuery} {
        font-size: 1.125rem;
    }
`;

export const Text = styled.p`
    font-size: 1.25rem;
    margin: 0;

    ${mobileMediaQuery} {
        font-size: 0.875rem;
    }
`;

export const ContainerFlexReversed = styled.div`
    display: flex;
    gap: 3em;
    flex-direction: row-reverse;
    margin-bottom: 3em;
`;

export const ContentButton = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    margin-top: 1em;
`;

export const ButtonBlue = styled.button`
    background-color: ${(props) => props.theme.primaryColorLight};
    border-radius: 12px;
    color: ${(props) => props.theme.white};
    font-size: 1rem;
    text-align: center;
    border: none;
    font-weight: 500;
    padding: 0.5em;
    &[disabled] {
        background-color: ${(props) => props.theme.grey};
    }
`;

export const ButtonMore = styled.button`
    background-color: transparent;
    color: ${(props) => props.theme.primaryColorLight};
    text-align: center;
    border: none;
    text-decoration: underline;
    font-weight: 500;
    font-size: 1rem;
`;

import { BsFillPersonFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import * as S from "./style";

type TypeDropdownData = {
    title: string;
    url: string;
};

type typeDropdown = {
    handleFunction: any;
    dataDropdown: Array<TypeDropdownData>;
};
export default function Dropdown({
    handleFunction,
    dataDropdown,
}: typeDropdown) {
    const router = useNavigate();

    return (
        <S.WrapperDropdown>
            <S.HeaderDropdown>
                <S.ButtonUser>
                    <BsFillPersonFill size={20} color="#fff" />
                </S.ButtonUser>
                <div>
                    <h4>Nome do usuário</h4>
                    <span>Configure suas informações</span>
                </div>
            </S.HeaderDropdown>
            <S.ListDropdown>
                <ul>
                    {dataDropdown.map(
                        (item: TypeDropdownData, index: number) => (
                            <li key={index} onClick={() => router(item.url)}>
                                {item.title}
                            </li>
                        )
                    )}
                    <li onClick={handleFunction}>Sair</li>
                </ul>
            </S.ListDropdown>
        </S.WrapperDropdown>
    );
}

import { Button } from "components";
import { ChatContent } from "contexts/Chat.context";
import { useGetChat } from "hooks";
import { useContext, useEffect } from "react";
import { BsFillPersonFill, BsPlusLg } from "react-icons/bs";
import * as S from "./style";

export default function SidebarChat() {
    const { listChat, refetchListChat, isLoadingListChat } = useGetChat();
    const { setRecipient } = useContext(ChatContent);
    const getUser = localStorage.getItem("getUser");
    const JsonGetUser = JSON.parse(getUser!);
    const GetAdmin = localStorage.getItem("user");
    const JsonGetAdmin = JSON.parse(GetAdmin!);

    useEffect(() => {
        refetchListChat();
    }, []);

    function handleNewChat() {
        setRecipient({
            senderId: ["650c5a5ab3a74319bb15328c"],
            senderName: "Administrador",
            recipientName: JsonGetUser.name,
            recipientId: [JsonGetUser.id],
        });
    }

    return (
        <S.Aside>
            <S.NewChat>
                {!isLoadingListChat &&
                    JsonGetAdmin == 0 &&
                    listChat?.response?.length == 0 && (
                        <Button.Small onClick={handleNewChat}>
                            <BsPlusLg size={17} />
                            Novo Chat
                        </Button.Small>
                    )}
            </S.NewChat>
            {!isLoadingListChat &&
                listChat?.response?.length > 0 &&
                listChat?.response?.map((item: any, index: number) => (
                    <S.Wrapper key={index} onClick={() => setRecipient(item)}>
                        <S.ContentImg>
                            <BsFillPersonFill />
                        </S.ContentImg>
                        <S.ContentSpan>
                            <span>{item.senderName}</span>
                            <span>
                                {item.message.length <= 27
                                    ? item.message
                                    : `${item.message.slice(0, 28)}...`}
                            </span>
                        </S.ContentSpan>
                    </S.Wrapper>
                ))}
        </S.Aside>
    );
}

import { v4 as uuidv4 } from "uuid";

type typeBanner = {
    id: string;
    title: string;
    url: string;
    video?: boolean;
};
export const DataBanner: Array<typeBanner> = [
    {
        id: uuidv4(),
        title: "Imagem realista área externa",
        url: "https://arktools.s3.sa-east-1.amazonaws.com/home/paisagismo.jpg",
    },
    {
        id: uuidv4(),
        title: "Imagem realista área interna",
        url: "https://arktools.s3.sa-east-1.amazonaws.com/home/marsi1_View01-giga.jpg",
    },
    {
        id: uuidv4(),
        title: "Imagem realista área interna",
        url: "https://arktools.s3.sa-east-1.amazonaws.com/home/marsiU01-giga.jpg",
    },
    {
        id: uuidv4(),
        title: "Imagem realista área interna com visão externa",
        url: "https://arktools.s3.sa-east-1.amazonaws.com/home/JOGOS001.jpg",
    },
    {
        id: uuidv4(),
        title: "Imagem realista área externa",
        url: "https://arktools.s3.sa-east-1.amazonaws.com/home/4-gigapixel-standard-scale-2_00x.jpg",
    },
    {
        id: uuidv4(),
        title: "Imagem realista área interna",
        url: "https://arktools.s3.sa-east-1.amazonaws.com/home/04.jpg",
    },

    {
        id: uuidv4(),
        title: "Imagem realista área interna",
        url: "https://arktools.s3.sa-east-1.amazonaws.com/home/WC001.jpg",
    },
    {
        id: uuidv4(),
        title: "Imagem realista interna, quarto infantil",
        url: "https://arktools.s3.sa-east-1.amazonaws.com/home/003.jpg",
    },
];

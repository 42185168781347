import styled from "styled-components";
import { tabletMediaQuery } from "styles/themes/mediaQueries";

export const Subtitle = styled.span`
    font-size: 1.5rem;
    font-weight: bold;
    color: ${(props) => props.theme.black};
    ${tabletMediaQuery} {
        text-align: justify;
        font-size: 1rem;
        font-weight: 500;
    }
`;

export const Wrapper = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-top: 1em;
    margin-bottom: 3em;
    gap: 2em;
`;

export const Background = styled.div`
    background: #ebf5fc;
    margin-bottom: 2em;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
`;

export const ContentService = styled.div`
    background-color: ${(props) => props.theme.white};
    border-radius: 13.5px;
    width: 232px;
    height: 470px;
    padding: 0.5em;
    display: flex;
    flex-direction: column;
    gap: 1em;
`;
export const ServiceImage = styled.div`
    border-radius: 6px;
    img {
        width: 218px;
        height: 180px;
        border-radius: 8px;
    }
`;
export const ContainerService = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 2em 0;
    gap: 3em;

    @media (max-width: 1200px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
`;
export const ServiceInfo = styled.div`
    text-align: start;

    color: #0a0a0a;
    h3 {
        font-weight: 700;
        font-size: 1.1rem;
    }
    span {
        font-size: 0.9rem;
    }
`;

export const PaddingService = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: 1em;
    padding: 0 1em;
`;

export const ContentButton = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ButtonBlue = styled.button`
    background-color: ${(props) => props.theme.primaryColorLight};
    border-radius: 12px;
    color: ${(props) => props.theme.white};
    font-size: 0.8rem;
    text-align: center;
    border: none;
    width: 93px;
    font-weight: 500;
    &[disabled] {
        background-color: ${(props) => props.theme.grey};
    }
`;

export const ButtonMore = styled.button`
    background-color: transparent;
    color: ${(props) => props.theme.primaryColorLight};
    font-size: 0.8rem;
    text-align: center;
    border: none;
    text-decoration: underline;
    font-weight: 500;
`;

export const WrapperService = styled.div`
    display: flex;
    justify-content: center;
`;

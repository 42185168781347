import { api } from "api/api";
import { useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

export function useCarrinho() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const postCarrinho = async (carrinhoData: any) => {
        try {
            setIsLoading(true);
            const response = await api("carrinho", {
                method: "POST",

                data: JSON.stringify({
                    upload: carrinhoData.upload,
                    service: carrinhoData.service,
                    user: carrinhoData.user,
                    authorization: carrinhoData.authorization,
                }),
            });

            return response;
        } catch (error) {
            toast.error("Ops ocorreu um erro!");
            return error;
        } finally {
            setIsLoading(false);
        }
    };

    return { postCarrinho, isLoading };
}

export function usePutCarrinho() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const putCarrinho = async (carrinhoData: any) => {
        try {
            setIsLoading(true);
            const response = await api("carrinho", {
                method: "PUT",

                data: JSON.stringify({
                    id: carrinhoData.id,
                    count: carrinhoData.count,
                }),
            });

            return response;
        } catch (error) {
            toast.error("Ops ocorreu um erro!");
            return error;
        } finally {
            setIsLoading(false);
        }
    };

    return { putCarrinho, isLoading };
}

export function useDeleteItemCarrinho(){
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const deleteItemCarrinho = async (id: string) => {
        try {
            setIsLoading(true);
            const response = await api(`carrinho/${id}`, {
                method: "DELETE",
            });
            toast.success("Item removido com sucesso!");
            return response;
        } catch (error) {
            toast.error("Ops ocorreu um erro!");
            return error;
        } finally {
            setIsLoading(false);
        }
    };

    return { deleteItemCarrinho, isLoading };
}
export function useDeleteCarrinho() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const deleteCarrinho = async (idUser: string) => {
        try {
            setIsLoading(true);
            const response = await api(`carrinho/delete/${idUser}`, {
                method: "DELETE",
            });
            toast.success("Compra finalizada com sucesso!");
            return response;
        } catch (error) {
            toast.error("Ops ocorreu um erro!");
            return error;
        } finally {
            setIsLoading(false);
        }
    };

    return { deleteCarrinho, isLoading };
}

export function useGetCarrinho() {
    const [enable] = useState<boolean>(false);
    const {
        data: listCarrinho,
        refetch: refetchListCarrinho,
        isFetching: isLoadingListCarrinho,
    } = useQuery(["listCarrinho"], () => api("carrinho"), {
        enabled: enable,
    });
    return {
        listCarrinho,
        refetchListCarrinho,
        isLoadingListCarrinho,
    };
}

import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as S from "./style";
import { Input, Button, Loading, HelperText } from "components";
import { RegisterSchema } from "schemas";
import { DataProps } from "types";
import { useUsers } from "hooks";
import Logo from "assets/icons/LogoArktoolsBlue.svg";

export default function Register() {
    const { postUser, isloading } = useUsers();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<DataProps<typeof RegisterSchema>>({
        mode: "onBlur",
        resolver: zodResolver(RegisterSchema),
    });

    const router = useNavigate();

    const submitForm = async (data: any) => {
        const response = await postUser(data);
        if (response.code) return;
        router("/validateAccount");
    };
    return (
        <S.Wrapper>
            <S.Container>
                <S.Logo>
                    <img src={Logo} alt={"Logo arktools"} />
                </S.Logo>
                <S.ContentTitle>
                    <S.Title>Seja bem-vindo!</S.Title>
                    <S.Span>
                        Crie sua conta, envie seus projetos e os receba em alta
                        qualidade.
                    </S.Span>
                </S.ContentTitle>
                <S.Form onSubmit={handleSubmit(submitForm)}>
                    <S.Padding>
                        <Input.Text
                            {...register("name")}
                            type="text"
                            name="name"
                            placeholder="Seu nome"
                            autoComplete="name"
                            helperText={errors?.name?.message}
                        />
                        <Input.Text
                            {...register("document")}
                            type={"text"}
                            name="document"
                            placeholder="Seu CPF"
                            helperText={errors?.document?.message}
                        />
                        <Input.Text
                            {...register("phone")}
                            type={"text"}
                            name="phone"
                            placeholder="Seu telefone"
                            helperText={errors?.phone?.message}
                        />
                        <Input.Text
                            {...register("email")}
                            type="text"
                            name="email"
                            placeholder="E-mail"
                            autoComplete="email"
                            helperText={errors?.email?.message}
                        />
                        <Input.Text
                            {...register("password")}
                            type={"password"}
                            name="password"
                            placeholder="Senha"
                            autoComplete="current-password"
                            helperText={errors?.password?.message}
                        />
                    </S.Padding>
                    <S.Button>
                        <Button.Master
                            disabled={isloading ? true : false}
                            type="submit"
                        >
                            {isloading ? <Loading size={8} /> : "Cadastrar"}
                        </Button.Master>
                    </S.Button>
                    <S.RegisterPolitica>
                        <S.Politica>
                            <input {...register("politica")} type="checkbox" />
                            <S.TitlePolitica>
                                Ao criar sua conta, você aceita nossos{" "}
                                <strong onClick={() => router("/politica")}>
                                    Termos e condições
                                </strong>{" "}
                                e nossa{" "}
                                <strong onClick={() => router("/politica")}>
                                    Política de privacidade
                                </strong>
                                .
                            </S.TitlePolitica>
                        </S.Politica>
                        {errors?.politica?.message && (
                            <HelperText
                                helperText={errors?.politica?.message}
                            />
                        )}
                    </S.RegisterPolitica>
                    <S.RegisterFooter>
                        <span>Já possui uma conta?</span>
                        <strong onClick={() => router("/login")}>
                            Iniciar sessão
                        </strong>
                    </S.RegisterFooter>
                </S.Form>
            </S.Container>
        </S.Wrapper>
    );
}

export default () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 15.5C9.45 15.5 9 15.05 9 14.5V9.5C9 8.95 9.45 8.5 10 8.5C10.55 8.5 11 8.95 11 9.5V14.5C11 15.05 10.55 15.5 10 15.5ZM11 7H9V5H11V7Z"
            fill="white"
        />
    </svg>
);

export default () => (
    <svg
        width="65"
        height="63"
        viewBox="0 0 65 63"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M38.5348 10H30.3499V34.6428H53.9212V28.1747"
            stroke="white"
            stroke-width="4.2857"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M37.8588 19.6458L42.7481 24.4045L55.0001 12.1426"
            stroke="white"
            stroke-width="4.2857"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M38.9338 52.8564C32.1519 52.8564 27.7423 52.8564 25.7049 52.8564C23.8811 52.8564 21.6956 52.2479 20.2294 50.2987C19.2388 48.9815 18.5767 47.0523 18.5767 44.285C18.5767 39.71 18.5767 35.7813 18.5767 32.499"
            stroke="white"
            stroke-width="4.2857"
            stroke-linecap="round"
        />
        <path
            d="M10 41.0708L18.5763 32.499L27.1576 41.0708"
            stroke="white"
            stroke-width="4.2857"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

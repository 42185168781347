import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Input, Loading } from "components";
import { usePutFile } from "hooks";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { EditDocumentSchema } from "schemas";
import { DataProps } from "types";
import * as S from "./style";
export default function EditDocument() {
    const { id } = useParams<string>();
    const { putFile, isloading } = usePutFile();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<DataProps<typeof EditDocumentSchema>>({
        mode: "onBlur",
        resolver: zodResolver(EditDocumentSchema),
    });

    const submitForm = async (data: any) => {
        const response = await putFile(data, id);
        if (response?.code) return;
        reset();
    };

    return (
        <S.Panel>
            <h1>Edit Document</h1>
            <S.Form onSubmit={handleSubmit(submitForm)}>
                <S.Content>
                    <Input.Text
                        {...register("linkdownload")}
                        type="text"
                        name="linkdownload"
                        placeholder="Download link"
                        autoComplete="linkdownload"
                        label="Link:"
                        helperText={errors?.linkdownload?.message}
                    />
                    <Input.Select
                        {...register("status")}
                        name="status"
                        label="Status:"
                        helperText={errors?.status?.message}
                    />

                    <Button.Master
                        disabled={isloading ? true : false}
                        type="submit"
                    >
                        {isloading ? <Loading size={8} /> : "Salvar"}
                    </Button.Master>
                </S.Content>
            </S.Form>
        </S.Panel>
    );
}

import { Table, Container, Title } from "components";
import * as S from "./style";
import { useGetCarrinho, useGetFile } from "hooks";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Orders() {
    const route = useNavigate();
    const { listFile, refetchListFile, isLoadingListFile } = useGetFile();
    const { refetchListCarrinho } = useGetCarrinho();
    useEffect(() => {
        refetchListFile();
        refetchListCarrinho();
    }, []);

    return (
        <Container>
            <S.Wrapper>
                {!isLoadingListFile &&
                    (listFile?.upload?.length > 0 ? (
                        <>
                            <Title title="Acompanhamento de projetos" />
                            <Table
                                data={listFile}
                                loading={isLoadingListFile}
                            />
                        </>
                    ) : (
                        <S.NewService>
                            <S.ContentNewService>
                                <S.h3>
                                    Você não possui nenhum serviço contratado
                                </S.h3>
                                <span>
                                    Não há nenhum serviço contratado no momento,
                                    acesse nossos serviços para sua primeira
                                    contratação.
                                </span>
                            </S.ContentNewService>
                            <S.ButtonBlue>
                                <button
                                    onClick={() =>
                                        route("/service-contracting")
                                    }
                                >
                                    Contratar novo serviço
                                </button>
                            </S.ButtonBlue>
                        </S.NewService>
                    ))}
            </S.Wrapper>
        </Container>
    );
}

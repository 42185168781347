export default () => (
    <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.50941 7.32205C6.10711 7.32205 5.71385 7.20275 5.37934 6.97925C5.04484 6.75574 4.78413 6.43806 4.63018 6.06638C4.47622 5.69471 4.43594 5.28572 4.51443 4.89115C4.59291 4.49658 4.78664 4.13414 5.07111 3.84967C5.35558 3.5652 5.71802 3.37148 6.11259 3.29299C6.50716 3.21451 6.91614 3.25479 7.28782 3.40874C7.6595 3.5627 7.97718 3.82341 8.20068 4.15791C8.42419 4.49241 8.54348 4.88568 8.54348 5.28798C8.54284 5.82725 8.32833 6.34425 7.94701 6.72557C7.56568 7.10689 7.04868 7.3214 6.50941 7.32205ZM6.50941 4.06754C6.26803 4.06754 6.03207 4.13911 5.83137 4.27322C5.63067 4.40732 5.47424 4.59793 5.38187 4.82094C5.2895 5.04394 5.26533 5.28933 5.31242 5.52607C5.35951 5.76282 5.47575 5.98028 5.64643 6.15096C5.81711 6.32164 6.03457 6.43788 6.27132 6.48497C6.50806 6.53206 6.75345 6.50789 6.97646 6.41552C7.19946 6.32315 7.39007 6.16672 7.52417 5.96602C7.65828 5.76532 7.72986 5.52936 7.72986 5.28798C7.72953 4.9644 7.60085 4.65416 7.37204 4.42535C7.14323 4.19654 6.833 4.06786 6.50941 4.06754Z"
            fill="black"
        />
        <path
            d="M6.50914 12.2033L3.07726 8.1559C3.02957 8.09513 2.98238 8.03397 2.93568 7.97243C2.34946 7.20021 2.03276 6.25699 2.03418 5.28746C2.03418 4.10062 2.50565 2.9624 3.34487 2.12318C4.18409 1.28397 5.32231 0.8125 6.50914 0.8125C7.69597 0.8125 8.8342 1.28397 9.67341 2.12318C10.5126 2.9624 10.9841 4.10062 10.9841 5.28746C10.9855 6.25655 10.669 7.19934 10.083 7.97121L10.0826 7.97243C10.0826 7.97243 9.96055 8.13272 9.94225 8.15428L6.50914 12.2033ZM3.58537 7.48222C3.58537 7.48222 3.68015 7.60752 3.70172 7.63437L6.50914 10.9454L9.32023 7.62989C9.33813 7.60752 9.43332 7.48141 9.43373 7.481C9.91261 6.85008 10.1714 6.07954 10.1705 5.28746C10.1705 4.31641 9.78472 3.38514 9.09809 2.69851C8.41146 2.01187 7.48019 1.62613 6.50914 1.62613C5.5381 1.62613 4.60682 2.01187 3.92019 2.69851C3.23356 3.38514 2.84781 4.31641 2.84781 5.28746C2.84696 6.08003 3.10605 6.85101 3.58537 7.48222Z"
            fill="black"
        />
    </svg>
);

import * as S from "./style";
import Logo from "assets/icons/LogoArktoolsWhite.svg";
import LogoFast3d from "assets/icons/LogoFast3D.svg";
import { useNavigate } from "react-router-dom";
import { Phone, SmallEmail, Location } from "assets/icons";
import { FooterType } from "types";
import { externalLinks } from "./footer";

export default function Footer() {
    const router = useNavigate();

    const handleRoute = (route: string) => {
        router(`/${route}`);
    };

    return (
        <footer>
            <S.ContainerContext>
                <S.ContainerLogo>
                    <S.LogoArktools src={Logo} alt="" />
                </S.ContainerLogo>
                <S.List>
                    <S.ListItens onClick={() => handleRoute("servicesPage")}>
                        Serviços
                    </S.ListItens>
                    <S.ListItens onClick={() => handleRoute("projects")}>
                        Projetos
                    </S.ListItens>
                    <S.ListItens>Blog</S.ListItens>
                    <S.ListItens>Contato</S.ListItens>
                </S.List>
                <S.ContainerIcons>
                    {externalLinks.map((link: FooterType) => (
                        <a
                            key={link.id}
                            href={link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <S.Icon src={link.icon} alt="" />
                        </a>
                    ))}
                </S.ContainerIcons>
            </S.ContainerContext>
            <div>
                <S.LightFooter>
                    <S.FlexInformation>
                        <S.FlexIconSpan>
                            <SmallEmail />
                            <span>arktools@fastprobr.com</span>
                        </S.FlexIconSpan>
                        <S.FlexIconSpan>
                            <Phone />
                            <span>(11) 3195-5736</span>
                        </S.FlexIconSpan>
                    </S.FlexInformation>
                    <S.FlexInformation>
                        <S.FlexIconSpan>
                            <Location />
                            <span>
                                Unidade Campinas<br></br>
                            </span>
                        </S.FlexIconSpan>
                        <S.Span>
                            <S.Span>
                                Av. Dr. Romeu Tortima n° 194<br></br>
                            </S.Span>
                            <S.Span>
                                Jd. Santa Genebra II, Campinas/SP Brasil
                            </S.Span>
                        </S.Span>
                    </S.FlexInformation>
                    <div>
                        <a
                            href="https://www.fast3dlab.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <S.LogoFast3d src={LogoFast3d} alt="" />
                        </a>
                    </div>
                </S.LightFooter>
            </div>
        </footer>
    );
}

import styled from "styled-components";
import { mobileMediaQuery } from "styles/themes/mediaQueries";

type CircleType = {
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
};

export const Circle = styled.div<CircleType>`
    position: absolute;
    background-color: ${(props) => props.theme.secondaryColorDark};
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    padding: 6px 0px;
    border: 5px solid #f2f2f2;
    z-index: 1;
    top: ${(props) => props.top && props.top};
    bottom: ${(props) => props.bottom && props.bottom};
    left: ${(props) => props.left && props.left};
    right: ${(props) => props.right && props.right};
    ${mobileMediaQuery} {
        display: none;
    }
`;

export const Number = styled.span`
    color: ${(props) => props.theme.white};
    font-weight: bolder;
    font-size: 25px;
`;

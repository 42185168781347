import { RetangleBlue } from "components/RetangleBlue";
import * as S from "./style";

export default function NotFound() {
    return (
        <S.Wrapper>
            <RetangleBlue.Left />
            <RetangleBlue.Rigth />
        </S.Wrapper>
    );
}

import { api } from "api/api";
import { useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

export function useFile() {
    const [isloading, setIsloading] = useState<boolean>(false);

    const postFile = async (form: any) => {
        try {
            setIsloading(true);

            const response = await api("upload", {
                method: "POST",
                data: form,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            toast.success(response?.message);
            return response;
        } catch (error) {
            toast.error("An error occurred while uploading the file!");
            return error;
        } finally {
            setIsloading(false);
        }
    };

    return { postFile, isloading };
}

export function useGetFile() {
    const [enable] = useState<boolean>(false);
    const {
        data: listFile,
        refetch: refetchListFile,
        isFetching: isLoadingListFile,
    } = useQuery(["listFile"], () => api("upload"), {
        enabled: enable,
    });
    return {
        listFile,
        refetchListFile,
        isLoadingListFile,
    };
}

export function usePutFile() {
    const [isloading, setIsloading] = useState<boolean>(false);
    const putFile = async (form: any, id: string | undefined) => {
        try {
            setIsloading(true);

            const response = await api(`upload/${id}`, {
                method: "PUT",
                data: form,
            });

            toast.success(response?.message);
            return response;
        } catch (error) {
            toast.error("An error occurred while updating the status!");
            return error;
        } finally {
            setIsloading(false);
        }
    };

    return { putFile, isloading };
}

import styled from "styled-components";
import { tabletMediaQuery } from "styles/themes/mediaQueries";

export const Panel = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 9em;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 5em;
`;

export const Content = styled.div`
    width: 40%;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    padding: 20px;
    box-shadow: 0px 1px 3px 1px #b9b9b9;
    ${tabletMediaQuery} {
        width: 70%;
    }
`;

import Logo from "assets/icons/LogoArktoolsBlue.svg";
import * as S from "./style";

export default function ValidateAccount() {
    return (
        <S.Wrapper>
            <S.Container>
                <S.Logo>
                    <img src={Logo} alt={"Logo arktools"} />
                </S.Logo>
                <S.ContentTitle>
                    <S.Title>Valide sua conta!</S.Title>
                    <S.ContentSpan>
                        <S.Span>
                            Por favor, verifique sua caixa de entrada ou o spam.
                            Enviamos um e-mail para ativação de sua conta e isso
                            pode levar alguns minutos.
                        </S.Span>
                    </S.ContentSpan>
                    {/* <S.Span>
                        Caso não tenha recebido, <strong>clique aqui</strong>{" "}
                        para gerar novo e-mail de validação.
                    </S.Span> */}
                </S.ContentTitle>
            </S.Container>
        </S.Wrapper>
    );
}

import styled from "styled-components";
import {
    desktopMediaQuery,
    mobileMediaQuery,
} from "styles/themes/mediaQueries";

type TypeBackground = {
    backgroudColor?: boolean;
};

export const Wrapper = styled.div`
    margin-top: 9em;
    text-align: center;
    margin-bottom: 3em;
    gap: 1em;
    ${mobileMediaQuery} {
        display: flex;
        flex-direction: column-reverse;
    }
    ${desktopMediaQuery} {
        display: grid;
        grid-template-columns: 1.5fr 1fr;
    }
`;

export const WrapperPayment = styled.div`
    /* border: 0.1px solid #616161; */
    box-shadow: 0px 3px 8px 0px #0000001a;

    border-radius: 8px;
    padding: 2em;
    gap: 1em;
    ${mobileMediaQuery} {
        display: flex;
        flex-direction: column;
    }
    ${desktopMediaQuery} {
        display: grid;
        grid-template-columns: 0.3fr 1fr;
    }
`;

export const ContentTypesPayment = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    align-items: start;
`;

export const TypePayment = styled.div<TypeBackground>` // afeta Pagamento do lado direito
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    align-items: center;
    width: 100%;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    padding: 8px;

    background-color: ${(props) =>
        props.backgroudColor ? "#d2e7f4" : "white"};
    span {
        font-weight: 500;
        color: #000;
    }
    &:hover {
        cursor: pointer;
    }
`;

export const ContentAside = styled.aside` // afeta Pagamento do lado direito
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 1em;
    height: 50em;
    width: 10em;
    h4 {
        font-size: 1.25rem;
        font-weight: 700;
    }
`;

export const ContainerPayment = styled.div`
    border: 2px solid #d2e7f4;
    border-radius: 12px;

`;

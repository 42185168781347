import { createContext, useEffect, useState } from "react";
import { ChatType, ChildrenType } from "types";
import io from "socket.io-client";

export const ChatContent = createContext({} as ChatType);

export default function ChatProvider({ children }: ChildrenType) {
    const [recipient, setRecipient] = useState<string>("");
    const [message, setMessage] = useState<string>("");
    const [socket, setSocket] = useState<any>(null);

    useEffect(() => {
        const newSocket = io(process.env.REACT_APP_API_URL!, {
            reconnection: true, // Ativar a reconexão automática
            reconnectionAttempts: 3, // Número máximo de tentativas de reconexão
            reconnectionDelay: 1000, // Tempo de espera entre tentativas (em milissegundos)
        });

        newSocket.on("connect_error", (error) => {
            console.error("Erro de conexão:", error);
        });

        setSocket(newSocket);

        return () => {
            // Desconectar o socket quando o componente é desmontado
            newSocket.disconnect();
        };
    }, []);

    return (
        <ChatContent.Provider
            value={{
                recipient,
                setRecipient,
                message,
                setMessage,
                socket,
            }}
        >
            {children}
        </ChatContent.Provider>
    );
}

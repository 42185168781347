import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";

export const StyledCarousel = styled(Carousel)`
    /* max-width: 60%;
    margin-left: 1em; */
    .carousel .slider-wrapper {
        /* overflow-x: auto !important; */
    }
    .carousel .slider .selected {
        width: 100%;
    }

    .carousel .slider .previous {
        width: 0;
    }
`;

export const CarouselSlide = styled.div`
    display: flex;
    margin-top: 3em;
    border-radius: 8px;
    justify-content: center;
    padding: 0 3em;
    gap: 2em;

    @media (max-width: 1200px) {
        flex-wrap: wrap;
    }
`;
export const CustomPrevArrow = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: #00000033;
        padding: 5px;
        z-index: 1;
        margin-top: 3em;
        &:hover {
            cursor: pointer;
        }
    }
    svg {
        color: #fff;
        height: 20px;
        width: 20px;
    }
`;
export const CustomNextArrow = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    margin-top: 3em;
    div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: #00000033;
        padding: 5px;
        z-index: 1;
        &:hover {
            cursor: pointer;
        }
    }
    svg {
        color: #fff;
        height: 20px;
        width: 20px;
    }
`;
export const ContentCarousel = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.8em;
    text-align: start;
    position: relative;

    span {
        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    span {
        font-size: 1.2rem;
        font-weight: 700;
    }
`;
export const CarouselContent = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
`;

export const Image = styled.div`
    transition: transform ease-in-out 0.3s;
    img {
        object-fit: cover;
        border-radius: 8px;
        @media (min-width: 1024px) {
            width: 380px !important;
        }
        @media (min-width: 1900px) {
            width: 530px !important;
        }
    }
    &:hover {
        cursor: pointer;
        transform: scale(1.1);
    }
`;

export const ContainerButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    width: fit-content;
    margin: auto;
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
`;

import styled from "styled-components";
import { mobileMediaQuery } from "styles/themes/mediaQueries";

export const Title = styled.h2`
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    ${mobileMediaQuery} {
        font-size: 1rem;
    }
`;
export const Form = styled.form`
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    align-items: center;
`;
export const ContentButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    button {
        height: 30px;
        padding: 10px 15px;
        width: 84px;
    }
`;

export const Wrapper = styled.div`
    margin-top: 9em;
    margin-bottom: 3em;
    display: flex;
    flex-direction: column;
    gap: 2.5em;
    align-items: center;
    ${mobileMediaQuery} {
        margin-top: 7em;
        gap: 1em;
    }
`;

import { Container } from "components";
import * as S from "./style";
export default function Politica() {
    return (
        <Container>
            <S.Container>
                <S.PoliticaTitle>
                    POLÍTICA DE PRIVACIDADE E COOKIES
                </S.PoliticaTitle>
                <S.Content>
                    <S.SubTitle>
                        Esta política de Privacidade descreve como o nosso site
                        coleta, utiliza e protege as informações pessoais dos
                        Usuários. Ao utilizar este site, você concorda com os
                        termos desta política
                    </S.SubTitle>
                </S.Content>
                <S.Content>
                    <S.Title>Quem somos?</S.Title>
                    <S.ContentSubTitle>
                        Este website foi desenvolvido pela FASTPROBR SERVIÇOS DE
                        INFORMÁTICA LTDA, Empresa brasileira, com sede na cidade
                        de Campinas/SP, na Av. Doutor Romeu Tortima, nº 194, CEP
                        13.084-791, inscrita no CNPJ sob o nº
                        30.726.774/0001-65.
                    </S.ContentSubTitle>
                </S.Content>
                <S.Content>
                    <S.Title>Como nos contatar? </S.Title>
                    <S.ContentSubTitle>
                        Se você quiser falar com a FastProBR sobre a plataforma
                        Arktools, escreva para o canal de atendimento da
                        Arktools no endereço de e-mail: arktools@fastprobr.com.
                        Se você deseja nos contatar sobre o tratamento de seus
                        dados, escreva para o nosso DPO (Encarregado) no
                        endereço de e-mail a seguir: dpo@fastprobr.com.
                    </S.ContentSubTitle>
                </S.Content>
                <S.Content>
                    <S.Title>Por que pedimos seus dados?</S.Title>
                    <S.ContentSubTitle>
                        Para a criação e validação do seu cadastro na plataforma
                        e para comunicações em geral dos serviços oferecidos
                        pela plataforma Arktools
                    </S.ContentSubTitle>
                </S.Content>
                <S.Content>
                    <S.Title>
                        Como os dados serão Armazenados e Compartilhados? E por
                        quanto tempo
                    </S.Title>
                    <S.ContentSubTitle>
                        O nome, CPF, endereço de e-mail e número de telefone
                        fornecidos pelos Usuários são armazenados em um banco de
                        dados (SQL) da MongoDB. Esses dados podem ser
                        compartilhados entre a FastProBR e XXXXX para XXXX. Seus
                        dados serão tratados com confidencialidade e segurança
                        por xxxxx.
                    </S.ContentSubTitle>
                </S.Content>
                <S.Content>
                    <S.Title>
                        Quais as responsabilidades destes agentes que realizarão
                        o tratamento dos dados?
                    </S.Title>
                    <S.ContentSubTitle>
                        Nos termos da Lei, e seguindo as devidas práticas de
                        segurança da informação, as empresas que, de alguma
                        forma, tratarem dados pessoais buscarão a constante
                        observância das normas e aplicação de técnicas de
                        segurança. Em caso de falha comprovada, as empresas
                        poderão ser responsabilizadas nos termos da lei.
                    </S.ContentSubTitle>
                </S.Content>
                <S.Content>
                    <S.Title>São utilizados cookies?</S.Title>
                    <S.ContentSubTitle>
                        Coleta de Informações 1. Cookies: Este site utiliza
                        cookies para melhorar a experiência do Usuário e medir o
                        tráfego por meio de ferramentas de análise. Os cookies
                        são pequenos arquivos de texto armazenados no seu
                        dispositivo que nos permitem entender como você utiliza
                        o site. 2. XXXXXX: 3. XXXXXX
                    </S.ContentSubTitle>
                </S.Content>
                <S.Content>
                    <S.Title>
                        Seus Direitos, conforme contidos no artigo 18 da LGPD
                        (Lei n. 13.709/18):
                    </S.Title>
                    <S.ContentSubTitle>
                        Você tem o direito de acessar, corrigir ou excluir suas
                        informações pessoais, dentre outros, a qualquer momento
                        ao entrar em contato com arktools@fastprobr.com e/ou
                        dpo@fastprobr.com. Entre em contato conosco se desejar
                        exercer esses direitos ou tiver alguma dúvida sobre esta
                        política.
                    </S.ContentSubTitle>
                </S.Content>
                <S.Content>
                    <S.Title>Por quanto tempo esta política é válida?</S.Title>
                    <S.ContentSubTitle>
                        Podemos atualizar esta política periodicamente para
                        refletir as práticas atuais de coleta e uso de
                        informações. Quaisquer alterações serão publicadas nesta
                        página, e recomendamos que você acesse periodicamente
                        para verificar as atualizações. Ao usar nosso site, você
                        concorda com os termos desta Política de Privacidade e
                        Cookies. Se não concordar com esses termos, por favor,
                        não utilize nosso site. Se tiver alguma dúvida ou
                        preocupação sobre esta política, entre em contato
                        conosco através dos dados de contato fornecidos no site.
                        Data da última atualização desta Política: 08/05/2024
                    </S.ContentSubTitle>
                </S.Content>
                <S.Content>
                    <S.Title>
                        VERSÃO COMPLETA DESTA POLÍTICA DE PRIVACIDADE E COOKIES
                    </S.Title>
                    <S.ContentSubTitle>
                        A Empresa FastProBR, mantendo o histórico de respeito à
                        transparência, privacidade e à proteção de dados
                        pessoais, vem, por meio deste documento, apresentar as
                        principais informações quanto à coleta e tratamento de
                        dados pessoais vinculadas a este Website
                        (https://www.arktools.io).
                    </S.ContentSubTitle>
                </S.Content>

                <S.Content>
                    <S.Title>PRINCIPAIS DEFINIÇÕES</S.Title>
                    <S.ContentSubTitle>
                        Os principais termos utilizados neste documento estão
                        especificados abaixo, sendo baseados no previsto na LGPD
                        e/ou no contexto de utilização deste Website:
                    </S.ContentSubTitle>
                </S.Content>

                <S.Content>
                    <S.Title>LGPD</S.Title>
                    <S.ContentSubTitle>
                        Lei Geral de Proteção de Dados Pessoais (Lei n.
                        13.709/18).
                    </S.ContentSubTitle>
                </S.Content>

                <S.Content>
                    <S.Title>WebSite (Site)</S.Title>
                    <S.ContentSubTitle>
                        Website Arktools, registrado e acessado através do
                        seguinte endereço virtual: https://www.arktools.io
                    </S.ContentSubTitle>
                </S.Content>

                <S.Content>
                    <S.Title>Empresa</S.Title>
                    <S.ContentSubTitle>
                        FASTPROBR SERVIÇOS DE INFORMÁTICA LTDA, empresa
                        brasileira, com sede na cidade de Campinas/SP, na Av.
                        Doutor Romeu Tortima, nº 194, CEP 13.084-791, inscrita
                        no CNPJ sob o nº 30.726.774/0001-65. Informações de
                        contato através dos endereços de e-mail
                        arktools@fastprobr.com / contact@fastprobr.com.
                    </S.ContentSubTitle>
                </S.Content>

                <S.Content>
                    <S.Title>Usuário</S.Title>
                    <S.ContentSubTitle>
                        Pessoa física ou jurídica que acessar e utilizar este
                        Website. Em se tratando de pessoa física (natural) será,
                        nos termos da LGPD, o titular de dados pessoais.
                    </S.ContentSubTitle>
                </S.Content>

                <S.Content>
                    <S.Title>Formulário</S.Title>
                    <S.ContentSubTitle>
                        Campos pré-definidos onde são preenchidos dados e
                        informações pelo USUÁRIO, a fim de permitir o registro e
                        o gerenciamento de dados.
                    </S.ContentSubTitle>
                </S.Content>

                <S.Content>
                    <S.Title>Dado pessoal</S.Title>
                    <S.ContentSubTitle>
                        Informação relacionada a pessoa natural identificada ou
                        identificável.
                    </S.ContentSubTitle>
                </S.Content>

                <S.Content>
                    <S.Title>Dado pessoal sensível</S.Title>
                    <S.ContentSubTitle>
                        Dado pessoal sobre origem racial ou étnica, convicção
                        religiosa, opinião política, filiação a sindicato ou a
                        organização de caráter religioso, filosófico ou
                        político, dado referente à saúde ou à vida sexual, dado
                        genético ou biométrico, quando vinculado a uma pessoa
                        natural.
                    </S.ContentSubTitle>
                </S.Content>

                <S.Content>
                    <S.Title>Banco de dados</S.Title>
                    <S.ContentSubTitle>
                        Conjunto estruturado de dados pessoais, estabelecido em
                        um ou em vários locais, em suporte eletrônico ou físico.
                    </S.ContentSubTitle>
                </S.Content>

                <S.Content>
                    <S.Title>Titular</S.Title>
                    <S.ContentSubTitle>
                        Pessoa natural a quem se referem os dados pessoais que
                        são objeto de tratamento.
                    </S.ContentSubTitle>
                </S.Content>

                <S.Content>
                    <S.Title>Encarregado (DPO)</S.Title>
                    <S.ContentSubTitle>
                        Pessoa indicada pelo controlador e operador para atuar
                        como canal de comunicação entre o controlador, os
                        titulares dos dados e a Autoridade Nacional de Proteção
                        de Dados (ANPD).
                    </S.ContentSubTitle>
                </S.Content>

                <S.Content>
                    <S.Title>Tratamento</S.Title>
                    <S.ContentSubTitle>
                        Toda operação realizada com dados pessoais, como as que
                        se referem a coleta, produção, recepção, classificação,
                        utilização, acesso, reprodução, transmissão,
                        distribuição, processamento, arquivamento,
                        armazenamento, eliminação, avaliação ou controle da
                        informação, modificação, comunicação, transferência,
                        difusão ou extração.
                    </S.ContentSubTitle>
                </S.Content>

                <S.Content>
                    <S.Title>Consentimento</S.Title>
                    <S.ContentSubTitle>
                        Manifestação livre, informada e inequívoca pela qual o
                        titular concorda com o tratamento de seus dados pessoais
                        para uma finalidade determinada.
                    </S.ContentSubTitle>
                </S.Content>
                <S.Content>
                    <S.Title>Eliminação</S.Title>
                    <S.ContentSubTitle>
                        Exclusão de dado ou de conjunto de dados armazenados em
                        banco de dados, independentemente do procedimento
                        empregado.
                    </S.ContentSubTitle>
                </S.Content>

                <S.Content>
                    <S.Title>Transferência internacional de dados</S.Title>
                    <S.ContentSubTitle>
                        Transferência de dados pessoais para país estrangeiro ou
                        organismo internacional do qual o país seja membro.
                    </S.ContentSubTitle>
                </S.Content>

                <S.Content>
                    <S.Title>Uso compartilhado de dados</S.Title>
                    <S.ContentSubTitle>
                        Comunicação, difusão, transferência internacional
                        (quando aplicável), interconexão de dados pessoais ou
                        tratamento compartilhado de bancos de dados pessoais por
                        órgãos e entidades públicas no cumprimento de suas
                        competências legais, ou entre esses e entes privados,
                        reciprocamente, com autorização específica, para uma ou
                        mais modalidades de tratamento permitidas por esses
                        entes públicos, ou entre entes privados.
                    </S.ContentSubTitle>
                </S.Content>

                <S.Content>
                    <S.Title>1 POLÍTICA DE PRIVACIDADE</S.Title>
                    <S.ContentSubTitle>
                        Este documento visa apresentar, nos termos da Lei Geral
                        de Proteção de Dados Pessoais brasileira - LGPD (Lei n.
                        13.709/2018), as principais informações envolvendo a
                        coleta e o tratamento de dados pessoais que podem
                        ocorrer em caso de acesso e utilização deste Website. A
                        utilização deste Website sujeita o USUÁRIO à observância
                        e respeito às condições abaixo expostas. Caso não
                        concorde com as informações aqui disponibilizadas,
                        destacamos que não será possível utilizar os serviços
                        ora oferecidos, bem como solicitamos que o USUÁRIO
                        interrompa, imediatamente, o acesso e uso a estes
                        conteúdos e/ou serviços.
                    </S.ContentSubTitle>
                </S.Content>

                <S.Content>
                    <S.Title>
                        1.1 Identificação e informações de contato de nossa
                        empresa
                    </S.Title>
                    <S.ContentSubTitle>
                        FASTPROBR SERVIÇOS DE INFORMÁTICA LTDA, empresa
                        brasileira, com sede na cidade de Campinas/SP, na Av.
                        Doutor Romeu Tortima, nº 194, CEP 13.084-791, inscrita
                        no CNPJ sob o nº 30.726.774/0001-65. Informações de
                        contato Arktools: arktools@fastprobr.com /
                        contact@fastprobr.com.
                    </S.ContentSubTitle>
                </S.Content>

                <S.Content>
                    <S.Title>
                        1.2 Identificação e forma de contato com o nosso
                        Encarregado (DPO)
                    </S.Title>
                    <S.ContentSubTitle>
                        Caso deseje se comunicar com nosso departamento de
                        privacidade, por favor envie um e-mail para o nosso
                        Encarregado pelo Tratamento de Dados Pessoais (DPO) no
                        endereço de e-mail: dpo@fastprobr.com.
                    </S.ContentSubTitle>
                </S.Content>

                <S.Content>
                    <S.Title>
                        1.3 Do fornecimento e da finalidade do tratamento dos
                        dados pessoais
                    </S.Title>
                    <S.ContentSubTitle>
                        Este Website possui links que podem coletar dados
                        pessoais. Assim, os dados pessoais coletados serão os
                        eventualmente fornecidos, de forma voluntária e
                        opcional, pelo USUÁRIO (Titular) nos formulários
                        disponibilizados e serão utilizados para os fins
                        destacados e/ou pretendidos por este. Os dados
                        fornecidos serão utilizados para o cadastro e validação
                        do titular dos dados pessoais. Os dados poderão ser
                        utilizados para comunicação geral dos serviços
                        oferecidos pela plataforma Arktools. Ademais, informa-se
                        que, no momento da aceitação desta Política de
                        Privacidade e Cookies (realizando um “clique na caixa”
                        sinalizada para tanto), serão obtidas informações
                        referentes a versão deste documento, data, hora e número
                        de IP (Internet Protocol), vinculados ao USUÁRIO, para
                        fins de registro do consentimento. Os dados fornecidos
                        por meio do formulário serão repassados somente a
                        terceiros listados no item “1.7 Informações sobre o
                        compartilhamento de dados pessoais com terceiros” deste
                        documento ou mediante autorização e/ou solicitação
                        expressa do USUÁRIO (mediante solicitação de
                        consentimento no momento do envio de tais dados).
                    </S.ContentSubTitle>
                </S.Content>
                <S.Content>
                    <S.Title>
                        1.4 Da coleta e finalidade do tratamento de dados
                        pessoais
                    </S.Title>
                    <S.ContentSubTitle>
                        Serão coletados nome, CPF, endereço de e-mail e número
                        de telefone do USUÁRIO para fins de cadastro e validação
                        do titular dos dados pessoais e para comunicação em
                        geral sobre serviços oferecidos pela plataforma
                        Arktools. Os dados pessoais fornecidos serão
                        compartilhados com terceiros, como descrito no item 1.7
                        desta política.
                    </S.ContentSubTitle>
                </S.Content>

                <S.Content>
                    <S.Title>
                        1.5 Da coleta e finalidade do tratamento de dados
                        pessoais – Menores de idade e/ou sensíveis
                    </S.Title>
                    <S.ContentSubTitle>
                        <b>Dados de menores de idade:</b> Visando atender ao
                        previsto no art. 14 da LGPD, informa-se que o envio de
                        dados pessoais de USUÁRIOS menores de 18 anos de idade
                        deve ser realizado com o consentimento específico de um
                        dos pais ou responsável legal. Assim, caso o USUÁRIO
                        menor de idade pretenda fazer uso do formulário
                        disponibilizado neste Website, deverá fazê-lo por meio
                        do envio de dados de um de seus pais ou responsável
                        legal.
                    </S.ContentSubTitle>
                    <S.ContentSubTitle>
                        <b>Dados sensíveis:</b> Visando atender ao previsto no
                        art. 11 e seguintes da LGPD, informa-se que não há
                        previsão de coleta e tratamento de dados pessoais
                        sensíveis no formulário disponibilizado neste Website.
                        Caso haja o envio voluntário de um ou mais dados
                        pessoais sensíveis, isso se dará sob opção do próprio
                        USUÁRIO e sob sua exclusiva responsabilidade. Lembra-se
                        que, nos termos da LGPD, Dado Pessoal Sensível é
                        considerado: "dado pessoal sobre origem racial ou
                        étnica, convicção religiosa, opinião política, filiação
                        a sindicato ou a organização de caráter religioso,
                        filosófico ou político, dado referente à saúde ou à vida
                        sexual, dado genético ou biométrico, quando vinculado a
                        uma pessoa natural.” A empresa não solicita, nem
                        recomenda, o envio de dados pessoais sensíveis por meio
                        do formulário disponibilizado neste site.
                    </S.ContentSubTitle>
                </S.Content>

                <S.Content>
                    <S.Title>
                        1.6 Da forma e duração do tratamento de dados pessoais
                    </S.Title>
                    <S.ContentSubTitle>
                        A Empresa irá tratar os dados pessoais de acordo com o
                        previsto na LGPD e detalhado nesta política. A duração
                        do armazenamento e tratamento dos dados aqui referidos
                        será mantida pelo tempo necessário até o cumprimento de
                        seu objetivo e/ou obrigação legal (nos termos das regras
                        de retenção de dados pessoais), e/ou enquanto não houver
                        revogação do consentimento dado por parte do USUÁRIO ou
                        quando assim for solicitado por meio de determinação
                        judicial ou extrajudicial, quando cabível, bem como
                        enquanto durar as operações e registros legais desta
                        empresa sempre respeitando-se as normas legais de guarda
                        de informação aplicáveis.
                    </S.ContentSubTitle>
                </S.Content>

                <S.Content>
                    <S.Title>
                        1.7 Informações sobre o compartilhamento de dados
                        pessoais com terceiros
                    </S.Title>
                    <S.ContentSubTitle>
                        Salienta-se que a empresa utiliza serviços de terceiros
                        para o gerenciamento de e-mails, do banco de dados, do
                        Website e de empresas parceiras vinculadas ao serviço
                        contratado, o qual atua como operador de dados pessoais,
                        e que podem tratar os dados pessoais dos USUÁRIOS. Os
                        principais serviços de terceiros utilizados, os quais
                        foram contratados considerando-se requisitos técnicos e
                        de segurança, com base em suas respectivas
                        documentações, são:
                    </S.ContentSubTitle>
                    <S.ContentSubTitle>
                        - MongoDB – Provedor de serviço em nuvem para guarda e
                        compartilhamento de dados - obtenha mais informações
                        sobre privacidade em{" "}
                        <a href="https://www.mongodb.com/legal/privacy/privacy-policy">
                            https://www.mongodb.com/legal/privacy/privacy-policy
                        </a>{" "}
                    </S.ContentSubTitle>
                    <S.ContentSubTitle>
                        A principal empresa na qual são compartilhados dados
                        pessoais dos USUÁRIOS, é:
                    </S.ContentSubTitle>
                    <S.ContentSubTitle>
                        - Xxxxx - xxxxxxxxxxxxxxx. Obtenha mais informações
                        sobre privacidade em xxxxxxxxxxx Outros profissionais
                        e/ou empresas vinculadas serão informados neste Website,
                        junto com a oferta do serviço, podendo ser verificado
                        antes do envio de dados e da contratação deste.
                    </S.ContentSubTitle>
                </S.Content>
                <S.Content>
                    <S.Title>
                        1.8 Da Transferência Internacional de Dados Pessoais
                    </S.Title>
                    <S.ContentSubTitle>
                        Considerando-se que a Empresa utiliza serviços de
                        empresas terceiras de origem internacional, bem como a
                        aplicação de boas práticas técnicas que visam preservar
                        cópias de segurança de dados em território físico
                        diverso ao da Empresa (fora do território brasileiro),
                        informa-se que cópias de segurança (backup de dados
                        pessoais) podem ocorrer em países diversos ao Brasil,
                        por meio dos sistemas contratados. Para mais informações
                        acesse: xxxxxxxxxxxx. Assim, ao concordar com os termos
                        aqui expostos, o USUÁRIO concorda com o envio e
                        tratamento de seus dados pessoais para sistemas /
                        empresas localizadas fora do território nacional.
                    </S.ContentSubTitle>
                </S.Content>

                <S.Content>
                    <S.Title>
                        1.9 Das responsabilidades das partes sobre o tratamento
                        de dados pessoais
                    </S.Title>
                    <S.ContentSubTitle>
                        A Empresa FastProBR, mantendo seu histórico compromisso
                        com a segurança das informações e privacidade, irá
                        buscar constantes adequações ao previsto na LGPD (Lei n.
                        13.709/18). Assim, cabe à Empresa, com suporte de seus
                        parceiros (operadores), a busca pela adequada
                        implementação de medidas, e a sua manutenção, que visem
                        a segurança da informação, privacidade dos USUÁRIOS,
                        respeito aos direitos dos titulares e atendimento ao
                        previsto para o correto tratamento de dados pessoais nos
                        termos legais. É de responsabilidade dos USUÁRIOS deste
                        Website agirem de boa fé e dentro dos ditames legais
                        aplicáveis, sendo que, na hipótese de agir de forma
                        contrária à lei, ética e/ou boa-fé, responderá
                        civilmente e/ou criminalmente por seus atos, perante
                        esta Empresa e/ou terceiros.
                    </S.ContentSubTitle>
                </S.Content>

                <S.Content>
                    <S.Title>
                        1.10 Dos direitos dos USUÁRIOS (titulares dos dados
                        pessoais)
                    </S.Title>
                    <S.ContentSubTitle>
                        Nos termos do art. 18 da Lei n. 13.709/2018, poderá o
                        USUÁRIO, quanto aos eventuais Dados Pessoais fornecidos,
                        exercer (quando da entrada em vigor integral, e completa
                        regulamentação, da LGPD) seus direitos de:
                        <ol>
                            <li>Confirmação da existência de tratamento;</li>
                            <li>Acesso aos dados;</li>
                            <li>
                                Correção de dados incompletos, inexatos ou
                                desatualizados;
                            </li>
                            <li>
                                Anonimização, bloqueio ou eliminação de dados
                                desnecessários, excessivos ou tratados em
                                desconformidade com o disposto nesta Lei;
                            </li>
                            <li>
                                Portabilidade dos dados a outro fornecedor de
                                serviço ou produto, mediante requisição
                                expressa, de acordo com a regulamentação da
                                autoridade nacional, observados os segredos
                                comercial e industrial;
                            </li>
                            <li>
                                Eliminação dos dados pessoais tratados com o
                                consentimento do titular, exceto nas hipóteses
                                previstas no art. 16 desta Lei;
                            </li>
                            <li>
                                Informação das entidades públicas e privadas com
                                as quais o controlador realizou uso
                                compartilhado de dados;
                            </li>
                            <li>
                                Informação sobre a possibilidade de não fornecer
                                consentimento e sobre as consequências da
                                negativa;
                            </li>
                            <li>
                                Revogação do consentimento, nos termos do § 5º
                                do art. 8º desta Lei.
                            </li>
                        </ol>
                        Ainda, caso a Empresa não atenda ao solicitado, o
                        titular dos dados pessoais tem o direito de peticionar
                        em relação aos seus dados contra o controlador perante a
                        autoridade nacional, mediante acesso e preenchimento de
                        formulário específico junto ao website da ANPD, e de
                        acordo com as regras lá apresentadas. Para mais
                        informações acesse:{" "}
                        <a href="https://www.gov.br/anpd/pt-br/canais_atendimento/cidadao-titular-de-dados/peticao-de-titular-contra-controlador-de-dados">
                            https://www.gov.br/anpd/pt-br/canais_atendimento/cidadao-titular-de-dados/peticao-de-titular-contra-controlador-de-dados
                        </a>
                        .
                    </S.ContentSubTitle>
                </S.Content>
                <S.Content>
                    <S.Title>1.11 Links externos</S.Title>
                    <S.ContentSubTitle>
                        FASTPROBR SERVIÇOS DE INFORMÁTICA www.fastprobr.com
                        dpo@fastprobr.com POLÍTICA DE PRIVACIDADE E COOKIES:
                        ARKTOOLS FASTPROBR SERVIÇOS DE INFORMÁTICA DOCUMENTO
                        PÚBLICO PÁGINA 10 DE 12 VERSÃO 1.0 - MAIO 2024 Os textos
                        e imagens publicados no Website podem apresentar links
                        para outros sites (tais como os sites de Parceiros). Por
                        não existirem vínculos entre esta Empresa e os sites
                        apontados pelos links, a Empresa FastProBR não pode
                        oferecer garantias ou responsabilizar-se quanto a
                        questões de privacidade, segurança e exatidão das
                        informações e dados durante a navegação / utilização por
                        parte dos USUÁRIOS nos referidos websites. Por favor,
                        para obter tais informações, verifique os Termos de Uso
                        e Políticas de Privacidade, e demais documentos legais,
                        diretamente no website de terceiro acessado.
                    </S.ContentSubTitle>
                </S.Content>

                <S.Content>
                    <S.Title>1.12 Da Segurança</S.Title>
                    <S.ContentSubTitle>
                        Esta Empresa adota medidas de segurança quanto às
                        informações de seus USUÁRIOS, por meio de empresas
                        contratadas para disponibilização e manutenção deste
                        Website e serviço de e-mails / banco de dados. Contudo,
                        é dever do USUÁRIO zelar pela sua própria segurança,
                        pela segurança de suas informações e pela sua
                        privacidade, atentando-se ao tipo de informação que é
                        disponibilizada no Website, bem como demais precauções
                        de segurança digital em seu próprio dispositivo de
                        acesso e de conexão. Ressalta-se que, na hipótese de
                        haver algum incidente de segurança relativo a este
                        Website, ou a dados de USUÁRIOS cadastrados por meio
                        deste, que possam gerar algum tipo de prejuízo ou dano,
                        poderão ser publicados avisos no próprio Website e/ou
                        envio de e-mail aos USUÁRIOS afetados, nos termos da
                        Lei.
                    </S.ContentSubTitle>
                </S.Content>

                <S.Content>
                    <S.Title>2 POLÍTICA DE COOKIES</S.Title>
                    <S.ContentSubTitle>
                        Utilizamos o sistema de “Cookies” com a finalidade de
                        realizar adequações técnicas para permitir a correta
                        disposição e execução de conteúdo / ferramentas, bem
                        como obter dados estatísticos sobre a utilização do
                        serviço por parte do USUÁRIO, com o objetivo de melhorar
                        a experiência deste.
                    </S.ContentSubTitle>
                </S.Content>

                <S.Content>
                    <S.Title>2.1 O que são “cookies”</S.Title>
                    <S.ContentSubTitle>
                        Os “Cookies” são pequenos arquivos digitais que são
                        baixados e mantidos no dispositivo utilizado pelo
                        USUÁRIO para acessar e utilizar o sistema e serviços
                        fornecidos. Estes “Cookies” permitem, entre outros,
                        habilitar algumas funcionalidades técnicas, compilar
                        informações estatísticas, armazenar e recuperar
                        informação sobre os hábitos de navegação e preferências
                        do USUÁRIO, bem como identificar o comportamento do
                        USUÁRIO.
                    </S.ContentSubTitle>
                </S.Content>

                <S.Content>
                    <S.Title>
                        2.2 Que tipos de “cookies” são utilizados por este
                        Website
                    </S.Title>
                    <S.ContentSubTitle>
                        Quanto a titularidade do gerenciamento, são utilizados
                        dois tipos:
                        <ul>
                            <li>
                                “Cookies” próprios - são os recebidos e enviados
                                para / pelo dispositivo do USUÁRIO para o
                                sistema / terminal do gerenciador deste Website,
                                e por este tratado.
                            </li>
                            <li>
                                “Cookies” de terceiros - são os recebidos e
                                enviados para / pelo dispositivo do USUÁRIO para
                                o sistema / terminal de um gerenciador de
                                empresa terceira, não gestora deste Website, a
                                qual irá efetuar o tratamento dos dados
                                fornecidos pelos “Cookies”.
                            </li>
                        </ul>
                        Quanto à finalidade, são utilizados os seguintes tipos
                        de “Cookies”:
                        <ul>
                            <li>
                                “Cookies” técnicos - são aqueles que permitem ao
                                USUÁRIO navegar e utilizar um website,
                                plataforma ou aplicação digital, usufruindo das
                                diferentes opções e serviços disponibilizados,
                                tais como: controlar o tráfego e comunicação de
                                dados, identificar a seção, controlar e permitir
                                acesso restrito, utilizar sistemas de segurança
                                durante o uso, armazenar conteúdo para a
                                disponibilização de vídeos e sons e compartilhar
                                conteúdos por meio de redes sociais.
                            </li>
                            <li>
                                “Cookies” de análises - são os que permitem o
                                acompanhamento e análise do comportamento dos
                                USUÁRIOS durante o acesso / uso dos websites
                                vinculados. A informação obtida por este tipo de
                                “Cookies” é utilizada para analisar a atividade
                                dos websites, aplicativos ou plataforma, e para
                                a elaboração de perfis de navegação dos seus
                                USUÁRIOS, com o objetivo de introduzir melhorias
                                nas funcionalidades, com base na experiência e
                                reporte destes.
                            </li>
                        </ul>
                        Quanto ao prazo em que os “Cookies” permanecem
                        armazenados no seu dispositivo de USUÁRIO:
                        <ul>
                            <li>
                                “Cookies” de sessão: são de um tipo projetado
                                para obter e armazenar dados durante o acesso ao
                                website / aplicativo. São utilizados para
                                armazenar e tratar dados necessários para uma
                                ocasião específica.
                            </li>
                            <li>
                                “Cookies” permanentes: são de um tipo em que os
                                dados obtidos são armazenados pelo gestor,
                                podendo ser acessados e tratados durante um
                                período definido por este, salvo quando
                                desativados ou apagados pelo USUÁRIO.
                            </li>
                        </ul>
                    </S.ContentSubTitle>
                </S.Content>
                <S.Content>
                    <S.Title>2.3 Como gerenciar os “cookies”</S.Title>
                    <S.ContentSubTitle>
                        Você, USUÁRIO pode bloquear ou desabilitar os “Cookies”,
                        total ou parcialmente, por meio das configurações das
                        opções de seu navegador de internet. Ademais, ao USUÁRIO
                        é possível a revogação do consentimento dado para o
                        armazenamento e tratamento de “Cookies” perante este
                        Website, mediante configuração em seu navegador de
                        internet. A opção por desabilitar determinados “Cookies”
                        pode levar à perda de funcionalidades de alguns recursos
                        e funcionalidades deste Website, limitar o acesso a
                        alguns de seus conteúdos, bem como pode afetar o
                        funcionamento e usabilidade de algumas partes deste
                        Website. Assim, recomendamos que as configurações sejam
                        realizadas com cautela. Alterações nas opções de
                        consentimento anterior podem ser executadas via link:{" "}
                        <a href="https://www.arktools.io">
                            https://www.arktools.io
                        </a>{" "}
                        no canto esquerdo inferior da página em “Gerenciar
                        Cookies”.
                        <strong>Lista de Cookies utilizados:</strong>
                        São utilizados os seguintes cookies, listados no quadro
                        abaixo:
                        <table>
                            <thead>
                                <tr>
                                    <th>Cookie</th>
                                    <th>Duração</th>
                                    <th>Descrição</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>fAst3DlAbUsErcOnsEnt</td>
                                    <td>
                                        1 ano ou quando a versão do documento
                                        for atualizada.
                                    </td>
                                    <td>
                                        Salva as preferências atuais do Usuário;
                                        salva o número de identificação do
                                        Usuário (utilizado para verificar se o
                                        Usuário aceitou a versão atual da
                                        Política de Privacidade e Cookies).
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </S.ContentSubTitle>
                </S.Content>

                <S.Content>
                    <S.Title>3 DO CONSENTIMENTO</S.Title>
                    <S.ContentSubTitle>
                        Ao acessar e utilizar este Website e/ou utilizar os
                        serviços prestados, você, como USUÁRIO declara que
                        concorda com os termos contidos neste documento, o qual
                        está disponibilizado de maneira permanente e de fácil
                        acesso. Ademais, caso haja a utilização de algum
                        formulário disponibilizado no Website, o USUÁRIO deverá
                        marcar a opção de “Aceito a Política de Privacidade e
                        Cookies”, devidamente identificada. Nesta hipótese será
                        gerado um “log” (arquivo digital), vinculado as
                        informações preenchidas, a versão deste documento, bem
                        como contendo os seguintes dados do USUÁRIO: IP, Data e
                        Hora. Ademais, o USUÁRIO concorda com o envio e
                        tratamento de seus dados pessoais para sistemas /
                        empresas localizadas fora do território nacional, nos
                        termos expostos neste documento.
                        <strong>
                            Do consentimento - Dados Pessoais de Crianças e de
                            Adolescentes
                        </strong>
                        Em complemento ao exposto acima (“Do consentimento”), e
                        visando atender ao previsto no art. 14 da LGPD,
                        informa-se que o envio de dados pessoais de USUÁRIOS
                        menores de 18 anos de idade, deve ser realizado com o
                        consentimento específico e por meio de um dos pais ou
                        responsável legal.
                    </S.ContentSubTitle>
                </S.Content>
                <S.Content>
                    <S.Title>
                        Do consentimento - Dados Pessoais Sensíveis
                    </S.Title>
                    <S.ContentSubTitle>
                        Em complemento ao exposto acima (“Do consentimento”), e
                        visando atender ao previsto no art. 11 e seguintes da
                        LGPD, informa-se que sempre que houver a necessidade ou
                        envio voluntário de dados sensíveis, o USUÁRIO está
                        ciente que o fornecimento e tratamento se dará para os
                        fins por ele solicitado. Destaca-se que, nos termos da
                        LGPD, Dado Pessoal Sensível é considerado: "dado pessoal
                        sobre origem racial ou étnica, convicção religiosa,
                        opinião política, filiação a sindicato ou a organização
                        de caráter religioso, filosófico ou político, dado
                        referente à saúde ou à vida sexual, dado genético ou
                        biométrico, quando vinculado a uma pessoa natural.”
                    </S.ContentSubTitle>
                </S.Content>

                <S.Content>
                    <S.Title>4 DEMAIS BASES LEGAIS APLICÁVEIS</S.Title>
                    <S.ContentSubTitle>
                        Considerando-se o previsto em algumas leis aplicáveis,
                        tais como LGPD (Lei n. 13.709/18), Marco Civil da
                        Internet (Lei n. 12.965/14) e demais leis que possam ser
                        aplicadas na relação entre o USUÁRIO e a Empresa,
                        informa-se que podem ser tratados dados pessoais com
                        base nestas legislações. Ainda, caso o USUÁRIO envie
                        informações pessoais com o objetivo de se iniciar uma
                        relação contratual, o tratamento de dados pessoais
                        poderá ser embasado para tal fim. Ademais, outras bases
                        legais podem ser aplicadas, a depender do conteúdo e
                        objetivo do preenchimento do formulário disponibilizado.
                    </S.ContentSubTitle>
                </S.Content>

                <S.Content>
                    <S.Title>5 DISPOSIÇÕES FINAIS</S.Title>
                    <S.ContentSubTitle>
                        O presente documento, contendo a Política de Privacidade
                        e Cookies, pode ser revisto e/ou atualizado a qualquer
                        momento. Este documento sempre estará disponível para
                        consulta neste Website, por meio de livre e fácil
                        acesso. Reservamo-nos ao direito de promover
                        aprimoramentos e atualizações no Website, que podem
                        alterar sua estrutura ou layout, conforme nossa
                        conveniência e necessidade. Caso qualquer disposição
                        deste documento seja considerada inexequível ou
                        inválida, essa disposição será isoladamente invalidada e
                        não atingirá o restante das disposições aqui contidas.
                        Ademais, informa-se que situações não previstas neste
                        documento estão sujeitas a análise e deliberação da
                        equipe responsável junto a esta empresa, mediante
                        solicitação. Em caso de dúvidas, sugestões e/ou qualquer
                        outro tipo de contato quanto a este documento, o USUÁRIO
                        deverá entrar em contato com o nosso Encarregado pelo
                        Tratamento de Dados Pessoais (DPO) no endereço de
                        e-mail:{" "}
                        <a href="mailto:dpo@fastprobr.com">dpo@fastprobr.com</a>
                        .
                    </S.ContentSubTitle>
                </S.Content>
            </S.Container>
        </Container>
    );
}

export default () => (
    <svg
        width="33"
        height="39"
        viewBox="0 0 33 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M29.0417 9.75033H25.4583C25.4583 4.73366 21.5167 0.791992 16.5 0.791992C11.4833 0.791992 7.54167 4.73366 7.54167 9.75033H3.95833C1.9875 9.75033 0.375 11.3628 0.375 13.3337V34.8337C0.375 36.8045 1.9875 38.417 3.95833 38.417H29.0417C31.0125 38.417 32.625 36.8045 32.625 34.8337V13.3337C32.625 11.3628 31.0125 9.75033 29.0417 9.75033ZM16.5 4.37533C19.5458 4.37533 21.875 6.70449 21.875 9.75033H11.125C11.125 6.70449 13.4542 4.37533 16.5 4.37533ZM29.0417 34.8337H3.95833V13.3337H29.0417V34.8337ZM16.5 20.5003C13.4542 20.5003 11.125 18.1712 11.125 15.1253H7.54167C7.54167 20.142 11.4833 24.0837 16.5 24.0837C21.5167 24.0837 25.4583 20.142 25.4583 15.1253H21.875C21.875 18.1712 19.5458 20.5003 16.5 20.5003Z"
            fill="#487B9D"
        />
    </svg>
);

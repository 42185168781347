import styled from "styled-components";

export const ContainerIconTitle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1.5em;
`;

export const IconSetas = styled.img`
    @media (max-width: 450px) {
        width: 50px;
    }
`;

export const Icon = styled.div`
    height: 60px;
    display: flex;
    align-items: center;
    svg {
        @media (max-width: 768px) {
            width: 30px;
            height: 30px;
        }
    }
`;

export const Section = styled.section`
    display: flex;
    flex-direction: column;
    margin-top: 6em;

    @media (max-width: 452px) {
        gap: 2em;
        margin-top: 2em;
    }
`;

export const ContainerGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    color: ${(props) => props.theme.white};
    gap: 2em;
    justify-content: center;

    @media (max-width: 1180px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 450px) {
        display: grid;
        grid-template-columns: 1fr;
    }
`;

export const ContainerSquare = styled.div`
    background: ${(props) => props.theme.darkBlue};
    padding: 2em 2em;
    border-radius: 8px;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    @media (max-width: 1180px) {
        justify-content: center;
    }
`;

export const ContainerIconText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
`;

export const ContainerText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

export const TitleSquare = styled.h2`
    font-size: 1.5rem;

    font-weight: 500;
    margin: 0;
    @media (max-width: 768px) {
        font-size: 1rem;
    }
`;

export const Text = styled.p`
    font-size: 1rem;
    text-align: justify;
    font-weight: 400;
`;
export const Wrapper = styled.div`
    position: relative;
    &::before {
        content: "";
        position: absolute;
        border: 3px dashed black;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 50%;
        border-bottom: none;
        border-right: none;
        border-top: none;
        z-index: -1;
        @media (min-width: 452px) {
            display: none;
        }
    }
`;
export const Rectangle = styled.div`
    border: 1px solid black;
    padding: 20px;
    width: 25%;
    margin: auto;
    border-bottom: none;
    border-style: dotted;
    border-bottom-style: none;
    margin-top: 4em;
    @media (max-width: 452px) {
        display: none;
    }
`;

export const GridRectangle = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
`;

export const ContainerRectangle = styled.div``;

export const RectangleInvert = styled.div`
    border: 1px solid black;
    padding: 20px;
    width: 40%;
    margin: auto;
    border-top: none;
    border-style: dotted;
    border-top-style: none;
    @media (max-width: 452px) {
        display: none;
    }
`;

import React, { useId } from "react";
import * as S from "./style";
import { InputType } from "types";

const InputCheckbox = React.forwardRef<HTMLInputElement, InputType>(
    (
        { type = "checkbox", name = "", label = "", color = "", ...props },
        ref
    ) => {
        const inputId = useId();
        return (
            <>
                <S.Checkbox
                    ref={ref}
                    id={inputId}
                    type={type}
                    name={name}
                    {...props}
                />
                <S.LabelCheckbox color={color} htmlFor={inputId}>
                    {label}
                </S.LabelCheckbox>
            </>
        );
    }
);

export default InputCheckbox;

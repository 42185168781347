import React, { useId } from "react";
import * as S from "./style";
import { InputType } from "types";
import { HelperText } from "components";

const Input = React.forwardRef<HTMLInputElement, InputType>(
    (
        { type = "text", name = "", label = "", helperText = "", ...props },
        ref
    ) => {
        const inputId = useId();
        const hasError = helperText.length > 0;
        return (
            <S.Container>
                <S.Label htmlFor={inputId}>{label}</S.Label>
                <S.Input
                    ref={ref}
                    type={type}
                    id={inputId}
                    name={name}
                    hasError={hasError}
                    {...props}
                />

                {hasError && <HelperText helperText={helperText} />}
            </S.Container>
        );
    }
);

export default Input;

import * as S from "./style";
import IconSetas from "assets/icons/icon-seta.svg";
import Title from "components/Title";
import { ProcessItems } from "./process";
import { ProcessType } from "types";

export default function OurProcess() {
    return (
        <S.Section>
            <S.ContainerIconTitle>
                <S.IconSetas src={IconSetas} />
                <Title title="Passo a passo para a contratação dos nossos serviços:
" />
            </S.ContainerIconTitle>
            <S.Wrapper>
                <S.Rectangle />
                <S.ContainerGrid>
                    {ProcessItems.map((item: ProcessType) => (
                        <S.ContainerSquare key={item.id}>
                            <S.ContainerIconText>
                                <S.Icon>{item.icon}</S.Icon>
                                <S.TitleSquare>{item.title}</S.TitleSquare>
                            </S.ContainerIconText>
                            <S.ContainerText>
                                <S.Text>{item.description}</S.Text>

                            </S.ContainerText>
                        </S.ContainerSquare>
                    ))}
                </S.ContainerGrid>
                <S.GridRectangle>
                    <S.ContainerRectangle>
                        <S.RectangleInvert />
                    </S.ContainerRectangle>
                    <S.ContainerRectangle>
                        <S.RectangleInvert />
                    </S.ContainerRectangle>
                </S.GridRectangle>
            </S.Wrapper>
        </S.Section>
    );
}

import styled from "styled-components";

export const MenuContainer = styled.nav<{ showMobileMenu: boolean }>`
    background-color: ${(props) => props.theme.white};
    padding: 10px 10px 8px 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 3;
    top: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    gap: 10px;
    transition: height transform 5s ease-in-out;

    @media (max-width: 700px) {
        position: static;
    }
    @media (min-width: 999px) {
        flex-wrap: wrap;
    }
    @media (max-width: 999px) {
        flex-direction: column;
        align-items: flex-start;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
        /* animation-name: ${(props) =>
            props.showMobileMenu ? "example" : "exampleClose"}; */

        @keyframes example {
            from {
                height: 90px;
            }
            to {
                height: 90%;
            }
        }

        @keyframes exampleClose {
            from {
                height: 90%;
            }
            to {
                height: 90px;
            }
        }
    }
`;
export const Logo = styled.img`
    max-width: 233px;
`;

export const MenuLogo = styled.div`
    display: flex;
    &:hover {
        cursor: pointer;
    }

    @media (max-width: 999px) {
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
`;

export const MenuLinks = styled.ul<{ showMobileMenu: boolean }>`
    list-style: none;
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
    margin: 0;
    @media (max-width: 999px) {
        display: ${(props) => (props.showMobileMenu ? "flex" : "none")};
        flex-direction: column;
    }
`;

export const ContentButton = styled.div<{ showMobileMenu: boolean }>`
    display: flex;
    gap: 10px;

    @media (max-width: 999px) {
        display: ${(props) => (props.showMobileMenu ? "flex" : "none")};
        flex-wrap: wrap;
        padding-bottom: 1em;
    }
`;

export const MenuMobileIcon = styled.div<{ showMobileMenu: boolean }>`
    display: none;

    @media (max-width: 999px) {
        display: flex;
        margin-right: 2em;
    }
`;

export const Ancor = styled.a`
    text-decoration: none;
    cursor: pointer;
    font-size: 1rem;
    color: #888888;
    font-weight: 100;
    transition: transform 0.3s ease-in-out;
    &:hover {
        color: #000000;
    }
`;

export const ButtonLogin = styled.button`
    border: none;
    background: ${(props) => props.theme.white};
    font-size: 1.1rem;
    padding: 0 20px;
    cursor: pointer;
`;

export const ContentHeaderUser = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2em;
`;

export const ButtonUser = styled.button`
    border: none;
    background-color: ${(props) => props.theme.primaryColorLight};
    border-radius: 50%;
    padding: 10px;
`;
export const Sacola = styled.button`
    border: none;
    background-color: transparent;
    position: relative;
`;

export const CountItens = styled.span`
    position: absolute;
    top: 0;
    right: 0.3em;
    border-radius: 50%;
    background-color: #ff6969;
    color: #fff;
    width: 16px;
    height: 16px;
    font-size: 0.7rem;
    font-weight: 700;
`;

export const ContainerUser = styled.div<{ show?: boolean }>`
    position: relative;

    &:first-child div {
        visibility: ${({ show }) => (show ? "visible" : "hidden")};
        width: 273px;
        height: 321px;
    }
`;

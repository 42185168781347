import { WppButton } from "assets/icons";
import * as S from "./style";

export default function WhatsappButton() {
    const phoneNumber = "5511945553241";
    const message = encodeURIComponent(
        "Olá, gostaria de saber mais informações sobre o Arktools."
    );
    const href = `https://wa.me/${phoneNumber}?text=${message}`;

    return (
        <S.ContainerWppButton>
            <S.Button href={href} target="_blank" rel="noopener noreferrer">
                <WppButton />
            </S.Button>
        </S.ContainerWppButton>
    );
}

import { Loading } from "components";
import { useStorePayment } from "contexts/Payment.context";
import { useDeleteCarrinho, useGetCarrinho } from "hooks";
import * as S from "./style";

export default function SummaryOrder() {
    const { isLoading } = useDeleteCarrinho();
    const { listCarrinho, isLoadingListCarrinho } = useGetCarrinho();

    const { total } = useStorePayment();

    const formatValue = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
    });

    async function handleDelete() {
        // await deleteCarrinho();
        // refetchListCarrinho();
        // router("/orders");
    }
    return (
        <S.ContainerSummary>
            <S.WrapperSummary>
                <S.ContentSummary>
                    <S.SummaryTitle>Resumo do pedido</S.SummaryTitle>
                    {isLoadingListCarrinho ? (
                        <Loading size={20} color="#487B9D" />
                    ) : (
                        listCarrinho?.map((item: any, index: number) => (
                            <S.Subtitle key={index}>
                                {item?.service[0]?.title}
                            </S.Subtitle>
                        ))
                    )}
                    <S.SummaryValue>
                        <span>Subtotal</span>
                        <span>{formatValue.format(total)}</span>
                    </S.SummaryValue>
                    <S.SummaryValue>
                        <span>Descontos</span>
                        <span>0</span>
                    </S.SummaryValue>
                    <S.SummaryValue>
                        <span>Total</span>
                        <span>{formatValue.format(total)}</span>
                    </S.SummaryValue>
                </S.ContentSummary>
                <S.SummaryButton>
                    <S.ButtonWhite>Voltar</S.ButtonWhite>
                    {isLoading ? (
                        <Loading size={10} color="#487B9D" />
                    ) : (
                        <S.ButtonBlue onClick={handleDelete}>
                            Finalizar pedido
                        </S.ButtonBlue>
                    )}
                </S.SummaryButton>
            </S.WrapperSummary>
        </S.ContainerSummary>
    );
}

import { Ideia, Passo1, Passo4 } from "assets/icons";
import Passo2 from "assets/icons/passo2";
import { ProcessType } from "types";

export const ProcessItems: Array<ProcessType> = [
    {
        id: 1,
        title: "Passo 1",
        icon: <Passo1 />,
        description: `
            Escolha um ou mais serviços oferecidos para garantir uma apresentação de projeto impactante junto ao seu cliente!
            Serviços como a geração de Imagens renderizadas, estilizadas, com animações, vídeos, visitas virtuais etc.
        `,
    },
    {
        id: 2,
        title: "Passo 2",
        icon: <Passo2 />,
        description:
            "Faça um cadastro no site ou entre com as suas credenciais para login, selecione os serviços, envie seu projeto modelado 3D no SKETCH UP ou 3DS MAX para a plataforma ARKTOOLS. Caso necessário, esclareça dúvidas ou comunique demandas específicas.",
    },

    {
        id: 3,
        title: "Passo 3",
        icon: <Ideia />,
        description:
            "Durante a confirmação da contratação dos serviços, você será informado do prazo de entrega. Com os arquivos fornecidos, suas imagens e ou vídeos serão preparados com a máxima qualidade possível.",
    },

    {
        id: 4,
        title: "Passo 4",
        icon: <Passo4 />,
        description:
            "Após o processamento e a criação das imagens e vídeos, uma mensagem será enviada para você dando acesso aos trabalhos finalizados. OBS. Em alguns casos, resultados preliminares poderão ser enviados para a sua aprovação antes do processamento final.",
    },
];

export default () => (
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14.49 11.3897L11.2987 9.95964L11.2899 9.95558C11.1242 9.88472 10.9435 9.85628 10.764 9.87284C10.5846 9.8894 10.4121 9.95043 10.2622 10.0504C10.2446 10.0621 10.2276 10.0747 10.2114 10.0884L8.56258 11.494C7.518 10.9866 6.43956 9.91629 5.93217 8.88526L7.33984 7.21136C7.35339 7.19442 7.36626 7.17749 7.37846 7.1592C7.47629 7.00969 7.53565 6.83832 7.55125 6.66033C7.56685 6.48234 7.53821 6.30325 7.46787 6.13901V6.13088L6.03378 2.93414C5.9408 2.71958 5.78092 2.54085 5.57801 2.42462C5.3751 2.30839 5.14004 2.2609 4.90792 2.28924C3.99 2.41003 3.14744 2.86083 2.5376 3.55743C1.92775 4.25404 1.59234 5.14882 1.594 6.07465C1.594 11.4533 5.97011 15.8295 11.3488 15.8295C12.2746 15.8311 13.1694 15.4957 13.866 14.8859C14.5626 14.276 15.0134 13.4335 15.1342 12.5155C15.1626 12.2835 15.1152 12.0485 14.9991 11.8456C14.883 11.6427 14.7044 11.4828 14.49 11.3897ZM11.3488 14.7456C9.04989 14.7431 6.84586 13.8287 5.22029 12.2032C3.59472 10.5776 2.68038 8.37356 2.67787 6.07465C2.67532 5.41315 2.91364 4.77333 3.34833 4.27469C3.78302 3.77605 4.38435 3.45268 5.04002 3.36498C5.03975 3.36768 5.03975 3.37041 5.04002 3.37311L6.46259 6.55697L5.06237 8.23291C5.04816 8.24926 5.03525 8.2667 5.02376 8.28507C4.92182 8.44149 4.86201 8.6216 4.85015 8.80794C4.83828 8.99427 4.87475 9.18052 4.95602 9.34861C5.56975 10.6039 6.83449 11.8591 8.10329 12.4722C8.27262 12.5527 8.45994 12.5879 8.64695 12.5744C8.83395 12.5608 9.01425 12.499 9.17022 12.395C9.18761 12.3832 9.20435 12.3706 9.22035 12.357L10.8671 10.9521L14.051 12.378H14.0585C13.9718 13.0346 13.6489 13.6372 13.1502 14.0729C12.6515 14.5087 12.0111 14.7478 11.3488 14.7456Z"
            fill="black"
        />
    </svg>
);

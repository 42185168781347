import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import { mobileMediaQuery } from "styles/themes/mediaQueries";

export const ContainerTitles = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 8em;

    ${mobileMediaQuery} {
        margin-top: 3em;
    }
`;

export const StyledCarousel = styled(Carousel)`
    margin-top: 4em;
    ${mobileMediaQuery} {
        margin-top: 1em;
    }
`;

export const CarouselSlide = styled.div`
    display: flex;
    gap: 2em;
    padding: 1em;
    justify-content: space-between;

    ${mobileMediaQuery} {
        margin-top: 2em;
    }
    transition: transform 0.3s ease-in-out;
    &:hover {
        cursor: pointer;
        transform: scale(1.1);
    }
`;

export const Image = styled.img`
    margin: 5px;
`;

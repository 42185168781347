import { SpinnerInterfaces } from "interfaces";
import { CSSProperties } from "react";
import { PulseLoader } from "react-spinners";

/**
 * @component Loading
 * @returns {JSX.Element} returns loading
 */

const Loading: React.FC<SpinnerInterfaces> = ({
    size,
    color = "#ffffff",
}: SpinnerInterfaces): JSX.Element => {
    const override: CSSProperties = {
        display: "block",
        margin: "0 auto",
        borderColor: "black",
    };
    return (
        <>
            <PulseLoader
                color={color}
                cssOverride={override}
                loading={true}
                size={size}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </>
    );
};

export default Loading;

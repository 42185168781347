import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";
import {
    desktopMediaQuery,
    fullMediaQuery,
    mobileMediaQuery,
    tabletMediaQuery,
} from "styles/themes/mediaQueries";

export const Text = styled.p`
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;
    padding: 100;
    line-height: 30px;

    ${mobileMediaQuery} {
        font-size: 1rem;
        line-height: 20px;
    }
`;

export const ContentButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
`;

export const ContainerContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3em;
    padding: 10px;
    ${desktopMediaQuery} {
        min-width: 300px;
    }

    ${mobileMediaQuery} {
        gap: 1em;
    }

    ${fullMediaQuery} {
        padding: 3em;
    }
`;

export const ContainerSubtitles = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ContainerBanner = styled.div`
    display: flex;
    align-items: left;
    gap: 1em;
    text-align: left;
    justify-content: center;
    padding: 2em 1em;

    height: 100%;

    @media (max-width: 1200px) {
        flex-direction: column-reverse;
    }

    ${mobileMediaQuery} {
        margin-top: 1em;
        gap: 20px;
    }
`;

export const CarouselContent = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
`;

export const StyledCarousel = styled(Carousel)`
    .carousel .slider .selected {
        width: 100%;
    }
    .carousel .slider {
        ${desktopMediaQuery} {
            height: 80vh;
            margin-top: 6em;
        }

        max-width: 90vw;
    }
    .carousel .slider .previous {
        width: 0;
    }

    .carousel .slide {
        padding: 1em;
    }
`;

export const CustomPrevArrow = styled.div`
    position: absolute;
    left: 1em;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: ${(props) => props.theme.white};
        padding: 5px;
        z-index: 1;
        transition: transform ease-in-out 0.3s;
        ${tabletMediaQuery} {
            height: 20px;
            width: 20px;
        }
        &:hover {
            cursor: pointer;
            transform: scale(1.1);
        }
    }
    svg {
        color: ${(props) => props.theme.primaryColorLight};
        height: 20px;
        width: 20px;
        ${tabletMediaQuery} {
            height: 12px;
            width: 12px;
        }
    }
`;
export const CustomNextArrow = styled.div`
    position: absolute;
    right: 1em;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: ${(props) => props.theme.white};
        padding: 5px;
        z-index: 1;
        transition: transform ease-in-out 0.3s;
        ${tabletMediaQuery} {
            height: 20px;
            width: 20px;
        }
        &:hover {
            transform: scale(1.1);
            cursor: pointer;
        }
    }
    svg {
        color: ${(props) => props.theme.primaryColorLight};
        height: 20px;
        width: 20px;
        ${tabletMediaQuery} {
            height: 12px;
            width: 12px;
        }
    }
`;

export const Image = styled.img`
    /* width: 100%; */
    /* width: 1024px; */
    /* height: 500px; */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    height: 100%;
    /* ${tabletMediaQuery} {
        height: auto;
    }

    ${desktopMediaQuery} {
        height: 500px;
    }
    ${fullMediaQuery} {
        height: 600px;
    } */
`;
export const WrapperAnimation = styled.div`
    display: flex;
    justify-content: center;
`;
export const Video = styled.video`
    width: 100%;
    border-radius: 8px;
    ${desktopMediaQuery} {
        height: 500px;
    }
    ${fullMediaQuery} {
        height: 700px;
    }
    ${tabletMediaQuery} {
        height: auto;
    }
`;
export const ContentVideo = styled.div`
    width: 100%;
    max-width: 1300px;
    overflow: hidden;
`;

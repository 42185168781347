import React, { useId } from "react";
import * as S from "./style";
import { SelectType } from "types";
import { HelperText } from "components";

const Select = React.forwardRef<HTMLSelectElement, SelectType>(
    ({ name = "", label = "", helperText = "", ...props }, ref) => {
        const inputId = useId();
        const hasError = helperText.length > 0;
        return (
            <S.Container>
                <S.Label htmlFor={inputId}>{label}</S.Label>
                <S.Select
                    ref={ref}
                    id={inputId}
                    name={name}
                    hasError={hasError}
                    {...props}
                >
                    <option value="in progress">In progress</option>
                    <option value="complete">Complete</option>
                </S.Select>

                {hasError && <HelperText helperText={helperText} />}
            </S.Container>
        );
    }
);

export default Select;

import { ThemeProvider } from "styled-components";
import { QueryClient, QueryClientProvider } from "react-query";
import AuthProvider from "contexts/Auth.context";
import MainRoutes from "routes";
import theme from "styles/themes/colors";
import GlobalStyle from "styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ChatProvider from "contexts/Chat.context";
import { Helmet } from "react-helmet";

export default function App() {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    });
    return (
        <>
        <Helmet>
        <title>ArkTools</title>
        <meta name="description" content="A solução completa para visualização arquitetônica em um único lugar: Renderização, Tours 360, Realidade Aumentada e Espaço na Nuvem para projetos e portfólio."/>
        </Helmet>
        <QueryClientProvider client={queryClient}>
            <AuthProvider>
                <ChatProvider>
                    <ThemeProvider theme={theme}>
                        <GlobalStyle />
                        <ToastContainer autoClose={1000} />
                        <MainRoutes />
                    </ThemeProvider>
                </ChatProvider>
            </AuthProvider>
        </QueryClientProvider>
        </>
    );
}

import { useNavigate } from "react-router-dom";
import { GetService } from "utils/typeService";
import * as S from "./style";

interface ContentItemProps {
    imageSrc: string;
    subtitle: string;
    text: string;
    reversed?: boolean;
    id: string;
}

export default function ContentItem({
    imageSrc,
    subtitle,
    text,
    reversed = false,
    id,
}: ContentItemProps): JSX.Element {
    const route = useNavigate();

    return (
        <S.ContainerFlex flexReversed={reversed}>
            <S.ContainerImage onClick={() => route(`/detailService/${id}`)}>
                <S.Image src={imageSrc} />
            </S.ContainerImage>
            <S.ContainerText>
                <S.Subtitle>{subtitle}</S.Subtitle>
                <S.Text>{text}</S.Text>
                <S.ContentButton>
                    <S.ButtonBlue
                        disabled={id !== GetService()["imagensRealista"]}
                        onClick={() => route(`/scanninghiring/${id}`)}
                    >
                        Contratar
                    </S.ButtonBlue>
                    <S.ButtonMore onClick={() => route(`/detailService/${id}`)}>
                        Saiba mais
                    </S.ButtonMore>
                </S.ContentButton>
            </S.ContainerText>
        </S.ContainerFlex>
    );
}

import { MenuItemType } from "types";
import * as S from "./style";
import { useLocation, useNavigate } from "react-router-dom";

export default function MenuItem({ children, to }: MenuItemType) {
    const router = useNavigate();
    const location = useLocation();
    const isActive = location.pathname === to;

    return (
        <S.MenuItem onClick={() => router(to)}>
            <S.Span active={isActive}>{children}</S.Span>
        </S.MenuItem>
    );
}

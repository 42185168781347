import { api } from "api/api";
import axios from "axios";
import { createContext, useState } from "react";
import { toast } from "react-toastify";
import { AuthType, ChildrenType } from "types";

export const AuthContent = createContext({} as AuthType);

export default function AuthProvider({ children }: ChildrenType) {
    const [isloading, setIsloading] = useState<boolean>(false);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    async function PostAutentication(data: any) {
        try {
            setIsloading(true);
            const response = await api("auth/login/", {
                method: "POST",
                data: JSON.stringify(data),
            });
            const respUser = await getUser(response.id);
            const role = respUser.role[0]?.roleNumber
                ? respUser.role[0]?.roleNumber
                : 0;
            localStorage.setItem("id", JSON.stringify(response.id));
            localStorage.setItem("getUser", JSON.stringify(response));
            localStorage.setItem("user", JSON.stringify(role));
            localStorage.setItem("token", JSON.stringify(response.token));
            localStorage.setItem("isAuthenticated", JSON.stringify(true));
            setIsAuthenticated(true);
            return response;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as any;
                if (
                    axiosError.response?.data &&
                    axiosError.response.data.message
                ) {
                    toast.error(axiosError.response.data.message);
                } else {
                    toast.error(axiosError.message);
                }
            } else {
                toast.error("An error occurred when trying to login!");
            }
            toast.error("Ops ocorreu um erro na authenticação!");
            return error;
        } finally {
            setIsloading(false);
        }
    }
    async function getUser(id: string) {
        const response = await api(`user/${id}`, {
            method: "GET",
        });
        return response.responseUser;
    }

    return (
        <AuthContent.Provider
            value={{
                isAuthenticated,
                setIsAuthenticated,
                PostAutentication,
                isloading,
            }}
        >
            {children}
        </AuthContent.Provider>
    );
}

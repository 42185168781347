import * as S from "./style";
import { Container, Title } from "components";
import { ProjectsItems } from "pages/projects/projects";
import { useParams } from "react-router-dom";

export default function DetailedProject() {
    const { id } = useParams();
    const filteredData = ProjectsItems.filter((array) =>
        array.some((item: any) => item.id == id)
    ).flat();

    const resultObject = filteredData.find((item: any) => item.id == id);

    return (
        <Container>
            <S.Container>
                <S.Content>
                    <Title
                        title={
                            resultObject?.title ? resultObject?.title : "Title"
                        }
                    />
                    {resultObject?.id == 1 ? (
                        <S.WrapperAnimation>
                            <S.ContentVideo>
                                <S.Video controls loop autoPlay>
                                    <source
                                        src={
                                            resultObject?.detaildProject?.video
                                        }
                                        type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                </S.Video>
                            </S.ContentVideo>
                        </S.WrapperAnimation>
                    ) : (
                        <S.Image src={resultObject?.banner} />
                    )}
                    <S.Text>{resultObject?.description}</S.Text>
                </S.Content>
            </S.Container>
        </Container>
    );
}

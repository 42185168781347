import styled from "styled-components";

export const Content = styled.div``;

export const Image = styled.img`
    width: 100%;
    height: auto;
`;

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10em;
`;

export const Title = styled.h2`
    font-size: 2rem;
    margin-bottom: 0.3em;
`;

export const Text = styled.p`
    font-size: 1rem;
    line-height: 2em;
    text-align: justify;
`;

export const Line = styled.hr`
    margin: 0 0 2em 0;
    border-top: 3px solid #000;
    width: 80%;
`;

export const LineText = styled.hr`
    border-top: 3px solid #000;
    margin-top: 5em;
`;

export const IFrame = styled.iframe`
    width: 100%;
    height: 560px;
`;

import styled from "styled-components";

export const Title = styled.h2`
    color: ${(props) => props.theme.black};
    font-size: 2rem;
    margin: 0;
    font-weight: 700;

    @media (max-width: 450px) {
        font-size: 1.5rem;
    }
`;

import SmallButton from "components/Button/SmallButton";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import * as S from "./style";

type typeBanner = {
    id: string;
    title: string;
    url: string;
    video?: boolean;
};

type typeCarousel = {
    bannerList?: Array<typeBanner>;
};
export default function Carousel({ bannerList }: typeCarousel) {
    const router = useNavigate();

    const handleRoute = (route: string) => {
        router(`/${route}`);
    };

    return (
        <S.CarouselContent>
            <S.StyledCarousel
                showArrows={true}
                showStatus={false}
                showIndicators={false}
                autoPlay
                infiniteLoop={true}
                interval={5000}
                showThumbs={false}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                        <S.CustomPrevArrow
                            onClick={onClickHandler}
                            title={label}
                        >
                            <div>
                                <BsChevronLeft />
                            </div>
                        </S.CustomPrevArrow>
                    )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                        <S.CustomNextArrow
                            onClick={onClickHandler}
                            title={label}
                        >
                            <div>
                                <BsChevronRight />
                            </div>
                        </S.CustomNextArrow>
                    )
                }
            >
                {bannerList?.map((CarouselItem: typeBanner) => (
                    <>
                        {CarouselItem?.video ? (
                            <S.WrapperAnimation key={CarouselItem.id}>
                                <S.ContentVideo>
                                    <S.Video controls loop autoPlay>
                                        <source
                                            src={CarouselItem.url}
                                            type="video/mp4"
                                        />
                                        Your browser does not support the video
                                        tag.
                                    </S.Video>
                                </S.ContentVideo>
                            </S.WrapperAnimation>
                        ) : (
                            <S.ContainerBanner key={CarouselItem.id}>
                                <S.Image src={CarouselItem.url} />
                                <S.ContainerContent>
                                    <S.ContainerSubtitles>
                                        <S.Text>{CarouselItem.title}</S.Text>
                                    </S.ContainerSubtitles>
                                    <S.ContentButton>
                                        <SmallButton
                                            onClick={() =>
                                                handleRoute("servicesPage")
                                            }
                                        >
                                            Saiba mais
                                        </SmallButton>
                                    </S.ContentButton>
                                </S.ContainerContent>
                            </S.ContainerBanner>
                        )}
                    </>
                ))}
            </S.StyledCarousel>
        </S.CarouselContent>
    );
}

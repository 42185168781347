import styled from "styled-components";
import { tabletMediaQuery } from "styles/themes/mediaQueries";

export const Content = styled.div`
    text-align: center;
`;

export const Image = styled.img`
    width: 100%;
    height: auto;
    margin-top: 2em;
    border-radius: 8px;
`;

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9em 7em;
    ${tabletMediaQuery} {
        padding: 9em 1em;
    }
`;

export const Title = styled.h2`
    font-size: 1.25rem;
    margin-top: 0;
    margin-bottom: 0.3em;
`;

export const Text = styled.p`
    font-size: 1rem;
    display: inline-block;
    line-height: 25px;
    text-align: justify;
    margin-top: 2em;
    max-width: 1300px;
`;

export const Line = styled.hr`
    margin: 0 0 2em 0;
    border-top: 3px solid #000;
    width: 80%;
`;

export const LineText = styled.hr`
    border-top: 3px solid #000;
    margin-top: 5em;
`;

export const WrapperAnimation = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 2em;
`;
export const ContentVideo = styled.div`
    width: 100%;
    max-width: 1300px;
    overflow: hidden;
`;
export const Video = styled.video`
    width: 100%;
    height: auto;
    border-radius: 8px;
`;

import styled from "styled-components";

export const WrapperMessage = styled.div``;

export const MessageContainer = styled.div`
    max-height: 400px;
    height: 400px;
    overflow-y: auto;
    padding: 0 10px 10px 0;
    margin-bottom: 10px;
    position: relative;
`;

export const Message = styled.div`
    display: flex;

    &.sender-data {
        justify-content: end;
    }

    &.recipient-data {
        justify-content: start;
    }
    span {
        max-width: 70%;
        background-color: #f2f2f2;
        padding: 10px;
        margin: 5px 0;
        border-radius: 5px;
        &.message-sender {
            background-color: ${(props) => props.theme.secondaryColor};
            align-self: flex-end;
            color: ${(props) => props.theme.white};
        }
        &.message-recipient {
            background-color: ${(props) => props.theme.borderGray};
            align-self: flex-start;
        }
    }
`;

export const MessageHeader = styled.header`
    display: flex;
    gap: 8px;
    align-items: center;
    background-color: ${(props) => props.theme.white};
    position: sticky;
    top: 0;
    padding: 10px;
    border: 1px solid ${(props) => props.theme.borderGray};
`;

export const HeaderName = styled.span`
    font-weight: bold;
    font-size: 1.1em;
`;

export const InputContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    input {
        flex: 1;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 8px;
        margin-right: 10px;
    }
    button {
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 5px;
        padding: 10px 20px;
        cursor: pointer;
        &:hover {
            background-color: #0056b3;
        }
    }
`;

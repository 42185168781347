import styled from "styled-components";
import { mobileMediaQuery } from "styles/themes/mediaQueries";

export const Subtitle = styled.span`
    font-size: 1.25rem;

    ${mobileMediaQuery} {
        font-size: 1rem;
    }
`;

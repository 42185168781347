import styled from "styled-components";
import {
    desktopMediaQuery,
    tabletMediaQuery,
} from "styles/themes/mediaQueries";
import Iframe from "react-iframe";

type typeButton = {
    active: boolean;
};

export const Content = styled.div`
    max-width: 1300px;
`;
export const ContentEstilizacaoSpan = styled.div`
    position: absolute;
    bottom: 0;
    background: linear-gradient(
        84.59deg,
        rgba(47, 47, 47, 0.8) 43.73%,
        rgba(87, 87, 87, 0.694543) 58.59%,
        rgba(217, 217, 217, 0.352) 78.11%
    );
    min-height: 90px;
    padding: 1.5em;
    width: 100%;
    color: ${(props) => props.theme.white};
    div {
        max-width: 626px;
    }
    span {
        font-weight: 600;
        color: #eaeaea;
    }
`;
export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9em 7em;
    ${tabletMediaQuery} {
        padding: 9em 1em;
    }
`;

export const Image = styled.img`
    width: 100%;
    height: auto;
    ${tabletMediaQuery} {
        border-radius: 8px;
    }
`;

export const Text = styled.p`
    font-size: 1rem;
    display: inline-block;
    line-height: 20px;
    text-align: justify;
    margin-top: 1em;
    font-weight: 500;
    ${tabletMediaQuery} {
        font-size: 0.8rem;
    }
`;
export const SubTitle = styled.h2`
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 1.5em;
    ${tabletMediaQuery} {
        font-size: 1rem;
    }
`;

export const ContentImage = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    ${tabletMediaQuery} {
        gap: 2em;
        flex-direction: column-reverse;
    }
`;
export const ContentSpan = styled.div<typeButton>`
    background-color: #ebf5fc;
    padding: 0.5em;
    border-top-right-radius: ${(props) => (props.active ? 0 : "8px")};
    border-top-left-radius: ${(props) => (props.active ? 0 : "8px")};
    span {
        font-size: 1.25rem;
        font-weight: 600;
    }
    ${tabletMediaQuery} {
        display: none;
    }
`;

export const ContentButton = styled.div`
    margin-top: 1.5em;
    display: flex;
    ${desktopMediaQuery} {
        justify-content: end;
    }
    ${tabletMediaQuery} {
        margin-top: 1em;
    }
`;

export const ContainerButton = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1em;
`;

export const ButtonBlue = styled.button`
    min-height: 39px;
    text-align: center;
    background-color: ${(props) => props.theme.primaryColorLight};
    color: ${(props) => props.theme.white};
    border: none;
    border-radius: 8px;
    font-size: 1.1rem;
    font-weight: 500;
    padding: 0 1em;
    transition: transform ease-in-out 0.3s;
    ${tabletMediaQuery} {
        font-size: 0.8rem;
    }
    &:hover {
        transform: scale(1.1);
    }
`;

export const ButtonWhite = styled.button`
    min-height: 39px;
    border-radius: 8px;
    color: ${(props) => props.theme.primaryColorDark};
    font-size: 1.1rem;
    font-weight: 500;
    padding: 0 1em;
    background-color: ${(props) => props.theme.white};
    transition: transform ease-in-out 0.3s;
    ${tabletMediaQuery} {
        font-size: 0.8rem;
    }
    &:hover {
        transform: scale(1.1);
    }
`;

export const ButtonBack = styled.button`
    display: flex;
    gap: 10px;
    border-radius: 8px;
    align-items: center;
    border: none;
    background-color: ${(props) => props.theme.primaryColorLight};
    color: ${(props) => props.theme.white};
    margin-bottom: 1em;
    padding: 6px 15px;
`;

export const ContentIframe = styled.div`
    max-width: 1300px;
`;

export const ReactIframe = styled(Iframe)`
    width: 100%;
`;
export const Video = styled.video`
    width: 100%;
    height: auto;
`;
export const ContentVideo = styled.div`
    width: 100%;
    max-width: 1300px;
    overflow: hidden;
`;
export const ContentRepreEstilizada = styled.div`
    display: flex;
    justify-content: space-between;

    button {
        :first-child {
            border-top-left-radius: 8px;
        }
        :last-child {
            border-top-right-radius: 8px;
        }
    }
`;

export const ButtonEstilizada = styled.button<typeButton>`
    width: 100%;
    border-top: solid 1px #9ec0da;
    border-bottom: transparent;
    border-left: transparent;
    border-right: solid 2px #9ec0da;
    min-height: 40px;
    background-color: ${(props) => (props.active ? "#9EC0DA" : "#e7f0f5")};
    font-weight: 600;
    font-size: 1rem;
    color: #4d4d4d;
`;
export const WrapperAnimation = styled.div`
    display: flex;
    justify-content: center;
`;

import { BsDash } from "react-icons/bs";
import * as S from "./style";
import { useAccordionButton } from "react-bootstrap";
import { FaqAdd } from "assets/icons";

export default function CustomToggle({ eventKey, isOpen, onToggle }: any) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
        onToggle(eventKey);
    });

    return (
        <S.ButtonTottle type="button" onClick={decoratedOnClick}>
            {isOpen ? <BsDash size={30} /> : <FaqAdd />}
        </S.ButtonTottle>
    );
}

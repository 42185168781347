import IconLinkedin from "assets/icons/IconLinkedin.svg";
import IconFacebook from "assets/icons/IconFacebook.svg";
import IconInstagram from "assets/icons/IconInstagram.svg";
import IconEmail from "assets/icons/IconEmail.svg";
import { FooterType } from "types";

export const externalLinks: Array<FooterType> = [
    {
        id: 1,
        url: "https://www.linkedin.com/company/fastprobr/",
        icon: IconLinkedin,
    },
    {
        id: 2,
        url: "https://www.facebook.com/fastprobr/",
        icon: IconFacebook,
    },
    {
        id: 3,
        url: "https://www.instagram.com/fastprobr/",
        icon: IconInstagram,
    },
    { id: 4, url: "mailto:arktools@fastprobr.com", icon: IconEmail },
];

import styled from "styled-components";
import { mobileMediaQuery } from "styles/themes/mediaQueries";

export const ContainerTitle = styled.div`
    margin: 9em 0px 4em;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Container = styled.div`
    padding: 0 22em;

    @media (max-width: 1440px) {
        padding: 0 10em;
    }

    @media (max-width: 680px) {
        padding: 0 4em;
    }

    ${mobileMediaQuery} {
        padding: 0 2em;
    }
`;

import { Loading, Title } from "components";
import { getDisplayService } from "hooks";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as S from "./style";

export default function Service() {
    const router = useNavigate();
    const {
        isLoadingServiceDisplay,
        listServiceDisplay,
        refetchServiceDisplay,
    } = getDisplayService();

    useEffect(() => {
        async function response() {
            await refetchServiceDisplay();
        }
        response();
    }, []);
    const idServices = [
        "65f2f8bc00cadc9a3738fb88",
        "65f300eb89312f2574d2c6b7",
        "66212653531a71dceb04ca73",
        "65f301b589312f2574d2c6ba",
        "65f3014489312f2574d2c6b9",
        "65f3010889312f2574d2c6b8",
    ];
    return (
        <S.Wrapper>
            <Title title="Conheça nossos serviços" />
            <S.Service>
                {isLoadingServiceDisplay ? (
                    <Loading size={20} color="#487B9D" />
                ) : (
                    listServiceDisplay?.map((item: any) => {
                        return (
                            idServices.includes(item?.id) && (
                                <S.ServiceContent
                                    key={item?.id}
                                    onClick={() =>
                                        router(`/detailService/${item?.id}`)
                                    }
                                >
                                    <S.ServiceImage src={item?.imageDisplay} />

                                    <S.ServiceTitleContent>
                                        <S.ServiceDescription>
                                            <S.ServiceDescriptionOpacity>
                                                {item?.descriptionOpacity}
                                            </S.ServiceDescriptionOpacity>
                                            <strong>
                                                Clique para saber mais
                                            </strong>
                                        </S.ServiceDescription>
                                        <S.ServiceTitle>
                                            {item?.title}
                                        </S.ServiceTitle>
                                    </S.ServiceTitleContent>
                                </S.ServiceContent>
                            )
                        );
                    })
                )}
            </S.Service>
        </S.Wrapper>
    );
}

import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";
import { tabletMediaQuery } from "styles/themes/mediaQueries";

export const CarouselContent = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
`;

export const StyledCarousel = styled(Carousel)`
    .carousel .slider .selected {
        width: 100%;
    }

    .carousel .slider .previous {
        width: 0;
    }
`;

export const CustomPrevArrow = styled.div`
    position: absolute;
    left: 1em;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: ${(props) => props.theme.white};
        padding: 5px;
        z-index: 1;
        transition: transform ease-in-out 0.3s;
        ${tabletMediaQuery} {
            height: 20px;
            width: 20px;
        }
        &:hover {
            cursor: pointer;
            transform: scale(1.1);
        }
    }
    svg {
        color: ${(props) => props.theme.primaryColorLight};
        height: 20px;
        width: 20px;
        ${tabletMediaQuery} {
            height: 12px;
            width: 12px;
        }
    }
`;
export const CustomNextArrow = styled.div`
    position: absolute;
    right: 1em;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: ${(props) => props.theme.white};
        padding: 5px;
        z-index: 1;
        transition: transform ease-in-out 0.3s;
        ${tabletMediaQuery} {
            height: 20px;
            width: 20px;
        }
        &:hover {
            transform: scale(1.1);
            cursor: pointer;
        }
    }
    svg {
        color: ${(props) => props.theme.primaryColorLight};
        height: 20px;
        width: 20px;
        ${tabletMediaQuery} {
            height: 12px;
            width: 12px;
        }
    }
`;

export const Image = styled.img`
    width: 100%;
    height: auto;
    ${tabletMediaQuery} {
        border-radius: 8px;
    }
`;
export const WrapperAnimation = styled.div`
    display: flex;
    justify-content: center;
`;
export const Video = styled.video`
    width: 100%;
    height: auto;
`;
export const ContentVideo = styled.div`
    width: 100%;
    max-width: 1300px;
    overflow: hidden;
`;

export default () => (
    <svg
        width="27"
        height="28"
        viewBox="0 0 27 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.3448 19.4961L13.9586 16.1098C13.8348 15.9861 13.6886 15.9636 13.6098 15.9636C13.5311 15.9636 13.3848 15.9861 13.2611 16.1098L9.86355 19.5073C9.48106 19.8898 8.8848 20.5086 6.89355 20.5086L11.0673 24.6711C11.7001 25.3031 12.5579 25.6581 13.4523 25.6581C14.3467 25.6581 15.2045 25.3031 15.8373 24.6711L20.0223 20.4973C18.9986 20.4973 18.1436 20.2948 17.3448 19.4961ZM9.86355 9.31484L13.2611 12.7123C13.3511 12.8023 13.4861 12.8586 13.6098 12.8586C13.7336 12.8586 13.8686 12.8023 13.9586 12.7123L17.3223 9.34859C18.1211 8.51609 19.0323 8.32484 20.0561 8.32484L15.8711 4.15109C15.2382 3.51907 14.3804 3.16406 13.4861 3.16406C12.5917 3.16406 11.7339 3.51907 11.1011 4.15109L6.9273 8.31359C8.90731 8.31359 9.51481 8.96609 9.86355 9.31484Z"
            fill="black"
        />
        <path
            d="M23.7936 11.9927L21.2624 9.45023H19.8449C19.2374 9.45023 18.6299 9.69773 18.2136 10.1365L14.8386 13.5115C14.5236 13.8265 14.1074 13.984 13.6911 13.984C13.2625 13.9779 12.8522 13.809 12.5436 13.5115L9.14613 10.1027C8.71863 9.67523 8.13363 9.42773 7.51488 9.42773H5.86113L3.28488 12.0152C2.65285 12.648 2.29785 13.5059 2.29785 14.4002C2.29785 15.2946 2.65285 16.1524 3.28488 16.7852L5.86113 19.3727H7.52613C8.13363 19.3727 8.71863 19.1252 9.15738 18.6977L12.5549 15.3002C12.8699 14.9852 13.2861 14.8277 13.7024 14.8277C14.1186 14.8277 14.5349 14.9852 14.8499 15.3002L18.2361 18.6865C18.6636 19.114 19.2486 19.3615 19.8674 19.3615H21.2849L23.8161 16.819C24.4508 16.1748 24.8047 15.3055 24.8005 14.4012C24.7963 13.4969 24.4343 12.631 23.7936 11.9927Z"
            fill="black"
        />
    </svg>
);

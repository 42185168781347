import styled from "styled-components";
import { mobileMediaQuery } from "styles/themes/mediaQueries";

export const ContainerContext = styled.div`
    background-color: ${(props) => props.theme.darkBlue};
    padding: 3em 0 2em 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const ContainerLogo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const LogoArktools = styled.img`
    max-width: 233px;
    height: 44px;
    margin-bottom: 20px;
`;

export const List = styled.ul`
    list-style: none;
    display: flex;
    justify-content: center;
    gap: 50px;

    ${mobileMediaQuery} {
        gap: 20px;
        padding: 0;
    }
`;

export const ListItens = styled.li`
    color: ${(props) => props.theme.white};
    font-size: 1rem;
    :hover {
        cursor: pointer;
    }
`;

export const ContainerIcons = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-left: 30px;

    ${mobileMediaQuery} {
        gap: 10px;
        margin: 0;
    }
`;

export const Icon = styled.img``;

export const LightFooter = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1em 5em;
    align-items: center;
    font-size: 01rem;
    flex-wrap: wrap;
    background-color: ${(props) => props.theme.white};
    ${mobileMediaQuery} {
        gap: 1em;
        padding: 10px;
        align-items: flex-start;
    }
`;
export const FlexInformation = styled.div`
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
`;

export const FlexIconSpan = styled.div`
    display: flex;
    align-items: center;
    gap: 2px;
    span {
        font-weight: 500;
        font-size: 0.8rem;
    }
`;

export const LogoFast3d = styled.img``;

export const Span = styled.span`
    font-weight: 500;
    font-size: 0.8rem;

    ${mobileMediaQuery} {
        margin-left: 0;
    }
`;

// export const ContainerFooterLight = styled.div`
//     display: flex;
//     align-items: center;
//     padding-right: 10px;
// `;

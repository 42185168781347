import styled from "styled-components";
import RectangleBlue from "assets/images/RectangleBlue.png"; // Importe a imagem aqui
import contatoarte from "assets/images/contatoarte.png"; // Importe a imagem aqui
import { mobileMediaQuery } from "styles/themes/mediaQueries";

export const ContainerBlue = styled.div`
    background-image: url(${RectangleBlue});
    background-size: 100%;
    background-repeat: no-repeat;
`;
export const ContainerGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 1024px) {
        background-image: none;
        grid-template-columns: 1fr;
    }

    @media (min-width: 1025px) {
        height: 100vh;
    }
`;

export const LeftColumn = styled.div``;
export const RightColumn = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Title = styled.h2`
    font-size: 2rem;
    margin: 0;
    font-weight: bold;
    text-align: start;

    @media (max-width: 1024px) {
        margin-top: 3em;
    }

    ${mobileMediaQuery} {
        font-size: 1rem;
        font-weight: 500;
    }
`;

export const Text = styled.p`
    margin-top: 1em;
    font-size: 1.25rem;
    font-weight: 400;

    @media (max-width: 1024px) {
        background: ${(props) => props.theme.mediumBlue};
        text-align: center;
        padding: 4em;
    }

    ${mobileMediaQuery} {
        font-size: 0.875rem;
        line-height: 17.5px;
    }
`;
export const ContentDescription = styled.div`
    max-width: 333px;
`;
export const LeftContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: 13em;
    color: ${(props) => props.theme.white};

    @media (max-width: 1024px) {
        margin-top: 3em;
        width: 100%;
        margin: 3em 0 0 0;
    }
`;

export const ContainerTitle = styled.div`
    width: 70%;

    ${mobileMediaQuery} {
        display: none;
    }
`;

export const Image = styled.img`
    src: url(${contatoarte});
    width: 462px;
    height: 260px;
    @media (max-width: 1024px) {
        width: 336px;
    }
`;

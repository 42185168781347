import styled from "styled-components";
import { tabletMediaQuery } from "styles/themes/mediaQueries";

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin: 12em 0 10em 0;
`;
export const Padding = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
`;

export const ContentForm = styled.div`
    width: 50%;
    padding: 5px 20px;
    ${tabletMediaQuery} {
        width: 100%;
    }
`;

export const Register = styled.div`
    display: flex;
    grid-gap: 5px;
    align-items: center;
`;
export const Form = styled.form`
    width: 80%;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    margin-top: 0.5em;
    ${tabletMediaQuery} {
        width: 70%;
    }
`;

export const ContentTitle = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const Title = styled.h3`
    font-size: 1.25rem;
    margin: 0 0 0.5em 0;
    font-weight: 700;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 380px;
    grid-gap: 10px;
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.primaryColor};
    box-shadow: 2.680000066757202px 2.680000066757202px 6.700000286102295px 0px
        #00000040;
    padding: 3em 10px;
`;

export const Span = styled.span`
    font-size: 0.8rem;
    color: ${(props) => props.theme.black};
    font-weight: 400;
    max-width: 210px;
    text-align: justify;
`;

export const Logo = styled.div``;

export const Button = styled.div`
    display: flex;
    justify-content: center;
    button {
        width: 118px;
        height: 39px;
        font-size: 1rem;
        font-weight: 500;
        text-align: center;
        padding: 0;
    }
`;

export const RegisterPolitica = styled.div`
    display: flex;
    gap: 1em;
    flex-direction: column;
    justify-content: center;
`;

export const TitlePolitica = styled.span`
    font-size: 0.7rem;
`;

export const Politica = styled.div`
    display: flex;
    gap: 0.5em;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    input {
        margin-top: 0.2em;
    }
    strong {
        color: #1f67b1;
        font-weight: 500;
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
`;

export const RegisterFooter = styled.div`
    display: flex;
    gap: 1em;
    font-size: 0.8rem;
    justify-content: center;
    strong {
        transition: transform 0.3s ease-in-out;
        color: #1f67b1;
        font-weight: 400;
        cursor: pointer;
        &:hover {
            transform: scale(1.1);
        }
    }
`;

export default () => (
    <svg
        width="120"
        height="120"
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="0.200195"
            y="0.5"
            width="119"
            height="119"
            rx="8"
            fill="url(#paint0_linear_1152_1722)"
        />
        <path
            d="M58.1667 64.5837C58.1667 67.1137 62.2715 69.167 67.3333 69.167C72.3952 69.167 76.5 67.1137 76.5 64.5837M43.5 55.417C43.5 57.947 47.6048 60.0003 52.6667 60.0003C54.731 60.0003 56.6358 59.6593 58.1667 59.0837M43.5 61.8337C43.5 64.3637 47.6048 66.417 52.6667 66.417C54.731 66.417 56.634 66.076 58.1667 65.5003M67.3333 61.8337C62.2715 61.8337 58.1667 59.7803 58.1667 57.2503C58.1667 54.7203 62.2715 52.667 67.3333 52.667C72.3952 52.667 76.5 54.7203 76.5 57.2503C76.5 59.7803 72.3952 61.8337 67.3333 61.8337Z"
            stroke="white"
            strokeWidth="2.75"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M43.5 48.083V68.2497C43.5 70.7797 47.6048 72.833 52.6667 72.833C54.731 72.833 56.634 72.492 58.1667 71.9163M58.1667 71.9163V57.2497M58.1667 71.9163C58.1667 74.4463 62.2715 76.4997 67.3333 76.4997C72.3952 76.4997 76.5 74.4463 76.5 71.9163V57.2497M61.8333 53.583V48.083"
            stroke="white"
            strokeWidth="2.75"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M52.6667 52.6667C47.6048 52.6667 43.5 50.6133 43.5 48.0833C43.5 45.5533 47.6048 43.5 52.6667 43.5C57.7285 43.5 61.8333 45.5533 61.8333 48.0833C61.8333 50.6133 57.7285 52.6667 52.6667 52.6667Z"
            stroke="white"
            strokeWidth="2.75"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <defs>
            <linearGradient
                id="paint0_linear_1152_1722"
                x1="60.527"
                y1="2.61422"
                x2="58.854"
                y2="119.488"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#487B9D" />
                <stop offset="0.447917" stop-color="#79AAC1" />
                <stop offset="1" stop-color="#A3D1E0" />
            </linearGradient>
        </defs>
    </svg>
);

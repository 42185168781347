import styled from "styled-components";

export const Subtitle = styled.span`
    font-size: 1.5rem;
    font-weight: bold;
    color: ${(props) => props.theme.black};
`;

export const Wrapper = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-top: 8em;
    margin-bottom: 3em;
    gap: 2em;
`;

export const Image360 = styled.div`
    text-align: start;
`;

export const Title360 = styled.span`
    font-size: 1rem;
    color: ${(props) => props.theme.black};
    font-weight: 700;
`;

export const Hiring = styled.div`
    text-align: start;
    border-radius: 8px;
    border: 1px solid #d0d0d0;
    box-shadow: grey 0px 0px 4px 0px;
    padding: 1.2em 1.5em;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    strong {
        font-weight: 400;
    }
`;

export const HiringTitle = styled.span`
    font-size: 1rem;
    color: ${(props) => props.theme.black};
    font-weight: 700;
`;

export const RequirementsSubTitle = styled(HiringTitle)`
    margin-left: 0.5em;
    margin-top: 1em;
`;
export const Hr = styled.hr`
    color: #a0a0a0;
    margin: 0;
`;
export const Requirements = styled.div`
    margin-top: 0.5em;
    display: flex;
    flex-direction: column;
    gap: 0.4em;
`;

export const WrapperForm = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.8em;
`;

export const ContentInfo = styled.div`
    display: flex;
    flex-direction: column;

    input {
        max-width: 50%;
        @media (min-width: 1900px) {
            max-width: 35%;
        }
    }
`;

export const FormTitle = styled(HiringTitle)`
    font-weight: 600;
`;

export const Banner = styled.div`
    display: flex;
    justify-content: center;
`;
export const BannerImg = styled.img`
    width: 100%;
    border-radius: 8px;
`;

export const ContentVideo = styled.div`
    width: 100%;
    max-width: 1300px;
    overflow: hidden;
`;

export const Video = styled.video`
    width: 100%;
    height: auto;
    border-radius: 8px;
`;

export const WrapperAnimation = styled.div`
    display: flex;
    justify-content: center;
`;
export const Date = styled.div`
    input {
        margin-top: 0.5em;
        width: 150px;
    }
`;

export const ContentFile = styled.div`
    margin-top: 1em;
`;
export const ConfirmFile = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;

    label {
        font-size: 1rem;
        font-weight: 600;
    }
`;
export const ContentInfoArea = styled(ContentInfo)`
    textarea {
        height: 130px;
    }
`;
export const Amount = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    gap: 1em;
    span {
        font-size: 1.25rem;
        font-weight: 700;
        display: flex;
    }
`;

export const ContentButton = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1em;
`;
export const ButtonCancelar = styled.button`
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.primaryColorLight};
    color: ${(props) => props.theme.primaryColorLight};
    padding: 0.3em 1em;
    font-size: 1.1rem;
    font-weight: 500;
`;
export const ButtonConfirmar = styled.button`
    border-radius: 8px;
    font-weight: 500;
    color: ${(props) => props.theme.white};
    font-size: 1.1rem;
    padding: 0.3em 1em;
    border: none;
    background-color: ${(props) => props.theme.primaryColorLight};
`;
export const ContentEstiliza = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1em;
    img {
        width: 255px;
        height: 90px;
        object-fit: cover;
        border-radius: 8px;
    }
`;

export const InputEstiliza = styled.div`
    display: flex;
    gap: 1em;
    flex-direction: row;
    label {
        font-size: 0.7rem;
    }
`;

export const WrapperEstilizacao = styled.div<{ justifyContent?: "flex-start" }>`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: ${({ justifyContent }) =>
        justifyContent ? justifyContent : "space-between"};
    gap: 1em;
`;

import SquareBlue4 from "assets/images/retangleRigth.png";
import * as S from "./style";
import { ReactElement } from "react";

export default function Rigth({ SquareUpTop2 = "23em" }: any): ReactElement {
    return (
        <S.RightColumn>
            <S.ContainerSquareDown2 SquareUpTop2={SquareUpTop2}>
                <S.SquareBlue src={SquareBlue4} />
            </S.ContainerSquareDown2>
        </S.RightColumn>
    );
}

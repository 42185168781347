import styled from "styled-components";

export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 0.4fr 1.5fr;
    grid-gap: 10px;
`;

export const Content = styled.div`
    margin-top: 9em;
`;

import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import * as S from "./style";

type typeBanner = {
    bannerList?: Array<string>;
    videoList?: boolean;
};
export default function ServiceCarousel({ bannerList, videoList }: typeBanner) {
    return (
        <S.CarouselContent>
            <S.StyledCarousel
                showArrows={true}
                showStatus={false}
                showIndicators={false}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                        <S.CustomPrevArrow
                            onClick={onClickHandler}
                            title={label}
                        >
                            <div>
                                <BsChevronLeft />
                            </div>
                        </S.CustomPrevArrow>
                    )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                        <S.CustomNextArrow
                            onClick={onClickHandler}
                            title={label}
                        >
                            <div>
                                <BsChevronRight />
                            </div>
                        </S.CustomNextArrow>
                    )
                }
            >
                {bannerList?.map((CarouselItem: string, index: number) => {
                    if (videoList) {
                        return (
                            <S.WrapperAnimation key={index}>
                                <S.ContentVideo>
                                    <S.Video controls>
                                        <source
                                            src={CarouselItem}
                                            type="video/mp4"
                                        />
                                        Your browser does not support the video
                                        tag.
                                    </S.Video>
                                </S.ContentVideo>
                            </S.WrapperAnimation>
                        );
                    } else {
                        return <S.Image key={index} src={CarouselItem} />;
                    }
                })}
            </S.StyledCarousel>
        </S.CarouselContent>
    );
}

import styled, { css } from "styled-components";
type Square = {
    SquareUpTop2?: string;
    SquareUpTop1?: string;
};
export const SquareBlue = styled.img`
    @media (max-width: 1024px) {
        display: none;
    }
`;

const baseSquareStyles = css`
    @media (max-width: 1024px) {
        display: none;
    }
`;

export const ContainerSquareUp2 = styled.div<Square>`
    position: absolute;
    top: ${(props) => props.SquareUpTop2};
    left: -1em;
    ${baseSquareStyles}
`;

export const LeftColumn = styled.div``;

export const ContainerSquareUp1 = styled.div<Square>`
    position: absolute;
    top: ${(props) => props.SquareUpTop1};
    left: 0;
    ${baseSquareStyles}
`;

export const RightColumn = styled.div`
    /* position: relative; */
`;

export const LeftContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    width: 35%;
    margin: 18em 2em 0px 9em;
    color: ${(props) => props.theme.white};

    @media (max-width: 1024px) {
        margin-top: 3em;
        width: 100%;
        margin: 3em 0 0 0;
    }
`;

export const ContainerSquareDown2 = styled.div<Square>`
    position: absolute;
    top: ${(props) => props.SquareUpTop2};
    right: 0;
    z-index: -1;
    ${baseSquareStyles}
`;

import Antes from "assets/images/Antes.png";
import Depois from "assets/images/Depois.png";
import * as S from "./style";
import { useState } from "react";
import { After } from "assets/icons";

export default function AfterBefore() {
    const [hover, setHover] = useState(false);
    return (
        <S.WrapperAfterBefore>
            <S.Instruction>
                <span>
                    Clique e interaja com a seta abaixo para visualizar o antes
                    e depois da renderização{" "}
                </span>
            </S.Instruction>
            <S.ContainerAfterBefore>
                <S.ImgAfter hover={hover}>
                    <img src={Antes} alt="antes" />
                    <S.Button onClick={() => setHover(!hover)}>
                        <After />
                    </S.Button>
                </S.ImgAfter>
                <S.ImgBefore hover={hover}>
                    <img src={Depois} alt="depois" />

                    {/* <S.ButtonBefore
                        onClick={() => setHover(!hover)}
                    ></S.ButtonBefore> */}
                </S.ImgBefore>
                <S.ImgBeforeZIndex hover={hover}>
                    <img src={Depois} alt="Depois" />
                </S.ImgBeforeZIndex>
            </S.ContainerAfterBefore>
        </S.WrapperAfterBefore>
    );
}

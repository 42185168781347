import Slider1 from "assets/images/project1.png";
import Slider2 from "assets/images/project2.png";
import Slider3 from "assets/images/project3.png";
import Banner from "assets/images/projectbanner.png";
import { Projects } from "types";
export const ProjectsItems: Array<Array<Projects>> = [
    [
        {
            id: 1,
            url: Slider1,
            title: "Projeto Parque Urban",
            description:
                "O projeto Parque Urban é um apartamento de três suítes que busca explorar ao máximo a vista em todos os ambientes da moradia. Conta com uma ampla área de estar conjunta da cozinha e área de lazer, integrando os espaços para melhor acolher a família. Um ambiente para trabalho também está presente na área social, com portas de correr caso haja a necessidade de um local mais reservado. A área mais privada do apartamento, os quartos, é dividida com um acesso privativo através de um corredor fechado que leva até a suíte master do casal, suíte para filha e uma suíte para visitas ou sala de TV mais reclusa.",
            banner: Banner,
            thumbnail: Slider1,
            detaildProject: {
                video: "https://arktools.s3.sa-east-1.amazonaws.com/Project/Projeto01VideoParque.mp4",
            },
        },
        {
            id: 2,
            url: Slider2,
            title: "Projeto Parque Urban",
            description:
                "Lorem ipsum dolor sit amet. In velit tempore sed velit voluptatem et quia dolores. Id molestias autem et voluptatem porro et nesciunt possimus quo voluptatem quis.",
            banner: Banner,
            thumbnail: Slider2,
        },
        {
            id: 3,
            url: Slider3,
            title: "Projeto Parque Urban",
            description:
                "Lorem ipsum dolor sit amet. In velit tempore sed velit voluptatem et quia dolores. Id molestias autem et voluptatem porro et nesciunt possimus quo voluptatem quis.",
            banner: Banner,
            thumbnail: Slider3,
        },
    ],
    [
        {
            id: 4,
            url: Slider2,
            title: "Projeto Parque Urban",
            description:
                "Lorem ipsum dolor sit amet. In velit tempore sed velit voluptatem et quia dolores. Id molestias autem et voluptatem porro et nesciunt possimus quo voluptatem quis.",
            banner: Banner,
            thumbnail: Slider1,
        },
        {
            id: 5,
            url: Slider3,
            title: "Projeto Parque Urban",
            description:
                "Lorem ipsum dolor sit amet. In velit tempore sed velit voluptatem et quia dolores. Id molestias autem et voluptatem porro et nesciunt possimus quo voluptatem quis.",
            banner: Banner,
            thumbnail: Slider2,
        },
        {
            id: 6,
            url: Slider3,
            title: "Projeto Parque Urban",
            description:
                "Lorem ipsum dolor sit amet. In velit tempore sed velit voluptatem et quia dolores. Id molestias autem et voluptatem porro et nesciunt possimus quo voluptatem quis.",
            banner: Banner,
            thumbnail: Slider3,
        },
    ],
];

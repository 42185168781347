import { FormContact } from "components";
import * as S from "./style";
import contatoarte from "assets/images/contatoarte.png"; // Importe a imagem corretamente

export default function Contact() {
    return (
        <S.ContainerGrid>
            <S.ContainerBlue>
                <S.LeftColumn>
                    <S.LeftContent>
                        <S.ContentDescription>
                            <S.Title>Como te ajudar?</S.Title>
                            <S.Text>
                                Para mais informações, deixe sua mensagem e
                                entraremos em contato com você em breve!
                            </S.Text>
                        </S.ContentDescription>
                        <S.Image src={contatoarte} alt="Contato Arte" /> {}
                    </S.LeftContent>
                </S.LeftColumn>
            </S.ContainerBlue>
            <S.RightColumn>
                <S.ContainerTitle>
                    <S.Title>Nos contate:</S.Title>
                </S.ContainerTitle>
                <FormContact />
            </S.RightColumn>
        </S.ContainerGrid>
    );
}

import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { AuthContent } from "contexts/Auth.context";
import * as S from "./style";
import { Input, Button, Loading } from "components";
import { LoginSchema } from "schemas";
import { DataProps } from "types";
import Logo from "assets/icons/LogoArktoolsBlue.svg";

export default function Login() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<DataProps<typeof LoginSchema>>({
        mode: "onBlur",
        resolver: zodResolver(LoginSchema),
    });

    const router = useNavigate();
    const { PostAutentication, isloading } = useContext(AuthContent);

    const submitForm = async (data: any) => {
        const response = await PostAutentication(data);
        if (response?.code) return;
        router("/orders");
    };
    return (
        <S.Wrapper>
            <S.Container>
                <S.Logo>
                    <img src={Logo} alt={"Logo arktools"} />
                </S.Logo>
                <S.ContentTitle>
                    <S.Title>Bem-vindo(a) de volta!</S.Title>
                </S.ContentTitle>
                <S.Form onSubmit={handleSubmit(submitForm)}>
                    <S.Padding>
                        <Input.Text
                            {...register("email")}
                            type="text"
                            name="email"
                            placeholder="E-mail"
                            autoComplete="email"
                            helperText={errors?.email?.message}
                        />
                        <Input.Text
                            {...register("password")}
                            type={"password"}
                            name="password"
                            placeholder="Senha"
                            autoComplete="current-password"
                            helperText={errors?.password?.message}
                        />
                    </S.Padding>
                    <S.ForgotPassowrd href="#" rel="nooreferrer noopener">
                        Esqueceu a senha?
                    </S.ForgotPassowrd>
                    <S.Button>
                        <Button.Master
                            disabled={isloading ? true : false}
                            type="submit"
                        >
                            {isloading ? <Loading size={8} /> : "Entrar"}
                        </Button.Master>
                    </S.Button>
                </S.Form>
            </S.Container>
        </S.Wrapper>
    );
}

import styled from "styled-components";
import { mobileMediaQuery } from "styles/themes/mediaQueries";

export const Section = styled.section`
    padding: 2em;

    ${mobileMediaQuery} {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0;
    }
`;

export const ContainerTitle = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 2em;
`;

export const ContainerSubtitle = styled.div`
    display: flex;
    justify-content: center;

    @media (max-width: 599px) {
        text-align: center;
    }
`;

export const ContainerGrid = styled.div`
    display: grid;
    grid-template-columns: 2fr 3fr;
    margin-top: 3em;
    align-items: start;

    @media (max-width: 1180px) {
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
    }
`;

export const ContainerFraction = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
`;

export const ImagePrincipal = styled.img`
    width: 90%;
    height: auto;
    object-fit: cover;

    @media (max-width: 1180px) {
        width: 600px;
        height: 600px;
        margin: auto;
        border-radius: 5px;
    }

    @media (max-width: 700px) {
        width: 400px;
        height: 400px;
    }

    @media (max-width: 599px) {
        width: 280px;
        max-height: 200px;
        margin: auto;
        border-radius: 5px;
    }

    @media (max-width: 300px) {
        width: 230px;
        height: 180px;
        margin: auto;
        border-radius: 5px;
    }
`;

export const ContainerFractionRigth = styled.div`
    gap: 20px;
    display: flex;
    flex-direction: column;
`;

export const ContainerFlex = styled.div`
    display: flex;
    gap: 10px;

    @media (max-width: 700px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

export const SmallImage = styled.img`
    ${mobileMediaQuery} {
        width: 280px;
        max-height: 200px;
        margin: auto;
        border-radius: 3px;
    }

    @media (max-width: 300px) {
        width: 230px;
        max-height: 180px;
    }
`;

export const DivTitleText = styled.div`
    padding-right: 20px;
    text-align: left;

    @media (max-width: 1250px) {
        padding: 0;
    }

    ${mobileMediaQuery} {
        text-align: start;
        padding: 10px 30px;
    }
`;

export const Title = styled.h4`
    font-size: 1.25rem;
    font-weight: 700;
    margin: 0;

    ${mobileMediaQuery} {
        font-size: 1rem;
    }
`;

export const Text = styled.p`
    font-size: 1rem;

    ${mobileMediaQuery} {
        font-size: 0.875rem;
    }
`;

export const ContainerButton = styled.div`
    ${mobileMediaQuery} {
        text-align: center;
    }
`;

import "aframe";
declare global {
    namespace JSX {
        interface IntrinsicElements {
            "a-scene": any;
            "a-assets": any;
            "a-sky": any;
            "a-camera": any;
            "a-cursor": any;
            "a-animation": any;
        }
    }
}

type typeImage360 = {
    url: string;
};
export default function Image360({ url }: typeImage360) {
    return (
        <a-scene
            embedded
            style={{
                width: "100%",
                height: "600px",
                margin: "auto",
            }}
        >
            <a-assets>
                <img id="texture" src={url} />
            </a-assets>
            <a-sky id="image-360" radius="10" src="#texture"></a-sky>
            <a-camera fov="80" position="0 0 0">
                <a-cursor id="cursor">
                    <a-animation
                        begin="click"
                        easing="ease-in"
                        attribute="scale"
                        fill="backwards"
                        from="0.1 0.1 0.1"
                        to="1 1 1"
                        dur="150"
                    ></a-animation>
                    <a-animation
                        begin="cursor-fusing"
                        easing="ease-in"
                        attribute="scale"
                        from="1 1 1"
                        to="0.1 0.1 0.1"
                        dur="1500"
                    ></a-animation>
                </a-cursor>
            </a-camera>
        </a-scene>
    );
}

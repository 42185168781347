import styled from "styled-components";

export const Container = styled.div`
    padding: 0 8em;

    @media (max-width: 850px) {
        padding: 0 4em;
    }

    @media (max-width: 470px) {
        padding: 0 2em;
    }
`;

import * as S from "./style";
import Title from "components/Title";
import { ChooseItems } from "./choose";
import { ChooseType } from "types";
import { RetangleBlue } from "components/RetangleBlue";

export default function ChooseUs() {
    return (
        <S.Section>
        <S.ContainerTitle>
            <S.FraseAcimaTitulo>
                {/* Sua frase aqui */}
                "Qualquer dúvida ou necessidade de informação, nossa equipe estará a disposição durante todo o processo."
            </S.FraseAcimaTitulo>
            <Title title="Por que nos escolher?" />
        </S.ContainerTitle>
        <S.ContainerGrid>
            {ChooseItems.map((item: ChooseType) => (
                <S.ContainerContext key={item.id}>
                    <S.ContainerText>
                        <S.TitleContext>{item.title}</S.TitleContext>
                        <S.Text>{item.description}</S.Text>
                    </S.ContainerText>
                    <S.ContainerImage>{item.icon}</S.ContainerImage>
                    <S.ContainerText>
                        <S.DescriptionContextMobile>
                            {item.description}
                        </S.DescriptionContextMobile>
                    </S.ContainerText>
                </S.ContainerContext>
            ))}
        </S.ContainerGrid>
        <RetangleBlue.Rigth />
    </S.Section>

    );
}

import * as S from "./style";

export default function Tooltip() {
    return (
        <S.WrapperTooltip>
            <span>
                <strong>Preparação:</strong> Indica a fase inicial onde ocorre a
                preparação do projeto.
            </span>

            <span>
                <strong>Produção:</strong> Indica a etapa de execução real, onde
                o projeto está em andamento.
            </span>

            <span>
                <strong>Verificação:</strong> Indica a fase de verificação e
                controle de qualidade do projeto antes da conclusão.
            </span>

            <span>
                <strong>Concluído:</strong> Indica a conclusão bem-sucedida do
                projeto, onde não há mais ações necessárias.
            </span>
        </S.WrapperTooltip>
    );
}

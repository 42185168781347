type BannerEstilizada = {
    title: string;
    url: string;
    interaction: string;
};

export const ButtonRepreEstilizada: Array<BannerEstilizada> = [
    {
        title: "Colagem",
        url: "https://arktools.s3.sa-east-1.amazonaws.com/Service/detailPage/representacaoEstilizada/ImagemRepresentacaoEstilizada_Colagem.png",
        interaction:
            "Colagem digital com fotografias, inserindo na representação elementos que facilitem a compreensão do usuário como imagens de vegetação e pessoas​",
    },
    {
        title: "Isométrica",
        url: "https://arktools.s3.sa-east-1.amazonaws.com/Service/detailPage/Isometricabanner.png",
        interaction:
            "Estilo de visualização com ângulo padrão, dando foco à volumetrias e setores do projeto, menos detalhes e acabamento mais clean​",
    },
    {
        title: "ilustração",
        url: "https://arktools.s3.sa-east-1.amazonaws.com/Service/detailPage/Ilustracaobanner.png",
        interaction: "Ilustração digital de cores chapadas e mais elaboradas",
    },
    {
        title: "Croqui",
        url: "https://arktools.s3.sa-east-1.amazonaws.com/Service/detailPage/croquibanner.png",
        interaction: "Ilustração digital no estilo de croquis arquitetônicos​",
    },
    {
        title: "Representações com animação",
        url: "https://arktools.s3.sa-east-1.amazonaws.com/Service/detailPage/representacaoEstilizada/AnimationsComplete.mp4",
        interaction:
            "Animação do projeto, com simulação de ambientes abertos e com chuva",
    },
];

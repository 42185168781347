import styled from "styled-components";
import {
    desktopMediaQuery,
    mobileMediaQuery,
} from "styles/themes/mediaQueries";

export const ContainerConfirmOrder = styled.div`
    margin-top: 6em;
    margin-bottom: 2em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;
export const Content = styled.div`
    border: 0.1px solid #e8e8e8;
    border-radius: 8px;
    box-shadow: 0px 3px 8px 0px #0000001a;
    padding: 2em;

    display: flex;
    flex-direction: column;
    gap: 1em;
    max-width: 700px;
    ${desktopMediaQuery} {
        width: 700px;
    }
    h2 {
        font-size: 1.25rem;
        font-weight: 700;
        margin-bottom: 1em;
    }
    strong {
        font-size: 1rem;
    }
`;
export const Header = styled.div`
    display: grid;
    gap: 1em;
    grid-template-columns: 1fr 1fr;
    border-bottom: 0.5px solid ${(props) => props.theme.primaryColor};
`;
export const ContentButton = styled.div`
    margin-top: 2em;
    display: flex;
    flex-direction: row;
    gap: 2em;
    align-items: center;
    justify-content: end;
    max-width: 700px;
    ${desktopMediaQuery} {
        width: 700px;
    }
`;
export const ContentData = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    color: #4d4d4d;
    font-weight: 500 !important;
`;
export const ButtonConfirmar = styled.button`
    border-radius: 8px;
    font-weight: 500;
    color: ${(props) => props.theme.white};
    font-size: 1.1rem;
    padding: 0.3em 1em;
    border: none;
    background-color: ${(props) => props.theme.primaryColorLight};
    ${mobileMediaQuery} {
        font-size: 0.8rem;
    }
`;
export const AddService = styled.button`
    border: none;
    background-color: transparent;
    color: #000;
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
    ${mobileMediaQuery} {
        font-size: 0.8rem;
    }
`;

import Imagem8K360 from "assets/images/8K360.png";
import { Container, Image360, Title } from "components";
import { getServiceID } from "hooks";
import { useEffect, useState } from "react";
import { BsArrowLeftCircle } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { GetService } from "utils/typeService";
import ServiceCarousel from "./carousel";
import { ButtonRepreEstilizada } from "./DatabannerEstilizada";
import * as S from "./style";
type BannerEstilizada = {
    title: string;
    url: string;
    interaction: string;
};
export default function DetailService() {
    const { id } = useParams();
    const [getService, setGetService] = useState<any>("");
    const [buttonEstilizada, setButtonEstilizada] = useState<string>(
        ButtonRepreEstilizada[0].title
    );
    const [bannerEstilizadaUrl, setBannerEstilizadaUrl] = useState<string>(
        ButtonRepreEstilizada[0].url
    );
    const [bannerEstilizadaInteraction, setBannerEstilizadaInteraction] =
        useState<string>(ButtonRepreEstilizada[0].interaction);
    const router = useNavigate();

    useEffect(() => {
        async function response() {
            const resp = await getServiceID(id!);
            setGetService(resp[0]);
        }
        response();
    }, []);

    return (
        <Container>
            <S.Container>
                <S.Content>
                    <S.ButtonBack onClick={() => router(-1)}>
                        <BsArrowLeftCircle size={20} color="#fff" /> Voltar
                    </S.ButtonBack>
                    <Title title={getService?.title} />
                    <S.SubTitle>{getService?.detailPage?.subtitle}</S.SubTitle>
                    <S.Text>{getService?.detailPage?.description}</S.Text>
                    <S.ContentImage>
                        {getService?.id ==
                            GetService()["representacaoEstilizada"] && (
                            <S.ContentRepreEstilizada>
                                {ButtonRepreEstilizada.map(
                                    (item: BannerEstilizada, index: number) => (
                                        <S.ButtonEstilizada
                                            key={index}
                                            active={
                                                buttonEstilizada == item.title
                                            }
                                            onClick={() => {
                                                setButtonEstilizada(item.title),
                                                    setBannerEstilizadaUrl(
                                                        item.url
                                                    ),
                                                    setBannerEstilizadaInteraction(
                                                        item.interaction
                                                    );
                                            }}
                                        >
                                            {item.title}
                                        </S.ButtonEstilizada>
                                    )
                                )}
                            </S.ContentRepreEstilizada>
                        )}
                        {getService?.id !=
                            GetService()["representacaoEstilizada"] &&
                            getService?.id != GetService()["animacoes"] && (
                                <S.ContentSpan
                                    active={
                                        getService?.id ==
                                        GetService()["representacaoEstilizada"]
                                    }
                                >
                                    <span>
                                        {getService?.id ==
                                        GetService()["representacaoEstilizada"]
                                            ? bannerEstilizadaInteraction
                                            : getService?.detailPage
                                                  ?.interaction}
                                    </span>
                                </S.ContentSpan>
                            )}
                        {getService?.id ==
                            GetService()["representacaoEstilizada"] && (
                            <S.ContentEstilizacaoSpan>
                                <div>
                                    <span>
                                        {getService?.id ==
                                        GetService()["representacaoEstilizada"]
                                            ? bannerEstilizadaInteraction
                                            : getService?.detailPage
                                                  ?.interaction}
                                    </span>
                                </div>
                            </S.ContentEstilizacaoSpan>
                        )}
                        {getService?.id == GetService()["imagensRealista"] ||
                        getService?.id == GetService()["animacoes"] ? (
                            <ServiceCarousel
                                videoList={
                                    getService?.id == GetService()["animacoes"]
                                }
                                bannerList={getService?.detailPage?.banner}
                            />
                        ) : getService?.id == GetService()["video2D"] ? (
                            <S.WrapperAnimation>
                                <S.ContentVideo>
                                    <S.Video controls loop autoPlay>
                                        <source
                                            src={getService?.detailPage?.banner}
                                            type="video/mp4"
                                        />
                                        Your browser does not support the video
                                        tag.
                                    </S.Video>
                                </S.ContentVideo>
                            </S.WrapperAnimation>
                        ) : getService?.id ==
                          GetService()["representacaoEstilizada"] ? (
                            buttonEstilizada ==
                            "Representações com animação" ? (
                                <S.WrapperAnimation>
                                    <S.ContentVideo>
                                        <S.Video controls loop autoPlay>
                                            <source
                                                src={bannerEstilizadaUrl}
                                                type="video/mp4"
                                            />
                                            Your browser does not support the
                                            video tag.
                                        </S.Video>
                                    </S.ContentVideo>
                                </S.WrapperAnimation>
                            ) : (
                                <S.Image src={bannerEstilizadaUrl} />
                            )
                        ) : getService?.id == GetService()["imagem360"] ? (
                            <Image360 url={Imagem8K360} />
                        ) : getService?.id == GetService()["passeioVirtual"] ? (
                            <S.ContentIframe>
                                <S.ReactIframe
                                    url="https://tour360.meupasseiovirtual.com/09640/282565/tourvirtual/index.html"
                                    title="Tour"
                                    width="700"
                                    height="600"
                                    allow="fullscreen"
                                ></S.ReactIframe>
                            </S.ContentIframe>
                        ) : (
                            <S.Image src={getService?.detailPage?.banner} />
                        )}
                    </S.ContentImage>
                    <S.ContentButton>
                        <S.ContainerButton>
                            <S.ButtonBlue onClick={() => router("/")}>
                                Ver planos
                            </S.ButtonBlue>
                            <S.ButtonWhite onClick={() => router("/contact")}>
                                Entre em contato
                            </S.ButtonWhite>
                        </S.ContainerButton>
                    </S.ContentButton>
                </S.Content>
            </S.Container>
        </Container>
    );
}

import styled from "styled-components";
import { mobileMediaQuery } from "styles/themes/mediaQueries";

export const Section = styled.section`
    margin-top: 3em;

    ${mobileMediaQuery} {
        margin-top: 3em;
    }
`;

export const ContainerTitle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const FraseAcimaTitulo = styled.div`
    text-align: center;
    font-size: 2.2em;
    margin-bottom: 60px;
    color: #333;
    font-weight: bold;
    width: 100%; /* Ajuste conforme necessário */
    max-width: 600px; /* Ajuste conforme necessário */
    margin: 0 auto 60px auto;
`;

export const TitleContext = styled.h3`
    text-transform: uppercase;
    margin: 0;
    font-weight: 700;
    font-size: 1.1rem;
`;

export const ContainerGrid = styled.div`
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 1em 4em;
    margin-top: 3em;

    @media (max-width: 1000px) {
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;
        gap: 3em;
        margin-top: 3em;
    }
`;
export const DescriptionContextMobile = styled.span`
    display: none;
    @media (max-width: 1000px) {
        display: block;
    }
`;

export const ContainerContext = styled.div`
    display: flex;
    gap: 1em;
    @media (min-width: 1000px) {
        &:nth-child(2n) {
            flex-direction: row-reverse;
            div {
                text-align: start;
            }
        }
    }
    @media (max-width: 1000px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const ContainerText = styled.div`
    text-align: end;
    @media (max-width: 1000px) {
        text-align: center;
    }
`;

export const Text = styled.span`
    font-size: 1rem;
    display: none;
    @media (min-width: 1000px) {
        display: block;
    }
`;

export const ContainerImage = styled.div``;

import styled from "styled-components";

export const Content = styled.div`
    margin-bottom: 1.1em;
`;
export const Title = styled.h4`
    font-size: 1.2rem;
    margin: 0;
`;
export const SubTitle = styled.h4`
    font-size: 1.3rem;
`;
export const PoliticaTitle = styled.h3`
    font-size: 2rem;
`;
export const ContentSubTitle = styled.span`
    font-size: 1.1rem;
`;
export const Container = styled.div`
    margin-top: 7em;
`;

export default () => (
    <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12.9504 5.35742H4.58241C3.81216 5.35742 3.18774 6.07104 3.18774 6.95133V13.327C3.18774 14.2073 3.81216 14.9209 4.58241 14.9209H12.9504C13.7207 14.9209 14.3451 14.2073 14.3451 13.327V6.95133C14.3451 6.07104 13.7207 5.35742 12.9504 5.35742Z"
            stroke="black"
            strokeWidth="1.19543"
        />
        <path
            d="M3.18774 7.74805L8.14301 10.5796C8.33659 10.6902 8.55002 10.7477 8.76642 10.7477C8.98283 10.7477 9.19626 10.6902 9.38984 10.5796L14.3451 7.74805"
            stroke="black"
            strokeWidth="1.19543"
        />
    </svg>
);

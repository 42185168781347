import { create } from "zustand";
interface Payment {
    typePayment: string;
    setTypePayment: (typePayment: string) => void;
    amount: number;
    setAmount: (amount: number) => void;
    isModalOpen: any;
    setIsModalOpen: any;
    modalContent: any;
    setModalContent: any;
    countItens: number;
    setCountItens: (amount: number) => void;
    total: any;
    setTotal: (valor: number) => void;
}

export const useStorePayment = create<Payment>((set) => ({
    typePayment: "card",
    setTypePayment: (type) => set({ typePayment: type }),
    amount: 0.0,
    setAmount: (valor) => set({ amount: valor }),
    isModalOpen: false,
    setIsModalOpen: (modal: boolean) => set({ isModalOpen: modal }),
    modalContent: null,
    setModalContent: (content: any) => set({ modalContent: content }),
    countItens: 0,
    setCountItens: (valor) => set({ countItens: valor }),
    total: 0,
    setTotal: (valor) => set({ total: valor }),
}));

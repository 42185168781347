export default () => (
    <svg
        width="54"
        height="51"
        viewBox="0 0 54 51"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12.9137 19.4857V34.6614C12.9137 36.17 13.5279 37.6179 14.6223 38.6838C15.726 39.7556 17.2046 40.3539 18.743 40.3514H34.2864C35.8326 40.3514 37.3144 39.7515 38.4071 38.6838C38.9459 38.1613 39.3746 37.5362 39.6681 36.8453C39.9615 36.1545 40.1137 35.4119 40.1157 34.6614V19.4857M12.9137 19.4857C11.3033 19.4857 10 18.2109 10 16.6398V12.8459C10 11.2748 11.3051 10 12.9137 10H40.1157C41.7261 10 43.0294 11.2748 43.0294 12.8459V16.6398C43.0294 18.2109 41.7243 19.4857 40.1157 19.4857M12.9137 19.4857H40.1157"
            stroke="white"
            stroke-width="2.67806"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M26.5146 25.1757L26.5146 34.1025"
            stroke="white"
            stroke-width="2.67806"
            stroke-miterlimit="10"
            stroke-linecap="round"
        />
        <path
            d="M30.6084 28.9336L27.0966 25.4218C27.0205 25.3447 26.9299 25.2835 26.83 25.2417C26.7301 25.1999 26.6228 25.1784 26.5145 25.1784C26.4062 25.1784 26.299 25.1999 26.1991 25.2417C26.0992 25.2835 26.0086 25.3447 25.9325 25.4218L22.4207 28.9336"
            stroke="white"
            stroke-width="2.67806"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

type typeService =
    | "imagensRealista"
    | "video2D"
    | "representacaoEstilizada"
    | "imagem360"
    | "passeioVirtual"
    | "animacoes";

export const GetService = (): { [key in typeService]: string } => {
    return {
        imagensRealista: "65f3014489312f2574d2c6b9",
        video2D: "65f3022a89312f2574d2c6bb",
        representacaoEstilizada: "65f301b589312f2574d2c6ba",
        imagem360: "65f300b489312f2574d2c6b6",
        passeioVirtual: "65f300eb89312f2574d2c6b7",
        animacoes: "66212653531a71dceb04ca73",
    };
};

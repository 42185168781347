export default () => (
    <svg
        width="65"
        height="65"
        viewBox="0 0 65 65"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M39.5312 10L55 17.7344V34.9829L52.1875 33.5767V20.8765L40.9375 26.5015V32.1704L38.125 33.5767V26.5015L26.875 20.8765V25.8643L24.0625 24.458V17.7344L39.5312 10ZM39.5312 24.0625L43.4204 22.1069L33.2471 16.2842L28.6108 18.6133L39.5312 24.0625ZM46.4526 20.6128L50.4517 18.6133L39.5312 13.1421L36.2573 14.79L46.4526 20.6128ZM35.3125 34.9829L32.5 36.3892V36.3672L24.0625 40.5859V50.5835L32.5 46.3428V49.5068L22.6562 54.4287L10 48.0786V33.2251L22.6562 26.897L35.3125 33.2251V34.9829ZM21.25 50.5835V40.5859L12.8125 36.3672V46.3428L21.25 50.5835ZM22.6562 38.147L30.7642 34.104L22.6562 30.0391L14.5483 34.104L22.6562 38.147ZM35.3125 38.125L45.1562 33.2031L55 38.125V49.7046L45.1562 54.6265L35.3125 49.7046V38.125ZM43.75 50.7812V44.0796L38.125 41.2671V47.9688L43.75 50.7812ZM52.1875 47.9688V41.2671L46.5625 44.0796V50.7812L52.1875 47.9688ZM45.1562 41.6406L50.4517 38.9819L45.1562 36.3452L39.8608 38.9819L45.1562 41.6406Z"
            fill="white"
        />
    </svg>
);

import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin: 12em 0 10em 0;
`;

export const Logo = styled.div`
    img {
        max-width: 233px;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    grid-gap: 10px;
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.primaryColor};
    box-shadow: 2.680000066757202px 2.680000066757202px 6.700000286102295px 0px
        #00000040;
    padding: 3em 10px;
`;

export const Span = styled.span`
    font-size: 0.8rem;
    color: ${(props) => props.theme.black};
    font-weight: 400;
    max-width: 315px;
    text-align: justify;

    strong {
        color: #1f67b1;
        font-weight: 500;
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
`;
export const ContentSpan = styled.div`
    margin-bottom: 1em;
    max-width: 315px;
`;
export const ContentTitle = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 1em;
`;

export const Title = styled.h3`
    font-size: 1.25rem;
    margin: 0 0 0.5em 0;
    font-weight: 700;
`;

import { api } from "api/api";
import { useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

export function useGetChat() {
    const [enable] = useState<boolean>(false);
    const {
        data: listChat,
        refetch: refetchListChat,
        isFetching: isLoadingListChat,
    } = useQuery(["listChat"], () => api("chat"), {
        enabled: enable,
    });
    return {
        listChat,
        refetchListChat,
        isLoadingListChat,
    };
}

export function usePostMessage() {
    async function postMessage(message: any) {
        try {
            const response = await api("chat/message", {
                method: "POST",
                data: JSON.stringify({
                    senderId: message.senderId[0],
                    recipientId: message.recipientId[0],
                }),
            });
            return response.messages;
        } catch (error) {
            toast.error("Ops ocorreu um erro!");
        }
    }
    return { postMessage };
}

import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";

export const StyledCarousel = styled(Carousel)`
    display: flex;
    justify-content: center;
    align-items: center;

    .carousel .slide {
        min-width: 33.33% !important;
    }

    @media (max-width: 1024px) {
        .carousel .slide {
            min-width: 100% !important;
        }
    }

    @media (min-width: 1025px) and (max-width: 1250px) {
        .carousel .slide {
            min-width: 50% !important;
        }
    }
`;

export const ContainerTitleSubtitle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
`;

export const CarouselSlide = styled.div`
    display: flex;
    cursor: pointer;
    margin-top: 7em;
    margin-bottom: 10em;
    border-radius: 10px;
    width: 95%;
    height: 382px;
    justify-content: center;
`;

export const ContainerFlex = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 312px;
    height: 370px;
    border-radius: 10px;
    background-color: ${(props) => props.theme.offWhite};
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
`;

export const Image = styled.img`
    top: -5em;
    position: absolute;
    border-radius: 50%;
    width: 149px !important;

    height: 149px;
`;

export const ContainerText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 550px;
    overflow-y: auto;
`;

export const Especialidade = styled.span`
    font-weight: 400;
    color: #686868;
    font-size: 1rem;
`;

export const Subtitle = styled.h4`
    font-size: 1.5rem;
    font-weight: 600;
    color: #171717;
    margin-top: 7.5em;
    border-bottom: 1px solid #686868;
    margin-bottom: 0;
    @media (max-width: 450px) {
        font-size: 1rem;
        margin-top: 11.5em;
    }

    @media (max-width: 300px) {
        padding-top: 10px;
    }
`;

export const Text = styled.p`
    text-align: start;
    padding: 0 30px 0 20px;
    line-height: 17.5px;
    font-size: 0.875rem;
    font-weight: 400;
    /* font-size: 0.8rem; */
    text-align: justify;

    @media (min-width: 1600px) {
        /* font-size: 1rem; */
    }
    @media (max-width: 450px) {
        /* font-size: 0.75rem; */
    }
`;

export const Span = styled.span`
    font-size: 2rem;
    font-weight: 700;
`;

import { createGlobalStyle } from "styled-components";
import "@fontsource-variable/quicksand";

const GlobalStyle = createGlobalStyle`
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
    width: 6px;
    border-radius: 10px;
  background: linear-gradient(180.82deg, #487B9D 2.45%, #79AAC1 45.83%, #A3D1E0 99.29%);
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}
    html {
        height: 100%;
    }

    body {
        background-color:  ${(props) => props.theme.backgroundLight};
        margin: 0;
        font-family: "Quicksand Variable", sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        color: ${(props) => props.theme.textDark};
        height: 100%;
    }

    #root {
        height: 100%;
    }

`;

export default GlobalStyle;

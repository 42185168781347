import { AiFillWarning } from "react-icons/ai";
import { InputType } from "types";
import * as S from "./style";

export default function HelperText({ helperText }: InputType) {
    return (
        <S.HelperText>
            <AiFillWarning size={15} />
            {helperText}
        </S.HelperText>
    );
}

type typeCalculoValor = {
    listExtraStyles: string;
    listService: any;
};

export function CalculoValorTotal({
    listExtraStyles,
    listService,
}: typeCalculoValor) {
    const extraStyleIds = listExtraStyles?.split(",");

    let filteredExtraStyles = [];

    if (listService.extraStyles && listService.extraStyles.length > 0) {
        filteredExtraStyles = listService.extraStyles.filter((style: any) =>
            extraStyleIds?.includes(style.id)
        );
    }

    const extraStyleValues = filteredExtraStyles.map(
        (style: any) => style.valueNoSignature
    );

    const totalExtraStyleValue = extraStyleValues.reduce(
        (acc: number, value: number) => acc + value,
        0
    );

    const calculoTotal = totalExtraStyleValue
        ? listService.valueNoSignature + totalExtraStyleValue
        : listService.valueNoSignature;

    return calculoTotal;
}

import { ChatContent } from "contexts/Chat.context";
import { useContext, useEffect, useState } from "react";
import { BsFillPersonFill } from "react-icons/bs";
import * as S from "./style";

type MessageType = {
    recipient: any;
};
const Message = ({ recipient }: MessageType) => {
    const { message, socket } = useContext(ChatContent);
    const [messageOn, setMessageOn] = useState<any>("");
    const [messages, setMessages] = useState<any>([]);
    const getUser = localStorage.getItem("getUser");
    const JsonGetUser = JSON.parse(getUser!);

    useEffect(() => {
        socket.emit("setUserId", JsonGetUser.id);
    }, [socket, JsonGetUser.id]);

    useEffect(() => {
        socket.on("message", (message: any) => {
            setMessages([...messages, message]);
        });
    }, [messages]);

    const sendMessage = () => {
        const messageObject = {
            message: messageOn,
            senderId: JsonGetUser.id,
            senderName: JsonGetUser.name,
            recipientId: recipient.senderId[0],
            recipientName: recipient.senderName,
            recipientRole: 1,
        };

        socket.emit("message", messageObject);
        setMessageOn("");
    };

    return (
        <S.WrapperMessage>
            <S.MessageContainer>
                <S.MessageHeader>
                    <div>
                        <BsFillPersonFill size={35} />
                    </div>
                    <div>
                        <S.HeaderName>{recipient.senderName}</S.HeaderName>
                    </div>
                </S.MessageHeader>
                {message.length > 0 &&
                    message.map((message: any, index: any) => (
                        <S.Message
                            key={index}
                            className={
                                message?.senderId[0] == JsonGetUser.id
                                    ? "sender-data"
                                    : "recipient-data"
                            }
                        >
                            <span
                                className={
                                    message?.senderId[0] == JsonGetUser.id
                                        ? "message-sender"
                                        : "message-recipient"
                                }
                            >
                                {message.message}
                            </span>
                        </S.Message>
                    ))}
                {messages.map((message: any, index: any) => (
                    <S.Message
                        key={index}
                        className={
                            message?.senderId == JsonGetUser.id
                                ? "sender-data"
                                : "recipient-data"
                        }
                    >
                        <span
                            className={
                                message?.senderId == JsonGetUser.id
                                    ? "message-sender"
                                    : "message-recipient"
                            }
                        >
                            {message.message}
                        </span>
                    </S.Message>
                ))}
            </S.MessageContainer>
            <S.InputContainer>
                <input
                    type="text"
                    value={messageOn}
                    onChange={(e) => setMessageOn(e.target.value)}
                />
                <button onClick={sendMessage}>Enviar</button>
            </S.InputContainer>
        </S.WrapperMessage>
    );
};

export default Message;

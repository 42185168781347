import { api } from "api/api";
import { useStorePayment } from "contexts/Payment.context";
import { toast } from "react-toastify";

export function getPaymentPix() {
    // const { typePayment, amount } = useStorePayment();
    // const {
    //     data: listPaymentPix,
    //     refetch: refetchPaymentPix,
    //     isFetching: isLoadingPaymentPix,
    // } = useQuery(["listPaymentPix"], () =>
    //     api(`payment/${typePayment}/${amount}`)
    // );
    // return {
    //     listPaymentPix,
    //     refetchPaymentPix,
    //     isLoadingPaymentPix,
    // };
}

export async function PaymentCredito(data: any) {
    try {
        const { typePayment, amount } = useStorePayment();

        const response = await api(`payment/${typePayment}/${amount}`, {
            method: "POST",

            data,
        });

        return response;
    } catch (error) {
        toast.error("Ops ocorreu um erro!");
        return error;
    }
}

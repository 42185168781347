import { CardPayment, initMercadoPago } from "@mercadopago/sdk-react";
import { Container } from "components";
import { useStorePayment } from "contexts/Payment.context";
import { useDeleteCarrinho, useGetCarrinho } from "hooks";
import { PaymentCredito } from "hooks/usePayment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as S from "./style";
import SummaryOrder from "./summaryOrder";
import { typesPayment } from "./TypePayment";
initMercadoPago(process.env.REACT_APP_PUBLIC_KEY!);

export default function Payment() {
    const { typePayment, setTypePayment, amount } = useStorePayment();
    const [paymentCredit] = useState("");
    const { deleteCarrinho } = useDeleteCarrinho();
    const token = localStorage.getItem("token");
    const router = useNavigate();
    const { listCarrinho, refetchListCarrinho } = useGetCarrinho();

    // const { refetchPaymentPix, isLoadingPaymentPix, listPaymentPix } =
    //     getPaymentPix();

    useEffect(() => {
        if (typePayment == "pix") {
            // refetchPaymentPix();
        }
    }, [typePayment]);

    useEffect(() => {
        async function teste() {
            if (paymentCredit) {
                await PaymentCredito(paymentCredit);
            }
        }
        teste();
    }, [paymentCredit]);

    // const customization = {
    //     paymentMethods: {
    //         creditCard: "all",
    //     },
    // };

    const onSubmit = async (formData: any) => {
        const requestBody = {
            formData,
            listCarrinho,
        };
        // callback chamado ao clicar no botão de submissão dos dados
        return new Promise<void>((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_URL}/api/process_payment`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${JSON.parse(token!)}`,
                },
                body: JSON.stringify(requestBody),
            })
                .then((response) => response.json())
                .then((response) => {
                    if (response.status == "approved") {
                        deleteCarrinho(listCarrinho[0].user[0].id)
                            .then(() => {
                                refetchListCarrinho();
                            })
                            .catch(() => {
                                toast.error(
                                    "Aconteceu um problema ao limpar o carrinho!"
                                );
                            });

                        toast.success("Pagamento aprovado!");
                        router("/orders");
                    }
                    // receber o resultado do pagamento
                    resolve();
                })
                .catch(() => {
                    toast.error("Pagamento recusado!");
                    // lidar com a resposta de erro ao tentar criar o pagamento
                    reject();
                });
        });
    };

    const onError = async (error: any) => {
        // callback chamado para todos os casos de erro do Brick
        // eslint-disable-next-line no-console
        console.log("error", error);
    };

    const onReady = async () => {
        // eslint-disable-next-line no-console
        console.log("onReady");
        /*
            Callback chamado quando o Brick estiver pronto.
            Aqui você pode ocultar loadings do seu site, por exemplo.
          */
    };
    return (
        <Container>
            <S.Wrapper>
                <S.WrapperPayment>
                    <S.ContentAside>
                        <h4>Pagamento</h4>
                        <S.ContentTypesPayment>
                            {typesPayment?.map((item: any, index) => (
                                <S.TypePayment
                                    key={index}
                                    backgroudColor={item.type == typePayment}
                                    onClick={() => setTypePayment(item.type)}
                                >
                                    {item.icon}
                                    <span>{item.title}</span>
                                </S.TypePayment>
                            ))}
                        </S.ContentTypesPayment>
                    </S.ContentAside>
                    <S.ContainerPayment>
                        {typePayment == "card" && (
                            <>
                                {/* <Paymentv2
                                    initialization={{
                                        amount: amount,
                                    }}
                                    locale="pt-BR"
                                    onSubmit={onSubmit}
                                    customization={
                                        customization as IPaymentBrickCustomization
                                    }
                                /> */}
                                <CardPayment
                                    initialization={{
                                        amount: amount,
                                    }}
                                    locale="pt-BR"
                                    onSubmit={onSubmit}
                                    onReady={onReady}
                                    onError={onError}
                                    customization={{
                                        paymentMethods: {
                                            minInstallments: 1,
                                            maxInstallments: 12,
                                        },
                                    }}
                                />
                            </>
                        )}
                        {/* {typePayment == "pix" && listPaymentPix && (
                            <PaymentPix
                                qr_base64={
                                    listPaymentPix.point_of_interaction
                                        .transaction_data.qr_code_base64
                                }
                                qr_code={
                                    listPaymentPix.point_of_interaction
                                        .transaction_data.qr_code
                                }
                            />
                        )} */}
                    </S.ContainerPayment>
                </S.WrapperPayment>
                <SummaryOrder />
            </S.Wrapper>
        </Container>
    );
}

import { Header, Footer, Modal } from "components";
import { Outlet } from "react-router-dom";
import * as S from "./style";
import { RetangleBlue } from "./RetangleBlue";

export default function Layout() {
    return (
        <S.Wrapper>
            <RetangleBlue.Left />
            <RetangleBlue.Rigth />
            <Modal />

            <Header />
            <Outlet />
            <Footer />
        </S.Wrapper>
    );
}

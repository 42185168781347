import {
    IconAgil,
    IconDinheiro,
    IconQualidade,
    IconPlanos,
} from "assets/icons";
import { ChooseType } from "types";

export const ChooseItems: Array<ChooseType> = [
    {
        id: 1,
        title: "Impacte seu cliente",
        icon: <IconAgil />,
        description:
            "Agilidade e precisão convergem no Portal Arktools, otimizando renderizações arquitetônicas com eficiência e personalização. Transforme projetos rapidamente",
    },
    {
        id: 2,
        title: "Aumente sua produtividade",
        icon: <IconDinheiro />,
        description:
            "Agilidade e precisão convergem no Arktools, otimizando renderizações arquitetônicas com eficiência e personalização. Transforme projetos rapidamente.",
    },
    {
        id: 3,
        title: "Surpreenda na qualidade",
        icon: <IconQualidade />,
        description:
            "Alcance qualidade excepcional: aproveite a potência de um site de render farm para arquitetos, acelerando o processo e obtendo renderizações de alto nível.",
    },
    {
        id: 4,
        title: "Planos adequados à sua necessidade",
        icon: <IconPlanos />,
        description:
            "Maximize a relação custo-benefício! Adquira planos inteligentes, flexíveis e econômicos, atendendo às suas necessidades de renderização com eficiência e flexibilidade.",
    },
];
